import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./list.scss";

export const List = ({ cols, ...props }) => {
    const className = classNames({
        List: true,
        [`List--${cols}`]: cols,
    });

    const getStyle = () => {
        return {
            columnCount: cols,
        };
    };

    return (
        <ul className={className} style={getStyle()}>
            {props.children}
        </ul>
    );
};

List.propTypes = {
    cols: PropTypes.number,
};

List.defaultProps = {
    cols: 1,
};
