import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { generatePath, useHistory } from "react-router-dom";
import { Row } from "../../components/layout/Row";
import { Col } from "../../components/layout/Col";
import { Header } from "../../components/header/Header";
import { Menu } from "../../components/menu/Menu";
import { Logo } from "../../components/logo/Logo";
import { Layout } from "../../components/layout/Layout";
import { MenuLink } from "../../components/menu/MenuLink";
import { Skiplinks } from "../../components/skiplinks/Skiplinks";
import { deepGet } from "../../lib/util";
import { ROUTES } from "../../routes";
import { Modal } from "../../components/modal/Modal";
import { LanguageSwitcher } from "../../components/language-switcher/LanguageSwitcher";
import usePageTracking from "../../lib/use_page_tracking";
import { ToastStack } from "../../components/toast/ToastStack";

/**
 * Abstract view
 * @return {JSX.Element}
 */
export const AbstractQuestionsView = ({
    children,
    clearState,
    gaInitialized,
    headerProps,
    headerButtons,
    languageState,
    match,
    modals,
    ptvState,
    setGaInitialized,
    setLanguageState,
    statementsState,
    toasts,
    toolState,
    ...props
}) => {
    const history = useHistory();
    const [subMenuActive, setSubMenuActive] = useState(false);
    const skiplinks = [
        [
            "content",
            deepGet(toolState, "general_texts.go_to_content", "Go to content."),
        ],
        [
            "menu",
            deepGet(props, "toolState.general_texts.go_to_menu", "Go to menu."),
        ],
    ];

    /**
     * Focuses the first focusable element.
     */
    useEffect(() => {
        setTimeout(() => {
            try {
                const cookieConsentButton = document.querySelector(
                    ".Toast--active#cookie-consent button"
                );
                const firstControl = document
                    .querySelector(".Layout .Row + .Row")
                    ?.querySelector("input, select, textarea, button");
                const target = cookieConsentButton
                    ? cookieConsentButton
                    : firstControl;

                target?.focus();
            } catch (e) {}
        }, 120);
    }, [history]);

    usePageTracking(toolState, gaInitialized, setGaInitialized);

    /**
     * Continues to the next route.
     * @param {string} [path]
     */
    const nextRoute = (path = ROUTES.STATEMENTS.path) => {
        history.push(path);
        setSubMenuActive(false);
    };

    /**
     * Renders the language switcher.
     * @return {JSX.Element}
     */
    const renderLanguageSwitcher = () => {
        return (
            <LanguageSwitcher
                languageState={languageState}
                setLanguageState={setLanguageState}
                languages={toolState.languages}
                callback={clearState}
            />
        );
    };

    /**
     * Renders the menu.
     * @return {JSX.Element}
     */
    const renderMenu = () => (
        <Menu
            languageSwitcher={renderLanguageSwitcher()}
            title={toolState.general_texts.menu_button_text}
        >
            {
                <MenuLink
                    onClick={() =>
                        history.push(
                            generatePath(ROUTES.RESTART.path, {
                                language: match.params.language,
                            })
                        )
                    }
                    tabIndex={20}
                >
                    {toolState.general_texts.begin_navigation_text}
                </MenuLink>
            }

            <MenuLink
                target="_blank"
                href="https://www.kieskompas.nl/nl/policies/"
                tabIndex={21}
            >
                {toolState.general_texts.my_privacy}
            </MenuLink>

            {statementsState.length > 0 && (
                <MenuLink
                    tabIndex={22}
                    onClick={() =>
                        history.push(
                            generatePath(ROUTES.STATEMENTS.path, {
                                language: match.params.language,
                                page: 1,
                            })
                        )
                    }
                >
                    {toolState.general_texts.statements_navigation_text}
                </MenuLink>
            )}

            {ptvState.length > 0 && (
                <MenuLink
                    tabIndex={23}
                    onClick={() =>
                        history.push(
                            generatePath(ROUTES.PTV.path, {
                                language: match.params.language,
                                page: 1,
                            })
                        )
                    }
                >
                    {toolState.general_texts.ten_scale_navigation_text}
                </MenuLink>
            )}

            <MenuLink
                tabIndex={24}
                onClick={() =>
                    history.push(
                        generatePath(ROUTES.RESULTS.path, {
                            language: match.params.language,
                        })
                    )
                }
            >
                {toolState.general_texts.result_navigation_text}
            </MenuLink>

            {toolState.more_info_items.length > 0 && (
                <MenuLink tabIndex={25} onClick={() => setSubMenuActive(true)}>
                    {toolState.general_texts.more_info_menu_item}
                </MenuLink>
            )}

            {
                <MenuLink
                    tabIndex={26}
                    onClick={() =>
                        history.push(
                            generatePath(ROUTES.RESTART.path, {
                                language: match.params.language,
                            })
                        )
                    }
                >
                    {toolState.general_texts.remove_data_item}
                </MenuLink>
            }
        </Menu>
    );

    /**
     * Renders the submenu.
     * @return {JSX.Element}
     */
    const renderSubMenu = () => {
        return (
            <Modal
                active={subMenuActive}
                allowclose={true}
                labelClose={deepGet(toolState, "general_texts.close")}
                position="float"
                size="normal"
                onClose={() => setSubMenuActive(false)}
            >
                <Menu title={toolState.general_texts.more_info_menu_item}>
                    {toolState.more_info_items.map((item) => (
                        <MenuLink
                            key={item.slug}
                            onClick={() =>
                                nextRoute(
                                    generatePath(ROUTES.CONTENT.path, {
                                        language: match.params.language,
                                        slug: item.slug,
                                    })
                                )
                            }
                        >
                            {item.link_text}
                        </MenuLink>
                    ))}

                    {toolState.faq_page && toolState.faq_page.faq_items && (
                        <MenuLink
                            onClick={() =>
                                nextRoute(
                                    generatePath(ROUTES.FAQ.path, {
                                        language: match.params.language,
                                    })
                                )
                            }
                        >
                            {toolState.faq_page.name}
                        </MenuLink>
                    )}
                </Menu>
            </Modal>
        );
    };

    /**
     * Renders the required toast.
     */
    const renderToasts = () => {
        return <ToastStack>{toasts}</ToastStack>;
    };

    return (
        <Layout>
            <Skiplinks skiplinks={skiplinks} />
            <Row overflow={true} top={10000}>
                <Col overflow={true}>
                    <Header
                        style="shadow"
                        {...headerProps}
                        labelClose={deepGet(toolState, "general_texts.close")}
                        languageSwitcher={renderLanguageSwitcher()}
                        logo={
                            <Logo
                                onClick={() =>
                                    history.push(
                                        generatePath(ROUTES.RESTART.path, {
                                            language:
                                                match.params.language ||
                                                languageState ||
                                                toolState.primary_language
                                                    .iso_code,
                                        })
                                    )
                                }
                                src={toolState.general_style.logo}
                                alt={toolState.general_texts.header_title}
                            />
                        }
                        menu={renderMenu()}
                    >
                        {headerButtons}
                    </Header>
                </Col>
                {renderSubMenu()}
                {renderToasts()}
                {modals}
            </Row>
            <span id="content" className="Skiplinks__target" />
            {children}
        </Layout>
    );
};

AbstractQuestionsView.propTypes = {
    /** Props to pass to the header. */
    headerProps: PropTypes.object,

    /** HeaderButton components to render. */
    headerButtons: PropTypes.array,

    /** Toast components to render. */
    toasts: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),

    /** Modals to render. */
    modals: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
};
