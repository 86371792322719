import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./row.scss";

/**
 * Rows are horizontal components that can contain one or more Column components.
 */
export const Row = ({ center, grow, top, overflow, ...props }) => {
    const className = classNames({
        Row: true,
        "Row--center": center,
        "Row--grow": grow,
        "Row--top": top,
        "Row--overflow": overflow && typeof overflow === "boolean",
        [`Row--overflow-${overflow}`]: overflow && typeof overflow === "string",
    });

    /**
     * Returns the row style.
     * @returns {{zIndex: number}|null}
     */
    const getStyle = () => {
        const zIndex = parseInt(top);
        if (!zIndex) {
            return null;
        }

        return {
            zIndex: zIndex,
        };
    };

    return (
        <section className={className} style={getStyle()} {...props}>
            {props.children}
        </section>
    );
};

Row.propTypes = {
    /** Child components/content.  */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.element, PropTypes.string])
        ),
    ]),

    /** Whether to center content.  */
    center: PropTypes.bool,

    /** Whether to grow this row.  */
    grow: PropTypes.bool,

    /** Whether to stick to top, can be set to number to use a specific z-index.  */
    top: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),

    /** Whether overflow content should be visible. Set to 'mobile' for mobile only.  */
    overflow: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

Row.defaultProps = {
    center: false,
    grow: false,
    top: false,
    overflow: false,
};
