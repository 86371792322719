import {
    calculateComparativeEntityPosition,
    calculateUserPosition,
} from "./position_score";

/**
 * Calculates the distance to a comparative entity by based on Euclidean math using the x and y axes.
 * @param {{[index: string]: number|null}} userPosition
 * @param {{[index: string]: number|null}} comparativeEntityPosition
 * @returns {number|Infinity}
 */
export const calculateEuclideanDistance = (
    userPosition,
    comparativeEntityPosition
) => {
    // a^2 + b^2...
    const norm = Object.keys(userPosition).reduce((acc, axis) => {
        const axisDistance =
            userPosition[axis] - comparativeEntityPosition[axis];
        acc = acc + Math.pow(axisDistance, 2);
        return acc;
    }, 0);

    // EDGE CASE: One or more axes don't align (exist on one but no the other).
    // Therefor the distance can't be calculated resulting in NaN (Not a Number).
    // In such cases Infinity should be returned.
    // See: https://taiga.maykinmedia.nl/project/kieskompas/issue/1128 for more information.
    if (isNaN(norm)) {
        return Infinity;
    }

    return Math.sqrt(norm);
};

/**
 * Returns Euclidean distance as a value from 0 to 100.
 * @param {number} distance The calculated distance.
 * @param {number} [nSeries=2] The amount of series used (2 for x/y).
 * @return {number}
 */
export const getNormalizedEuclideanDistance = (distance, nSeries = 2) => {
    const maxDistance = getMaxEuclideanDistance(nSeries);

    if (distance === Infinity) {
        return 100;
    }

    // Handle division by zero
    if (maxDistance === 0) {
        return 0;
    }

    return (distance / maxDistance) * 100;
};

/**
 * Return the max Euclidean distance.
 * @param {number} [nSeries=2] The amount of series used (2 for x/y).
 * @return {number}
 */
export const getMaxEuclideanDistance = (nSeries = 2) => {
    return Math.sqrt(nSeries * Math.pow(4, 2));
};

/**
 * TODO: Check usages, always for compass?
 * Returns the nearest comparative entity based on pythagoras math using the x and y axes.
 * @param {{axis: string, inverse: boolean, use_in_calculation: boolean, value: *}[]} statements
 * @param {Object[]} comparativeEntities
 * @returns {Object}
 */
export const getNearestComparativeEntity = (
    statements,
    comparativeEntities
) => {
    const userPosition = calculateUserPosition(statements);

    const distances = comparativeEntities.map((comparativeEntity) => {
        const comparativeEntityPosition =
            calculateComparativeEntityPosition(comparativeEntity);
        const distance = calculateEuclideanDistance(
            userPosition,
            comparativeEntityPosition
        );

        return {
            comparativeEntity,
            distance,
        };
    });

    return distances.sort((a, b) => {
        return a.distance - b.distance;
    })[0].comparativeEntity;
};
