import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./sub-title.scss";

/**
 * Subtitle is a simple component that can be used to style smaller titles.
 */
export const SubTitle = ({
    align,
    color,
    fontsize,
    strong,
    maxWidth,
    marginBottom,
    ...props
}) => {
    const className = classNames({
        SubTitle: true,
        "SubTitle--strong": strong,
        [`SubTitle--${align}`]: align,
    });

    const getStyle = () => {
        return {
            color,
            fontSize: fontsize ? `${fontsize / 16}rem` : null,
            maxWidth: maxWidth,
            marginBottom: `${marginBottom}px`,
        };
    };

    return (
        <h2 className={className} {...props} style={getStyle()}>
            {props.children}
        </h2>
    );
};

SubTitle.propTypes = {
    /** Child components/content.  */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.element, PropTypes.string])
        ),
    ]),

    /** The alignment based on the text direction (LTR/RTL). */
    align: PropTypes.oneOf(["side", "center"]),

    /** Color. */
    color: PropTypes.string,

    /** Font size in pixels. */
    fontsize: PropTypes.number,

    /** Maximum width. */
    maxWidth: PropTypes.string,

    /** Whether to use strong text. */
    strong: PropTypes.bool,
    marginBottom: PropTypes.number,
};

SubTitle.defaultProps = {
    align: "side",
    color: null,
    fontsize: null,
    maxWidth: null,
    strong: false,
    marginBottom: null,
};
