import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./sponsors.scss";
import { isRtl } from "../../lib/responsive";
import { useAnimation } from "../../lib/animation";

/**
 * A generic wrapper that typically contains one or more Logo components
 */
export const Sponsors = ({ ...props }) => {
    const list = useRef();
    const [slide, setSlide] = useState(1);
    const [iteration, setIteration] = useState(0);
    const renderedSlide = useAnimation(slide);

    useEffect(() => {
        const timeout = setTimeout(() => {
            const nextSlide = getNextSlide();
            setSlide(nextSlide);
            setIteration(iteration + 1);
        }, 3000);
        return () => clearTimeout(timeout);
    });

    /**
     * Returns the next (1 indexed) slide position.
     * @return {number}
     */
    const getNextSlide = () => {
        return !isOverflown() ? 1 : slide + 1;
    };

    /**
     * Returns the slide style.
     * @return {{transform: string}}
     */
    const getSlideStyle = () => {
        const translateX = getWidestSlideWidth() * -(renderedSlide - 1);
        const _translateX = isRtl() ? translateX * -1 : translateX;

        return {
            transform: `translateX(${_translateX}px)`,
        };
    };

    /**
     * Returns the width of the widest slide.
     * @return {number|null}
     */
    const getWidestSlideWidth = () => {
        if (!list.current) {
            return null;
        }
        return (
            [...list.current.childNodes]
                .map((childNode) => childNode.clientWidth)
                .sort((a, b) => b - a)[0] || 0
        );
    };

    /**
     * Returns whether the list is oveflown.
     * @return {boolean}
     */
    const isOverflown = () => {
        try {
            const clientWidth = list.current.clientWidth;
            const scrollWidth = list.current.scrollWidth;
            return scrollWidth > clientWidth;
        } catch (e) {
            return false;
        }
    };

    const className = classNames({
        Sponsors: true,
        "Sponsors--justify": renderedSlide === 1 && !isOverflown(),
    });

    /**
     * Renders the list item.s
     * @return {JSX.Element}
     */
    const renderItems = () => {
        return props.children.map((child, index) => (
            <li
                key={index}
                className="Sponsors__list-item"
                style={getSlideStyle()}
            >
                {child}
            </li>
        ));
    };

    return (
        <nav className={className}>
            <ul ref={list} className="Sponsors__list">
                {renderItems()}
            </ul>
        </nav>
    );
};

Sponsors.propTypes = {
    /** Child components/content.  */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.element, PropTypes.string])
        ),
    ]),
};

Sponsors.defaultProps = {
    children: [],
};
