import { calculateAgreementScore } from "./agreement_score";

/**
 * Returns the amount of axes used by statements.
 * @param {{axis: string, inverse: boolean, use_in_calculation: boolean, value: *}[]} statements
 * @param {boolean} excludeEmpty Whether to exclude axes (dimensions) that have no score.
 * @param {{fk_answers: {answer: {value: *}, statement: {axis: string, inverse: boolean, use_in_calculation: boolean, value: *}}}} [comparativeEntity] required when `excludeEmpty=true`.
 * @return {number}
 */
export const getNSeries = (
    statements,
    excludeEmpty = false,
    comparativeEntity = undefined
) => {
    const sortedStatements = getStatementsSortedByAxes(statements);
    if (excludeEmpty) {
        console.assert(
            comparativeEntity,
            '"getNSeries()" requires "comparativeEntity" to be set when "excludeEmpty=true"!'
        );

        const scoredAxes = Object.entries(sortedStatements).filter(
            ([axis, statements]) => {
                const axisAgreementScore = calculateAgreementScore(
                    statements,
                    comparativeEntity
                )[0];
                return axisAgreementScore !== null;
            }
        );
        return scoredAxes.length;
    }
    return Object.keys(sortedStatements).length;
};

/**
 * Returns key value where key is an axis and value the related statements.
 * @param statements
 * @return {{[index: string]: {axis: string, inverse: boolean, use_in_calculation: boolean, value: *}[]}}
 */
export const getStatementsSortedByAxes = (statements) => {
    return statements.reduce((acc, statement) => {
        const axis = statement.axis;
        const current = acc[axis] || [];
        return { ...acc, [axis]: [...current, statement] };
    }, {});
};
