import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Button } from "../button/Button";
import { ButtonLink } from "../button/ButtonLink";
import { Icon } from "../icon/Icon";
import { Text } from "../typography/Text";
import "./icon-button.scss";

/**
 * A special button/icon combination.
 */
export const IconButton = ({
    icon,
    alt,
    background,
    color,
    disabled,
    href,
    target,
    label,
    link,
    pad,
    size,
    tabIndex,
    ...props
}) => {
    const className = classNames({
        IconButton: true,
        [`IconButton--${size}`]: size,
    });

    const _link = link || href;
    const Tag = _link ? ButtonLink : Button;

    return (
        <div className={className} {...props}>
            <Tag
                disabled={disabled}
                pad={pad}
                style="transparent"
                tabIndex={tabIndex}
                aria-label={alt || label}
                href={href}
                target={target}
            >
                <Icon
                    icon={icon}
                    background={background}
                    color={color}
                    label={alt || label}
                />
                {(props.children || label) && size !== "small" && (
                    <Text
                        align="center"
                        size="small"
                        color={size === "small" ? color : null}
                    >
                        {props.children || label}
                    </Text>
                )}
            </Tag>
        </div>
    );
};

IconButton.propTypes = {
    /** Child components/content.  */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.element, PropTypes.string])
        ),
    ]),

    /** The icon name. */
    icon: PropTypes.string,

    /** The alt attribute of the image. */
    alt: PropTypes.string.isRequired,

    /** Whether to show (and optionally the color) a background around the icon. */
    background: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),

    /** Color. */
    color: PropTypes.string,

    /** The disabled state of the button. */
    disabled: PropTypes.bool,

    /** An (anchor) href, will result in link=true. */
    href: PropTypes.string,

    /** The aria label attribute (defaults to icon name). */
    label: PropTypes.string,

    /** Whether to use a ButtonLink instead of a Button. */
    link: PropTypes.bool,

    /** Whether the button should have padding. */
    pad: PropTypes.bool,

    /** Size preset, small hides label. */
    size: PropTypes.oneOf(["normal", "small"]),

    /** An (anchor) target. */
    target: PropTypes.string,

    /** Tabindex value. */
    tabIndex: PropTypes.number,
};

IconButton.defaultProps = {
    icon: "",
    alt: "",
    background: null,
    color: null,
    disabled: false,
    href: null,
    label: "",
    link: false,
    pad: true,
    size: "normal",
    tabIndex: null,
    target: null,
};
