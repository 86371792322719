import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./label.scss";

/**
 * Label is an element that can be used in combination with various input elements.
 */
export const Label = ({ align, htmlFor, ...props }) => {
    const className = classNames({
        Label: true,
        [`Label--${align}`]: align,
    });

    return (
        <label
            className={className}
            htmlFor={htmlFor ? htmlFor : null}
            {...props}
        >
            {props.children}
        </label>
    );
};

Label.propTypes = {
    /** Child components/content.  */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.element, PropTypes.string])
        ),
    ]),

    /** The alignment based on the text direction (LTR/RTL). */
    align: PropTypes.oneOf(["side", "center"]),

    /** The id attribute of the described element. */
    htmlFor: PropTypes.string,
};

Label.defaultProps = {
    align: "side",
    htmlFor: null,
};
