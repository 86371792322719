import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useAnimation } from "../../lib/animation";
import { slugify } from "../../lib/format";
import { calculateCompassAxisPosition } from "../../lib/score/position_score";
import {
    score,
    scoreComparativeEntities,
} from "../../lib/score/comparative_entity_score";
import "./universe.scss";

/**
 * Universe component.
 */
export const Universe = ({
    calculation,
    statements,
    comparativeEntities,
    srcPin,
    labelAgreementWith,
    labelMarker,
    ...props
}) => {
    const viewboxWidth = 1280;
    const comparativeEntityWidth = 40;

    const [isPortrait, setIsPortrait] = useState(
        window.innerWidth < window.innerHeight
    );

    useEffect(() => {
        const fn = () => setIsPortrait(window.innerWidth < window.innerHeight);
        window.addEventListener("resize", fn);
        return () => window.removeEventListener("resize", fn);
    });

    const className = classNames({
        Universe: true,
    });

    /**
     * Renders comparative entities.
     * @returns {JSX.Element[]}
     */
    const renderComparativeEntities = () => {
        // Sort left wing/right wing.
        const statementIds = statements.map((statement) => statement.id);
        comparativeEntities.sort((a, b) => {
            const aStatements = a.fk_answers
                .filter(
                    (fkAnswer) =>
                        statementIds.indexOf(fkAnswer.statement.id) > -1
                )
                .map((fkAnswer) => {
                    return {
                        value: fkAnswer.answer?.value,
                        ...fkAnswer.statement,
                    };
                });

            const bStatements = b.fk_answers
                .filter(
                    (fk_answer) =>
                        statementIds.indexOf(fk_answer.statement.id) > -1
                )
                .map((fkAnswer) => {
                    return {
                        value: fkAnswer.answer?.value,
                        ...fkAnswer.statement,
                    };
                });

            const aPos = calculateCompassAxisPosition("x", aStatements);
            const bPos = calculateCompassAxisPosition("x", bStatements);
            return aPos - bPos;
        });

        const scoredComparativeEntities = scoreComparativeEntities(
            statements,
            comparativeEntities,
            calculation
        );

        // Render comparative entities.
        return scoredComparativeEntities.map((comparativeEntity, index) => {
            const position = Math.round(comparativeEntity[score]);
            const title = `${labelAgreementWith}: ${position}`;
            return renderComparativeEntity(
                comparativeEntity,
                (index * 170) / (comparativeEntities.length - 1) + 5,
                title
            );
        });
    };

    /**
     * Renders a comparative entity
     * @param {Object} comparativeEntity
     * @param {number} angle
     * @returns {JSX.Element}
     */
    const renderComparativeEntity = (comparativeEntity, angle, title) => {
        const distance = 100 - comparativeEntity[score];

        return (
            <UniverseComparativeEntity
                key={comparativeEntity.pk}
                comparativeEntity={comparativeEntity}
                distance={distance}
                angle={angle}
                comparativeEntityWidth={comparativeEntityWidth}
                title={title}
                viewboxWidth={viewboxWidth}
                isPortrait={isPortrait}
            />
        );
    };

    return (
        <div className={className} {...props}>
            <svg
                className="Universe__svg"
                viewBox={`0 0 ${viewboxWidth} ${viewboxWidth / 2}`}
            >
                <defs>
                    <radialGradient id="universe-gradient">
                        <stop offset="0%" stopColor="#000" stopOpacity={1} />
                        <stop offset="99%" stopColor="#CCC" stopOpacity={0} />
                    </radialGradient>
                </defs>

                <g className="Universe__wrapper">
                    <text
                        className="Universe__title"
                        x="50%"
                        y="3%"
                        fill="currentcolor"
                        fontFamily="Helvetica, Arial, sans-serif"
                        fontSize="100%"
                        fontWeight="bold"
                        textAnchor="middle"
                        opacity="1.0"
                        style={{ textShadow: "1px 1px #FFF" }}
                    >
                        {labelMarker}
                    </text>

                    <image
                        x="50%"
                        y="8%"
                        width={comparativeEntityWidth}
                        height={comparativeEntityWidth}
                        href={srcPin}
                        transform={
                            isPortrait
                                ? `translate(0 -20) rotate(90 660 50)`
                                : `translate(-20 -20)`
                        }
                    />

                    <circle
                        cx="50%"
                        cy="0"
                        r="10.50%"
                        stroke="currentColor"
                        fill="transparent"
                        opacity="1.0"
                    />
                    <circle
                        cx="50%"
                        cy="0"
                        r="21.00%"
                        stroke="currentColor"
                        fill="transparent"
                        opacity="0.9"
                    />
                    <circle
                        cx="50%"
                        cy="0"
                        r="31.50%"
                        stroke="currentColor"
                        fill="transparent"
                        opacity="0.8"
                    />
                    <circle
                        cx="50%"
                        cy="0"
                        r="42.00%"
                        stroke="currentColor"
                        fill="transparent"
                        opacity="0.7"
                    />
                    <circle
                        cx="50%"
                        cy="0"
                        r="52.45%"
                        stroke="currentColor"
                        fill="transparent"
                        opacity="0.6"
                    />
                    <circle
                        cx="50%"
                        cy="0"
                        r="63.00%"
                        stroke="currentColor"
                        fill="transparent"
                        opacity="0.5"
                    />

                    {renderComparativeEntities()}
                </g>
            </svg>
        </div>
    );
};

Universe.propTypes = {
    /**
     * The calculation method to used for scoring.
     *  - "agreement" calculates how much the user agrees with a comparative entity on individual statements.
     *  - "distance" calculates how close/distant a user is to a comparative entity on a political spectrum
     *    (left wing/right wing, progressive/conservative).
     **/
    calculation: PropTypes.oneOf(["agreement", "distance"]),

    /** Comparative entities to render. */
    comparativeEntities: PropTypes.array,

    /** The agreement label. */
    labelAgreementWith: PropTypes.string,

    /** The marker explanation. */
    labelMarker: PropTypes.string,

    /** The pin image source. */
    srcPin: PropTypes.string,

    /** Statements to render. */
    statements: PropTypes.array,
};

Universe.defaultProps = {
    comparativeEntities: [],
    srcPin: "",
    labelAgreementWith: "",
    labelMarker: "",
    statements: [],
};

/**
 * Universe comparative entity.
 */
const UniverseComparativeEntity = ({
    angle,
    comparativeEntity,
    comparativeEntityWidth,
    distance,
    title,
    isPortrait,
    viewboxWidth,
}) => {
    const comparativeEntitySideWidth = comparativeEntityWidth / 2;
    const sideWidth = viewboxWidth / 2;
    const minimumOffset = sideWidth / (6 + 1); // 6 CIRCLES
    const baseOffset = minimumOffset + comparativeEntitySideWidth;
    const scaleWidth = sideWidth - baseOffset;

    const _translateX = -baseOffset - (scaleWidth / 100) * distance;
    const translateX = useAnimation(_translateX, 1, 0);

    return (
        <g
            key={comparativeEntity.name}
            className="Universe__comparative-entity"
            transform={`rotate(-${angle} 640 0) translate(${translateX} ${-comparativeEntitySideWidth})`}
        >
            <clipPath
                id={`comparative-entity-${slugify(
                    comparativeEntity.name
                )}-mask`}
            >
                <circle
                    cx={sideWidth + comparativeEntitySideWidth}
                    cy={comparativeEntitySideWidth}
                    r={comparativeEntitySideWidth}
                />
            </clipPath>

            <circle
                cx={sideWidth + comparativeEntitySideWidth}
                cy={comparativeEntitySideWidth}
                r={comparativeEntityWidth}
                // transform={`rotate(${angle} ${comparativeEntitySideWidth} ${comparativeEntitySideWidth})`}
                fill="url(#universe-gradient)"
            />

            <svg>
                <title>{title}</title>
                <image
                    x={sideWidth}
                    y="0"
                    width={comparativeEntityWidth}
                    height={comparativeEntityWidth}
                    transform={`rotate(${isPortrait ? angle + 90 : angle} ${
                        sideWidth + comparativeEntitySideWidth
                    } ${comparativeEntitySideWidth})`}
                    href={comparativeEntity.logo}
                    clipPath={`url(#comparative-entity-${slugify(
                        comparativeEntity.name
                    )}-mask)`}
                />
            </svg>
        </g>
    );
};

UniverseComparativeEntity.propTypes = {
    /** Rotation angle. */
    angle: PropTypes.number,

    /** Comparative entity. */
    comparativeEntity: PropTypes.object,

    /** Comparative entity width. */
    comparativeEntityWidth: PropTypes.number,

    /** Distance percentage. */
    distance: PropTypes.number,

    /** The agreement label. */
    labelAgreementWith: PropTypes.string,

    /** Whether the universe is shown as portrait. */
    isPortrait: PropTypes.bool,

    /** The title describing the comparative entity and agreement. */
    title: PropTypes.string,

    /** Viewbox with of the parent SVG.*/
    viewboxWidth: PropTypes.number,
};

UniverseComparativeEntity.defaultProps = {
    angle: 0,
    comparativeEntity: null,
    comparativeEntityWidth: 0,
    distance: 0,
    labelAgreementWith: "",
    isPortrait: false,
    title: "",
    viewboxWidth: 0,
};
