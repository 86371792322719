/**
 * Downloads an svg element as png.
 * @param {SVGElement} svg
 * @param {string} filename
 * @param {number|null} [width]
 * @param {number|null} [height]
 * @param {string|null} [backgroundColor]
 * @return {Promise<void>}
 */
export const downloadSvgAsPng = (
    svg,
    filename,
    width = null,
    height = null,
    backgroundColor = null
) => {
    // Running the conversion twice seems to fix issues on (mobile) Safari.
    return svgToPngObjectURL(svg, width, height, backgroundColor)
        .then(() => svgToPngObjectURL(svg, width, height, backgroundColor))
        .then((objectURL) => downloadURL(objectURL, filename));
};

/**
 * Converts an svg element to a (png) object URL.
 * @param {SVGElement} svg
 * @param {number|null} [width]
 * @param {number|null} [height]
 * @param {string|null} [backgroundColor]
 * @return {Promise<string>}
 */
const svgToPngObjectURL = (
    svg,
    width = null,
    height = null,
    backgroundColor = null
) => {
    return svgToPngDataURL(svg, width, height, backgroundColor).then(
        (dataURL) => {
            const base64 = dataURL.replace("data:image/png;base64,", "");
            const byte = base64ToArrayBuffer(base64);
            const blob = new Blob([byte], { type: "image/png" });
            return URL.createObjectURL(blob);
        }
    );
};

/**
 * Converts an svg element to a (png) data URL.
 * @param {SVGElement} svg
 * @param {number|null} [width]
 * @param {number|null} [height]
 * @param {string|null} [backgroundColor]
 * @return {Promise<string>}
 */
const svgToPngDataURL = (
    svg,
    width = null,
    height = null,
    backgroundColor = null
) => {
    // Clone svg.
    const _svg = svg.cloneNode(true);

    // Firefox seem to require width/height attributes being set on svg's to work.
    _svg.setAttribute("width", String(width || svg.clientWidth));
    _svg.setAttribute("height", String(height || svg.clientHeight));

    // Create a new image based on the svg.
    const image = new Image();
    image.src =
        "data:image/svg+xml;charset=utf-8," +
        encodeURIComponent(_svg.outerHTML);
    image.width = parseInt(_svg.getAttribute("width"));
    image.height = parseInt(_svg.getAttribute("height"));

    // Return promise.
    return new Promise((resolve, reject) => {
        image.onload = () => {
            // Create canvas.
            const canvas = document.createElement("canvas");
            canvas.width = image.width;
            canvas.height = image.height;

            // Create/draw context.
            const context = canvas.getContext("2d");

            // Apply background color.
            if (backgroundColor) {
                context.fillStyle = backgroundColor;
                context.fillRect(0, 0, canvas.width, canvas.height);
            }

            context.drawImage(image, 0, 0);
            const dataURL = canvas.toDataURL("image/png");

            // Resolve.
            resolve(dataURL);
        };
        image.onerror = (error) => reject(error);
    });
};

/**
 * Converts a base64 string to an array buffer.
 * @param base64
 * @return {Uint8Array}
 */
export const base64ToArrayBuffer = (base64) => {
    const binaryString = window.atob(base64);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);

    for (let i = 0; i < binaryLen; i++) {
        const ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
};

/**
 * Downloads URL.
 * @param {string} url
 * @param {string} filename
 */
const downloadURL = (url, filename) => {
    // Create/click download link.
    const a = document.createElement("a");
    a.download = filename;
    a.href = url;
    document.body.appendChild(a);
    a.click();

    // Cleanup.
    setTimeout(() => {
        document.body.removeChild(a);
    }, 300);
};
