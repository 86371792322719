import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Icon } from "../icon/Icon";
import "./select.scss";
import { slugify } from "../../lib/format";

/**
 * A generic wrapper that typically contains various select inputs components.
 */
export const Select = ({
    className,
    options,
    id,
    multiple,
    name,
    value,
    extraText,
    onChange,
    ...props
}) => {
    const _className = classNames({
        Select: true,
        [className]: className,
    });

    const _value = multiple ? (Array.isArray(value) ? value : [value]) : value;

    return (
        <div className={_className}>
            {extraText && (
                <label className="Select__extra-text">
                    <Icon icon="lock" label={extraText} />
                    {extraText}
                </label>
            )}
            <select
                className="Select__select"
                id={id ? id : null}
                multiple={multiple}
                name={name ? name : null}
                value={_value || ""}
                data-value={value ? value : null}
                onChange={onChange}
                {...props}
            >
                {options.map(([optionValue, label]) => (
                    <option
                        key={slugify(`${optionValue} ${label}`)}
                        value={optionValue}
                    >
                        {label}
                    </option>
                ))}
            </select>
        </div>
    );
};

Select.propTypes = {
    /** Additional className. */
    className: PropTypes.string,

    /** The available options. */
    options: PropTypes.arrayOf(PropTypes.array),

    /** The id attribute of the button. */
    id: PropTypes.string,

    /** Whether to use a multiselect. */
    multiple: PropTypes.bool,

    /** The name attribute of the button. */
    name: PropTypes.string,

    /** The value attribute of the button. */
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]),

    /** An additional label on top of the input. */
    extraText: PropTypes.string,

    /** The "onChange" callback. */
    onChange: PropTypes.func,
};

Select.defaultProps = {
    className: "",
    options: [],
    id: "",
    multiple: false,
    name: "",
    value: "",
    extraText: null,
    onChange: () => {},
};
