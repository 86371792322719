import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Image } from "../image/Image";
import "./hero.scss";

/**
 *  In web design, a hero image is a large web banner image, prominently placed on a web page, generally in the front
 *  and center.
 */
export const Hero = ({ alt, src, ...props }) => {
    const className = classNames({
        Hero: true,
    });

    return (
        <header className={className} {...props}>
            {props.languageSwitcher}
            {props.logo}

            <div className="Hero__background">
                <Image src={src} alt={alt} objectPosition="right"></Image>
            </div>

            <div className="Hero__body">{props.children}</div>
        </header>
    );
};

Hero.propTypes = {
    /** Child components/content.  */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.element, PropTypes.string])
        ),
    ]),

    /** Language switcher component.  */
    languageSwitcher: PropTypes.element,

    /** Logo components  */
    logo: PropTypes.element,

    /** The alt attribute of the image. */
    alt: PropTypes.string,

    /** The src attribute of the image. */
    src: PropTypes.string,
};

Hero.defaultProps = {
    alt: "",
    src: "",
};
