import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Button } from "../button/Button";
import { SubTitle } from "../typography/SubTitle";
import { FormActions } from "./FormActions";
import { FormControl } from "./FormControl";
import { Input } from "./Input";
import { Select } from "./Select";
import { Label } from "./Label";
import { Text } from "../typography/Text";
import "./form.scss";
import { bleach } from "../../lib/format";

/**
 * Form is the top level form component that can contain one or more FormControl components. A form can auto-render form
 * controls based on the inpyts prop.
 */
export const Form = ({
    actions,
    align,
    emptyLabel,
    inputs,
    intro,
    pad,
    size,
    strong,
    style,
    title,
    onChange,
    ...props
}) => {
    const [formState, setFormState] = useState(inputs);
    const [validState, setValidState] = useState(true);
    useEffect(() => setFormState(inputs), [inputs]);

    /**
     * Returns whether to use the "alternate layout" (a single input).
     * Alternate layout shows a wider input and uses the label as title.
     * @return {boolean}
     */
    const renderAlternateLayout = () =>
        (!title || !intro) && inputs.length === 1;

    const className = classNames({
        Form: true,
        "Form--alternate": renderAlternateLayout(),
        "Form--pad": pad,
        [`Form--${size}`]: size,
        [`Form--${style}`]: style,
    });

    /**
     * Updates form state with value from changed input.
     * @param {SyntheticEvent} e
     */
    const onInputChange = (e) => {
        const { name, value } = e.target;
        const id = parseInt(name);
        const index = formState.findIndex((input) => input.id === id);
        const position = index > -1 ? index : formState.length;
        const input = formState[position] || { id };
        const newState = JSON.parse(JSON.stringify(formState));

        newState[position] = input;
        newState[position].value = value;
        setFormState(newState);
    };

    /**
     * Renders the title.
     * @return {*}
     */
    const renderTitle = () => {
        if (renderAlternateLayout()) {
            const id = getId(inputs[0], 0);

            return (
                <SubTitle align={align} strong={strong}>
                    <label htmlFor={id}>{title || inputs[0].question}</label>
                </SubTitle>
            );
        }
        return title ? (
            <SubTitle align={align} strong={strong}>
                {title}
            </SubTitle>
        ) : null;
    };

    /**
     * Renders the title.
     * @return {*}
     */
    const renderIntro = () => {
        const _intro =
            renderAlternateLayout() && title && !intro
                ? inputs[0].question
                : intro || null;

        const html = bleach(_intro, [
            "a",
            "strong",
            "em",
            "u",
            "h1",
            "h2",
            "h3",
        ]);
        return _intro ? <Text align={align} html={html} /> : null;
    };

    /**
     * Renders the form controls.
     * @return {*}
     */
    const renderFormControls = () => {
        // 1 input, alternate layout.
        if (renderAlternateLayout()) {
            return (
                <FormControl>
                    {renderInput(inputs[0], getId(inputs[0], 0))}
                </FormControl>
            );
        }

        return formState.map((input, index) => {
            const id = getId(input, index);

            return (
                <FormControl key={index}>
                    <Label htmlFor={id}>{input.question}</Label>
                    {renderInput(input, id)}
                </FormControl>
            );
        });
    };

    /**
     * Returns a value for `id`.
     * @param {Object} input
     * @param {number} index
     * @return {string}
     */
    const getId = (input, index) => {
        if (input.id) {
            return typeof input.id === "number"
                ? `form-control-${input.id}`
                : input.id;
        }
        return `form-control-${index}`;
    };

    /**
     * Returns the correct input widget for input.
     * @param {Object} input
     * @param {string} id
     * @return {*}
     */
    const renderInput = (input, id) => {
        if (input.answer_type === "dropdown") {
            return (
                <Select
                    id={id}
                    name={String(input.id)}
                    options={getOptions(input)}
                    value={input.value}
                    onChange={onInputChange}
                />
            );
        }
        const pattern =
            input.validators && input.validators.length
                ? input.validators[0].regex
                : null;
        const validationMessage =
            input.validators && input.validators.length
                ? input.validators[0].error
                : null;
        return (
            <Input
                id={id}
                name={String(input.id)}
                value={input.value}
                pattern={pattern}
                validationMessage={validationMessage}
                placeholder={input.answers}
                extraText={input.extra_text}
                type={input.answer_type}
                onChange={onInputChange}
            />
        );
    };

    /**
     * Returns the options for input.
     * @param input
     * @return {Array[]}
     */
    const getOptions = (input) => {
        if (input.answers) {
            return [
                ["", emptyLabel],
                ...input.answers.split(";").map((option) => [option, option]),
            ];
        } else {
            return [["", emptyLabel], []];
        }
    };

    /**
     * Form change handler.
     * @param {SyntheticEvent} e
     * @param {Object[]} formState
     */
    const onFormChange = (e) => {
        const form = e.currentTarget;
        const valid = form.checkValidity();
        setValidState(valid);
        onChange(e, formState);
    };

    /**
     * Renders the form actions (wrapper).
     * @return {*}
     */
    const renderFormActions = () => {
        if (!actions.length) {
            return null;
        }

        return <FormActions>{renderActions()}</FormActions>;
    };

    /**
     * Renders the form actions (each action).
     * @return {*}
     */
    const renderActions = () => {
        return actions.map((action, index) => (
            <Button
                key={index}
                disabled={!validState}
                minwidth={true}
                size="normal"
                style={index === 0 ? "primary" : "open"}
                onClick={action.onClick}
            >
                {action.label}
            </Button>
        ));
    };

    return (
        <form className={className} onChange={onFormChange} {...props}>
            {renderTitle()}
            {renderIntro()}
            {props.children}
            {renderFormControls()}
            {renderFormActions()}
        </form>
    );
};

Form.propTypes = {
    /** Child components/content.  */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.element, PropTypes.string])
        ),
    ]),

    /** Actions to render (automatically). */
    actions: PropTypes.array,

    /** The alignment based on the text direction (LTR/RTL). */
    align: PropTypes.oneOf(["center", "side"]),

    /** Label for "no value" options. */
    emptyLabel: PropTypes.string,

    /** Inputs/questions to render (automatically). */
    inputs: PropTypes.arrayOf(
        PropTypes.shape({
            answer_type: PropTypes.oneOf(["", "dropdown", "email", "open"]),
            answers: PropTypes.string, // Placeholder or select options (semicolon separated).
            extra_text: PropTypes.string,
            id: PropTypes.number,
            question: PropTypes.string,
            validators: PropTypes.arrayOf(
                PropTypes.shape({
                    error: PropTypes.string,
                    identifier: PropTypes.string,
                    regex: PropTypes.string,
                })
            ),
            value: PropTypes.string,
        })
    ),

    /** Intro to render (automatically). */
    intro: PropTypes.string,

    /** Whether to apply padding. */
    pad: PropTypes.bool,

    /** Whether to use a strong title. */
    strong: PropTypes.bool,

    /** (Alternate) size preset. */
    size: PropTypes.oneOf(["normal", "big"]),

    /** The style of the form, can be "horizontal", or "vertical". */
    style: PropTypes.oneOf(["horizontal", "vertical"]),

    /** Title to render (automatically). */
    title: PropTypes.string,

    /** onChange callback (receives inputs with values as second argument). */
    onChange: PropTypes.func,
};

Form.defaultProps = {
    align: "center",
    actions: [],
    emptyLabel: "",
    inputs: [],
    intro: "",
    pad: false,
    size: "normal",
    strong: false,
    style: "horizontal",
    title: "",
    onChange: () => {},
};
