import {
    calculateComparativeEntityPosition,
    calculateUserPosition,
} from "./position_score";
import { getNSeries } from "./utils";

/**
 * Calculates the distance to a comparative entity by based on Manhattan distance.
 * @param {{axis: string, inverse: boolean, use_in_calculation: boolean, value: *}[]} statements
 * @param {{fk_answers: {answer: {value: *}, statement: {axis: string, inverse: boolean, use_in_calculation: boolean, value: *}}}} comparativeEntity
 * @returns {number|Infinity}
 */
export const calculateManhattanDistance = (statements, comparativeEntity) => {
    const comparativeEntityPosition =
        calculateComparativeEntityPosition(comparativeEntity);

    const userPosition = Object.fromEntries(
        Object.entries(calculateUserPosition(statements)).filter(
            ([, score]) => score !== null
        )
    );

    // Get distance per dimensions and sum the
    const summedDistance = Object.entries(userPosition).reduce(
        (acc, [axis, userScore]) => {
            const comparativeEntityScore = comparativeEntityPosition[axis] + 2;
            const axisDistance = Math.abs(
                comparativeEntityScore - (userScore + 2)
            ); // TODO: Should we use Math.abs here?
            return acc + axisDistance;
        },
        0
    );

    const nSeries = getNSeries(statements, true, comparativeEntity);
    const maxDistance = getMaxManhattanDistance(nSeries);

    // EDGE CASE: One or more axes don't align (exist on one but no the other).
    // Therefor the distance can't be calculated resulting in NaN (Not a Number).
    // In such cases Infinity should be returned.
    // See: https://taiga.maykinmedia.nl/project/kieskompas/issue/1128 for more information.
    if (isNaN(summedDistance) || maxDistance === 0) {
        return Infinity;
    }

    return summedDistance / maxDistance;
};

/**
 * Returns Manhattan distance as a value from 0 to 100.
 * @param {number} manhattanDistance The calculated Manhattan distance.
 * @param {number} [nSeries=2] The amount of series used (2 for x/y).
 * @return {number}
 */
export const getNormalizedManhattanDistance = (manhattanDistance) => {
    return manhattanDistance * 100;
};

/**
 * Return the max Manhattan distance.
 * @param {number} [nSeries=2] The amount of series used (2 for x/y).
 * @return {number}
 */
export const getMaxManhattanDistance = (nSeries = 2) => {
    return nSeries * 4;
};
