import React from "react";
import { Modal } from "../../components/modal/Modal";
import { Menu } from "../../components/menu/Menu";
import { MenuLink } from "../../components/menu/MenuLink";
import { deepGet } from "../../lib/util";

/**
 * Renders more tools modal.
 * @return {JSX.Element|null}
 */
export const renderMoreToolsModal = (tool, onClose, active) => {
    if (
        !deepGet(tool, "social_tool_setting") ||
        !deepGet(tool, "tools_to_link_to")
    ) {
        return null;
    }

    return (
        <Modal
            active={active}
            allowclose={true}
            labelClose={deepGet(tool, "general_texts.close")}
            position="float"
            size="normal"
            onClose={onClose}
        >
            <Menu title={deepGet(tool, "social_tool_setting.more_tools", "")}>
                {deepGet(tool, "tools_to_link_to", []).map(
                    (linkedTool, index) => (
                        <MenuLink
                            key={index}
                            href={linkedTool.url}
                            target="_blank"
                            onClick={onClose}
                        >
                            {linkedTool.name}
                        </MenuLink>
                    )
                )}
            </Menu>
        </Modal>
    );
};
