import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./icon.scss";

/**
 * Various icons available through an icon font.
 */
export const Icon = ({
    icon,
    background,
    color,
    fontsize,
    label,
    strong,
    ...props
}) => {
    const className = classNames({
        Icon: true,
        "Icon--background": background,
        "Icon--strong": strong,
        [`Icon--${icon}`]: icon,
    });

    const getStyle = () => {
        return {
            backgroundColor: typeof icon === "string" ? background : null,
            color,
            fontSize: fontsize ? `${fontsize / 16}rem` : null,
        };
    };

    return (
        <span
            className={className}
            style={getStyle()}
            aria-label={`${label || icon}`}
            {...props}
        />
    );
};

Icon.propTypes = {
    /** Whether to show (and optionally the color) a background around the icon. */
    background: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),

    /** Color. */
    color: PropTypes.string,

    /** Font size in pixels. */
    fontsize: PropTypes.number,

    /** The icon name. */
    icon: PropTypes.string,

    /** The aria label attribute (defaults to icon name). */
    label: PropTypes.string,

    /** Whether to use strong text. */
    strong: PropTypes.bool,
};

Icon.defaultProps = {
    background: null,
    color: null,
    fontsize: null,
    icon: "",
    label: "",
    strong: false,
};
