import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Body } from "../typography/Body";
import { Image } from "../image/Image";
import { Title } from "../typography/Title";
import { Text } from "../typography/Text";
import { SubTitle } from "../typography/SubTitle";
import { ButtonLink } from "../button/ButtonLink";
import { Button } from "../button/Button";
import { Modal } from "../modal/Modal";
import { Avatar } from "../avatar/Avatar";
import "./comparative-entity-information.scss";
import { IconButton } from "../icon-button/IconButton";
import { ucfirst } from "../../lib/format";

/**
 * The footer represents a caption for the content of its parent component.
 */
export const ComparativeEntityInformation = ({
    comparativeEntity,
    labelCandidates,
    labelCandidatesButton,
    labelClose,
    labelLeader,
    labelProgramButton,
    ...props
}) => {
    // FIXME
    const candidateList =
        comparativeEntity.canidate_list_array ||
        comparativeEntity.candidate_list_array;
    const [candidateListModalState, setCandidateListModalState] =
        useState(false);

    const className = classNames({
        ComparativeEntityInformation: true,
    });

    /**
     * Renders the candidate list.
     * @return {JSX.Element|null}
     */
    const renderCandidateList = () => {
        if (!candidateList) {
            return null;
        }

        const listItems = candidateList.map((candidate, index) => (
            <li
                key={`${index}=${candidate}`}
                className="ComparativeEntityInformation__list-item"
            >
                {candidate}
            </li>
        ));

        return (
            <ol className="ComparativeEntityInformation__list">{listItems}</ol>
        );
    };

    /**
     * Renders the social media links.
     * @return {null|JSX.Element[]}
     */
    const renderSocials = () => {
        if (!comparativeEntity?.social_media_links?.length) {
            return null;
        }
        const correctSocials = comparativeEntity.social_media_links.filter(
            (socialMediaLink) => socialMediaLink.link
        );

        return (
            <div className="ComparativeEntityInformation__social">
                {correctSocials.map(({ link, social_media }) => (
                    <IconButton
                        key={link}
                        icon={social_media}
                        background="#000"
                        color="#FFF"
                        label={ucfirst(social_media)}
                        href={link}
                        size="small"
                        pad={false}
                        target="_blank"
                    />
                ))}

                {comparativeEntity.link_to_ce_website && (
                    <IconButton
                        icon="external-link"
                        background="#3A3F43"
                        color="#FFF"
                        label={comparativeEntity.description_short}
                        href={comparativeEntity.link_to_ce_website}
                        size="small"
                        pad={false}
                        target="_blank"
                    />
                )}
            </div>
        );
    };

    return (
        <article className={className} {...props}>
            <Body pad="extra" size="big">
                {(comparativeEntity.image || comparativeEntity.logo) && (
                    <header className="ComparativeEntityInformation__head">
                        <Avatar
                            alt={comparativeEntity.name}
                            src={
                                comparativeEntity.image ||
                                comparativeEntity.logo
                            }
                        />
                    </header>
                )}

                <section className="ComparativeEntityInformation__body">
                    {comparativeEntity.name && (
                        <Title strong={true}>{comparativeEntity.name}</Title>
                    )}

                    {renderSocials()}

                    {comparativeEntity.about && (
                        <Text size="big">{comparativeEntity.about}</Text>
                    )}

                    {comparativeEntity.link_to_program && (
                        <ButtonLink
                            href={comparativeEntity.link_to_program}
                            size="normal"
                            target="_blank"
                        >
                            {labelProgramButton}
                        </ButtonLink>
                    )}
                </section>
            </Body>

            <Body pad="extra" size="big">
                {comparativeEntity.party_leader_photo && (
                    <header className="ComparativeEntityInformation__head">
                        <Image
                            alt={`${labelLeader} ${comparativeEntity.party_leader}`}
                            src={comparativeEntity.party_leader_photo}
                            objectFit="contain"
                        />
                    </header>
                )}

                <section className="ComparativeEntityInformation__body">
                    {comparativeEntity.party_leader && (
                        <SubTitle strong={true}>
                            {labelLeader} {comparativeEntity.party_leader}
                        </SubTitle>
                    )}
                    {comparativeEntity.party_leader_info && (
                        <Text size="big">
                            {comparativeEntity.party_leader_info}
                        </Text>
                    )}

                    {candidateList && (
                        <SubTitle strong={true}>{labelCandidates}</SubTitle>
                    )}
                    {candidateList && (
                        <Text
                            className="Text ComparativeEntityInformation__candidate-summary"
                            size="big"
                            tag="div"
                        >
                            {renderCandidateList()}
                        </Text>
                    )}

                    {candidateList && (
                        <Button
                            size="normal"
                            onClick={() => setCandidateListModalState(true)}
                        >
                            {labelCandidatesButton}
                        </Button>
                    )}

                    <Modal
                        active={candidateListModalState}
                        allowclose={true}
                        labelClose={labelClose}
                        onClose={() => setCandidateListModalState(false)}
                        title={`${labelCandidates}`}
                    >
                        <Body pad="extra">{renderCandidateList()}</Body>
                    </Modal>
                </section>
            </Body>
        </article>
    );
};

ComparativeEntityInformation.propTypes = {
    /** Child components/content.  */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.element, PropTypes.string])
        ),
    ]),

    /** Comparative entity. */
    comparativeEntity: PropTypes.object,

    /** The label for the candidates list. */
    labelCandidates: PropTypes.string,

    /** The label for the candidates button. */
    labelCandidatesButton: PropTypes.string,

    /** Label shown on close button (mobile only). */
    labelClose: PropTypes.string,

    /** The label for the leader. */
    labelLeader: PropTypes.string,

    /** The label for the program button. */
    labelProgramButton: PropTypes.string,
};

ComparativeEntityInformation.defaultProps = {
    comparativeEntity: {},
    labelClose: null,
};
