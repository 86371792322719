import React from "react";
import { IconButton } from "../../components/icon-button/IconButton";
import { deepGet } from "../../lib/util";

/**
 * Renders the Facebook share button.
 * @param {Tool} tool
 * @param {Function} onClick
 * @param [background]
 * @param [color]
 * @param [size]
 * @param [pad]
 * @param [tabIndex]
 * @return {JSX.Element|null}
 */
export const renderMoreTools = (
    tool,
    onClick,
    background = "#3A3F43",
    color = "#FFF",
    size = "normal",
    pad = false,
    tabIndex = 304
) => {
    if (
        !deepGet(tool, "social_tool_setting") ||
        !deepGet(tool, "tools_to_link_to.length")
    ) {
        return null;
    }

    return (
        <IconButton
            icon="compass-circle"
            background={background}
            color={color}
            label={deepGet(tool, "social_tool_setting.more_tools")}
            pad={pad}
            size={size}
            onClick={onClick}
            tabIndex={tabIndex}
        />
    );
};
