import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./segment.scss";

/**
 * Segments display a group of related buttons, sometimes known as segmented controls, in a horizontal row.
 */
export const Segment = ({ justify, size, ...props }) => {
    /**
     * Returns the amount of childre (buttons).
     * @return {number}
     */
    const getChildrenCount = () => {
        if (!props.children) {
            return 0;
        }

        const _children = props.children.length
            ? props.children
            : [props.children];
        return _children.filter((child) => child).length;
    };

    const className = classNames({
        Segment: true,
        [`Segment--justify-${getChildrenCount()}`]: justify,
        [`Segment--${size}`]: size,
    });

    return (
        <div className={className} {...props}>
            {props.children}
        </div>
    );
};

Segment.propTypes = {
    /** Child components/content.  */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.element, PropTypes.string])
        ),
    ]),

    /** Justify buttons. */
    justify: PropTypes.bool,

    /** Button size preset. */
    size: PropTypes.oneOf(["normal", "small", "smaller"]),
};

Segment.defaultProps = {
    justify: true,
    size: "normal",
};
