import React from "react";
import { renderCompass } from "./renderCompass";
import { renderJustificationTable } from "./renderJustificationTable";
import { renderPersona } from "./renderPersona";
import { renderRanking } from "./renderRanking";
import { renderUniverse } from "./renderUniverse";
import { renderReferendum } from "./renderReferendum";
import { RangeFilter } from "../../components/range-filter/RangeFilter";
import { StatementFilter } from "../../components/statement-filter/StatementFilter";
import { ThemeFilter } from "../../components/theme-filter/ThemeFilter";
import { Col } from "../../components/layout/Col";
import { ComparativeEntityFilter } from "../../components/comparative-entity-filter/ComparativeEntityFilter";
import { renderInfo } from "./renderInfo";

export const PREFERRED_CALCULATION = {
    AGREEMENT: "agreement",
    DISTANCE: "distance",
};

const renderComparativeEntityFilter = ({
    toolState,
    comparativeEntitiesState,
    activeComparativeEntitiesState,
    onComparativeEntityFilterClick,
    onRangeFilterChange,
}) => (
    <>
        {toolState.result_configuration.regional_national_filter && (
            <RangeFilter
                comparativeEntities={comparativeEntitiesState}
                labelNational={toolState.result_configuration.national}
                labelRegional={toolState.result_configuration.regional}
                title={
                    toolState.result_configuration.regional_national_filter_text
                }
                onChange={onRangeFilterChange}
            />
        )}

        <ComparativeEntityFilter
            comparativeEntities={activeComparativeEntitiesState}
            onClick={onComparativeEntityFilterClick}
        />
    </>
);

/**
 * Renders the theme filter.
 * @return {JSX.Element}
 */
const renderThemeFilter = ({
    toolState,
    statementsState,
    comparativeEntitiesState,
    activeThemesState,
    sessionState,
    setErrorState,
    match,
    onRangeFilterChange,
    onThemeFilterClick,
}) => {
    return (
        <>
            {toolState.result_configuration.regional_national_filter && (
                <RangeFilter
                    comparativeEntities={comparativeEntitiesState}
                    labelNational={toolState.result_configuration.national}
                    labelRegional={toolState.result_configuration.regional}
                    defaultNational={
                        toolState.result_configuration.national_default_selected
                    }
                    defaultRegional={
                        toolState.result_configuration.regional_default_selected
                    }
                    title={
                        toolState.result_configuration
                            .regional_national_filter_text
                    }
                    onChange={onRangeFilterChange}
                />
            )}

            <ThemeFilter
                statements={statementsState}
                title={toolState.result_configuration.main_result_title}
                themes={activeThemesState}
                sessionState={sessionState}
                setErrorState={setErrorState}
                match={match}
                onClick={onThemeFilterClick}
            />
        </>
    );
};

/**
 * Renders the statement filter.
 * @return {JSX.Element}
 */
const renderStatementFilter = ({
    comparativeEntitiesState,
    statementsState,
    toolState,
    onStatementFilterChange,
    onRangeFilterChange,
}) => (
    <>
        {toolState.result_configuration.regional_national_filter && (
            <RangeFilter
                comparativeEntities={comparativeEntitiesState}
                labelNational={toolState.result_configuration.national}
                labelRegional={toolState.result_configuration.regional}
                title={
                    toolState.result_configuration.regional_national_filter_text
                }
                onChange={onRangeFilterChange}
            />
        )}
        <StatementFilter
            statements={statementsState}
            title={toolState.result_configuration.statement_filter_title}
            labelThemes={toolState.result_configuration.main_result_title}
            onChange={onStatementFilterChange}
        />
    </>
);

/**
 * The tool types.
 * @type {Object}
 */
export const TOOLTYPES = {
    COMPASS: {
        slug: "compass",
        icon: "topic",
        initialCollapsed: false,

        renderTool: (props) => (
            <Col
                compensatetop={true}
                compensateleft={true}
                valign="center"
                pad={true}
                padright={true}
            >
                {renderCompass(props)}
            </Col>
        ),
        renderSidebar: renderThemeFilter,
    },

    JUSTIFICATION: {
        slug: "compareJustificationPage",
        icon: "answer",
        initialCollapsed: true,

        renderTool: (props) => renderJustificationTable(props),
        renderSidebar: renderStatementFilter,
    },

    INFO: {
        slug: "info",
        icon: "pin",

        renderTool: renderInfo,
        renderSidebar: renderComparativeEntityFilter,
    },

    PERSONA: {
        slug: "persona",
        icon: "person",
        initialCollapsed: false,

        renderTool: (props) => renderPersona(props),
        renderSidebar: renderThemeFilter,
    },

    RANKING: {
        slug: "ranking",
        icon: "ranking",
        initialCollapsed: false,

        renderTool: (props) => renderRanking(props),
        renderSidebar: renderThemeFilter,
    },

    UNIVERSE: {
        slug: "universe",
        icon: "universe",
        initialCollapsed: true,

        renderTool: (props) => renderUniverse(props),
        renderSidebar: renderThemeFilter,
    },

    REFERENDUM: {
        slug: "referendum",
        icon: "referendum",
        initialCollapsed: false,

        renderTool: (props) => renderReferendum(props),
        renderSidebar: renderThemeFilter,
    },
};
