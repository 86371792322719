import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./container.scss";

/**
 * A container limits the maximums size of the content.
 */
export const Container = ({ size, ...props }) => {
    const className = classNames({
        Container: true,
        [`Container--${size}`]: size,
    });

    return (
        <div className={className} {...props}>
            {props.children}
        </div>
    );
};

Container.propTypes = {
    /** The container size. */
    size: PropTypes.oneOf(["normal", "big"]),
};

Container.defaultProps = {
    size: "normal",
};
