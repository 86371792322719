/** The API hostname used for development. */
import { getLanguageFromUrl } from "../lib/i18n";

const DEV_API_HOSTNAME = "multidimension.kieskompas.maykin.nl"; // NEVER DEFAULT TO PRODUCTION!

/** @const {number} The API version. */
const API_VERSION = 2;

/** @const {string} The API key. */
export const API_KEY = "pS73MFiZf4g5SFONz2QC75sSNFcVmSOX";

/**
 * Returns the base endpoint for the API.
 * @param {string} language Language iso code.
 * @return {string}
 */
export const getApiBaseEndpoint = (language) => {
    const hostname = getApiHostname();
    const _language = language || getLanguageFromUrl();
    return `https://${hostname}/${_language}/api/v${API_VERSION}`;
};

/**
 * Returns the API hostname used to make API calls.
 * @return {string}
 */
const getApiHostname = () => {
    return isDevelopmentEnvironment()
        ? DEV_API_HOSTNAME
        : String(window.location.hostname);
};

/**
 * Returns whether the instance is assumed to run in a development setting.
 * @return {boolean}
 */
export const isDevelopmentEnvironment = () => {
    const hostname = String(window.location.hostname);
    const developmentPatterns = [
        "localhost",
        "127.0.0.1",
        "192.168",
        "ngrok", // ngrok.io
    ];

    return Boolean(
        developmentPatterns.find((pattern) => hostname.match(pattern))
    );
};
