import React from "react";
import { Col } from "../../components/layout/Col";
import { Referendum } from "../../components/referendum/Referendum";
import { calculateCompassAxisPosition } from "../../lib/score/position_score";
import { filterStatementsByThemes } from "../../lib/statement";
import { deepGet } from "../../lib/util";
import { Caption } from "../../components/typography/Caption";
import { Body } from "../../components/typography/Body";

/**
 * Renders the compass.
 * TODO: [API TRANSLATIONS] separate general_texts.result ->  result_configuration.referendum_configuration.result_caption_result
 * @returns {JSX.Element}
 */
export const renderReferendum = ({
    activeThemesState,
    statementsState,
    toolState,
}) => {
    const _statements = filterStatementsByThemes(
        statementsState,
        activeThemesState
    );
    const userPosition = calculateCompassAxisPosition("x", _statements);
    const value = (userPosition / 2) * 100;
    const statement = deepGet(
        toolState,
        "result_configuration.referendum_configuration.explanation"
    );

    return (
        <Col valign="center" pad={true} padright={true}>
            <Body size="big">
                <Caption align="center">{statement}</Caption>
            </Body>
            <Referendum
                labelResult={deepGet(
                    toolState,
                    "general_texts.result",
                    "Resultaat"
                )}
                labelPositive={deepGet(
                    toolState,
                    "result_configuration.referendum_configuration.result_caption_positive",
                    "Voor"
                )}
                labelNegative={deepGet(
                    toolState,
                    "result_configuration.referendum_configuration.result_caption_negative",
                    "Tegen"
                )}
                value={value}
            />
        </Col>
    );
};
