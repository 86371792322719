import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Button } from "../button/Button";
import { Icon } from "../icon/Icon";
import "./col.scss";

/**
 * Columns are vertical components that can contain one or more child components.
 */
export const Col = ({
    backgroundImage,
    blur,
    compensatetop,
    compensateleft,
    fill,
    overflow,
    noOverflow,
    pad,
    padright,
    span,
    align,
    valign,
    collapsible,
    collapsed,
    labelCollapse,
    width,
    height,
    minHeight,
    ...props
}) => {
    /**
     * Returns the initial collapsed stated (if required) based on the viewport size.
     * @return {boolean}
     */
    const getInitialCollapsedState = () => {
        try {
            return Boolean(matchMedia("(max-width: 767px)").matches);
        } catch (e) {
            return false;
        }
    };

    const [collapsedState, setCollapsedState] = useState(
        getInitialCollapsedState()
    );
    useEffect(() => {
        typeof collapsed === "boolean" && setCollapsedState(collapsed);
    }, [collapsed]);

    const className = classNames({
        Col: true,
        "Col--blur": blur,
        "Col--collapsible": collapsible,
        "Col--collapsed": collapsedState,
        "Col--compensate-top": compensatetop,
        "Col--compensate-left": compensateleft,
        "Col--fill": fill,
        "Col--overflow": overflow,
        "Col--no-overflow": noOverflow,
        "Col--pad": pad,
        "Col--pad-right": padright,
        [`Col--${span}`]: span,
        [`Col--${!align || align === "center" ? "middle" : "side"}`]: align,
        [`Col--${valign}`]: valign,
        [`Col--${width}`]: typeof width === "string",
    });

    /**
     * Returns the style to apply to the column.
     * @return {{minHeight, backgroundImage: (string|null), width: (string|null), height: (string|null)}}
     */
    const getStyle = () => {
        return {
            backgroundImage: backgroundImage ? `url(${backgroundImage})` : null,
            width: typeof width === "number" ? `${(1 / 16) * width}rem` : null,
            height:
                typeof height === "number"
                    ? `${(1 / 16) * height}rem`
                    : height || null,
            minHeight: minHeight,
        };
    };

    /**
     * Renders the collapse (if required).
     * @return {JSX.Element|null}
     */
    const renderCollapse = () => {
        if (!collapsible) {
            return null;
        }

        return (
            <Button
                className="Button Button--primary Col__collapse"
                pad={false}
                onClick={() => setCollapsedState(!collapsedState)}
                tabIndex={299}
            >
                <Icon icon="chevron-right" label={labelCollapse} />
            </Button>
        );
    };

    return (
        <div className={className} style={getStyle()} {...props}>
            {renderCollapse()}
            {props.children}
        </div>
    );
};

Col.propTypes = {
    /** Child components/content.  */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.array,
    ]),

    /** URL of a background image to show. */
    backgroundImage: PropTypes.string,

    /** Whether to blur the background image.. */
    blur: PropTypes.bool,

    /** Whether to fill the background with a color. */
    fill: PropTypes.bool,

    /** Whether overflow content should be visible.  */
    overflow: PropTypes.bool,

    /** Whether to actively prevent overflowing.  */
    noOverflow: PropTypes.bool,

    /** Whether to apply padding. */
    pad: PropTypes.bool,

    /** Whether to apply (additional) padding right. */
    padright: PropTypes.bool,

    /** Whether to apply margin of negative value to the top (fixes center position). */
    compensatetop: PropTypes.bool,

    /** Whether to apply margin of negative value to the left (fixes center position). */
    compensateleft: PropTypes.bool,

    /** The number of columns to span.  */
    span: PropTypes.number,

    /** The alignment of the column's content.  */
    align: PropTypes.oneOf(["center", "side"]),

    /** The vertical alignment of the column's content.  */
    valign: PropTypes.oneOf(["top", "center", "bottom"]),

    /** Allow the user to collapse the column. */
    collapsible: PropTypes.bool,

    /** Collapsed state. */
    collapsed: PropTypes.bool,

    /** The collapse label. */
    labelCollapse: PropTypes.string,

    /** The column width. */
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.oneOf(["sidebar-left", "sidebar-right"]),
    ]),

    /** The column height. */
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

    /** The minimum column height. */
    minHeight: PropTypes.string,
};

Col.defaultProps = {
    backgroundImage: "",
    blur: false,
    fill: false,
    height: null,
    minHeight: null,
    overflow: false,
    noOverflow: false,
    pad: false,
    padright: false,
    compensatetop: false,
    compensateleft: false,
    span: 12,
    align: "center",
    valign: "top",
    collapsible: false,
    collapsed: null,
    labelCollapse: "Collapse",
    width: null,
};
