import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { getParent, isChildOf } from "../../lib/dom";
import { KEYCODES } from "../../lib/keycode";
import { Icon } from "../icon/Icon";
import { IconButton } from "../icon-button/IconButton";
import { SubCaption } from "../typography/SubCaption";
import "./modal.scss";

/**
 * A Modal is a dialog that appears on top of the app's content, and must be dismissed by the app before interaction can resume.
 * It is useful as a select component when there are a lot of options to choose from, or when filtering items in a list,
 * as well as many other use cases.
 */
export const Modal = ({
    active,
    allowclose,
    animationspeed,
    dark,
    filled,
    iconProps,
    labelClose,
    maxwidth,
    position,
    size,
    title,
    ...props
}) => {
    const node = useRef();
    const [isActive, setIsActive] = useState(active);
    const refHeader = useRef();
    const refBody = useRef();

    const setFocus = useCallback(() => {
        if (isActive) {
            try {
                const bodyTarget = refBody.current?.querySelector(
                    "input, select, textarea, button, .MenuLink"
                );
                const headerTarget = refHeader.current?.querySelector("button");
                const target = bodyTarget || headerTarget;

                target?.focus();
            } catch (e) {}
        }
    }, [isActive, refBody, refHeader]);

    /**
     * Closes the modal.
     * @param {SyntheticEvent} [e]
     * @param {boolean} [force=false] Whether to skip target check.
     */
    const close = useCallback(
        (e, force = false) => {
            if (!allowclose) {
                return;
            }

            if (e && e.currentTarget !== e.target && !force) {
                return;
            }

            setIsActive(false);

            if (props.onClose) {
                props.onClose(e, false);
            }
        },
        [allowclose, setIsActive, props]
    );

    /**
     * onKeyUp callback.
     * @param {SyntheticEvent} e
     */
    const onKeyUp = useCallback(
        (e) => {
            if (e.keyCode === KEYCODES.ESCAPE && allowclose) {
                close();
            }

            const target = document.activeElement;
            let isHeaderChild = false;
            let isBodyChild = false;

            if (refHeader.current) {
                isHeaderChild = isChildOf(target, refHeader.current);
            }

            if (refBody.current) {
                isBodyChild = isChildOf(target, refBody.current);
            }

            if (!isHeaderChild && !isBodyChild) {
                setFocus();
            }
        },
        [allowclose, close, refHeader, refBody, setFocus]
    );

    // 1. Sync active state.
    // 2. Fix "z-index bug" on mobile Safari/issues on smaller screens.
    useEffect(() => {
        // 1. Sync active state.
        setIsActive(active);

        // 2. Fix "z-index bug" on mobile Safari/issues on smaller screens.
        if (!node.current) {
            return;
        }

        const row = getParent(node.current, ".Row");
        const rowTop = getParent(node.current, ".Row--top");

        if (!row) {
            return;
        }

        const cols = row.querySelectorAll(".Col");

        row.style.removeProperty("z-index");
        [...cols].forEach((col) => col.style.removeProperty("overflow-y"));

        if (active) {
            row.style.zIndex = rowTop ? "30000" : "20000";
            [...cols].forEach((col) => (col.style.overflowY = "unset"));
        }
    }, [active]);

    useEffect(() => {
        document.addEventListener("keyup", onKeyUp);
        return () => document.removeEventListener("keyup", onKeyUp);
    }, [props, onKeyUp]);

    /**
     * Focuses the first focusable element.
     */
    useEffect(() => setFocus(), [isActive, setFocus]);

    const className = classNames({
        Modal: true,
        "Modal--active": isActive,
        "Modal--dark": dark,
        "Modal--filled": filled,
        [`Modal--animate-${animationspeed}`]: animationspeed,
        [`Modal--${position}`]: position,
        [`Modal--${size}`]: size,
    });

    /**
     * Returns the modal style.
     * @return {{maxWidth: (string|null)}|null}
     */
    const getModalStyle = () => {
        if (!maxwidth) {
            return null;
        }

        return {
            maxWidth:
                position === "float" && maxwidth ? `${maxwidth / 16}rem` : null,
        };
    };

    /**
     * Renders the header.
     * @return {JSX.Element|null}
     */
    const renderHeader = () => {
        if (!iconProps && !title) {
            return null;
        }

        return (
            <header ref={refHeader} className="Modal__header">
                {iconProps ? <Icon {...iconProps} /> : null}
                {title ? <SubCaption color="black">{title}</SubCaption> : null}
                {allowclose ? (
                    <IconButton
                        alt={labelClose}
                        icon="close"
                        pad={false}
                        onClick={() => close()}
                        tabIndex={isActive ? 1 : -1}
                    />
                ) : null}
            </header>
        );
    };

    return (
        <div ref={node} className={className} {...props}>
            <div className="Modal__overlay" onClick={close}>
                <div
                    className="Modal__modal"
                    style={getModalStyle()}
                    aria-expanded={isActive}
                >
                    {renderHeader()}

                    <div ref={refBody} className="Modal__body">
                        {isActive && props.children}
                    </div>
                </div>
            </div>
        </div>
    );
};

Modal.propTypes = {
    /** Child components/content.  */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.element, PropTypes.string])
        ),
    ]),

    /** The active state of the modal. */
    active: PropTypes.bool,

    /** Whether to allow the modal to close. */
    allowclose: PropTypes.bool,

    /** The animation speed. */
    animationspeed: PropTypes.oneOf(["normal", "fast"]),

    /** Whether to use a darker overlay. */
    dark: PropTypes.bool,

    /** Whether to use a filled overlay. */
    filled: PropTypes.bool,

    /** Props to pass to the icon. */
    iconProps: PropTypes.shape(Icon.propTypes),

    /** Label shown on close button (mobile only). */
    labelClose: PropTypes.string,

    /** Maximum width in pixels. */
    maxwidth: PropTypes.number,

    /** The position of the button. */
    position: PropTypes.oneOf(["float", "side"]),

    /** Font size preset. */
    size: PropTypes.oneOf(["small", "smaller", "normal", "big", "fullscreen"]),

    /** The title. */
    title: PropTypes.string,

    /** onClose callback. */
    onClose: PropTypes.func,
};

Modal.defaultProps = {
    active: true,
    allowclose: false,
    animationspeed: "normal",
    dark: true,
    filled: false, // Only for #1169
    iconProps: null,
    labelClose: null,
    maxwidth: null,
    position: "float",
    size: "small",
    title: "",
    onClose: () => {},
};
