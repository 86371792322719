/**
 * Returns whether the current device screen size is mobile-like.
 * @return {boolean}
 */
export const isMobile = () => {
    try {
        return Boolean(matchMedia("(max-width: 767px)").matches);
    } catch (e) {
        return false;
    }
};

/**
 * Returns whether the current "App" is in RTL (right to left) mode.
 * @return {boolean}
 */
export const isRtl = () => {
    return Boolean(document.querySelector(".App[dir=rtl]"));
};
