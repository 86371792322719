/**
 * Finds all computed styles and applies them to inline style attribute.
 * @param {HTMLElement} node HTML element to consolidate styles for.
 * @param {boolean|string} recurse Whether to recurse children.
 */
export const consolidateStyles = (node, recurse = true) => {
    // Consolidate.
    try {
        const computedStyle = getComputedStyle(node);
        Object.values(computedStyle).forEach((property) => {
            const value = computedStyle[property];
            node.style[property] = value;
        });
    } catch (e) {
        console.warn("Can't consolidate styles for:", node);
        console.warn(e);
    }

    // Recurse.
    const recurseChildren =
        typeof recurse === "boolean"
            ? node.children
            : node.querySelectorAll(recurse);

    if (recurseChildren) {
        [...recurseChildren].forEach((child) =>
            consolidateStyles(child, recurse)
        );
    }
};

/**
 * Returns first parent of node matching selector.
 * @param {HTMLElement} node
 * @param {string} selector
 * @param {number} maxDepth
 */
export const getParent = (node, selector, maxDepth = 10) => {
    let element = node.parentElement;

    if (element && element.matches(selector)) {
        return element;
    }

    if (element && maxDepth > 0) {
        return getParent(element, selector, maxDepth - 1);
    }

    return null;
};

/**
 * Returns whether node is a child of parent.
 * @param {HTMLElement}node
 * @param {HTMLElement} parent
 */
export const isChildOf = (node, parent) => {
    let child = node.parentNode;

    while (child !== null) {
        if (child === parent) {
            return true;
        }
        child = child.parentNode;
    }
    return false;
};
