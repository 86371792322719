import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./title.scss";

/**
 * Title is a simple component that can be used to style titles.
 */
export const Title = ({ align, color, fontsize, strong, wrap, ...props }) => {
    const className = classNames({
        Title: true,
        "Title--strong": strong,
        "Title--wrap": wrap,
        [`Title--${align}`]: align,
    });

    const getStyle = () => {
        if (!color && !fontsize) {
            return null;
        }

        return {
            color,
            fontSize: fontsize ? `${fontsize / 16}rem` : null,
        };
    };

    return (
        <h1 className={className} {...props} style={getStyle()}>
            {props.children}
        </h1>
    );
};

Title.propTypes = {
    /** Child components/content.  */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.element, PropTypes.string])
        ),
    ]),

    /** The alignment based on the text direction (LTR/RTL). */
    align: PropTypes.oneOf(["side", "center"]),

    /** Color. */
    color: PropTypes.string,

    /** Font size in pixels. */
    fontsize: PropTypes.number,

    /** Whether to use strong text. */
    strong: PropTypes.bool,

    /** Whether to allow wrapping. */
    wrap: PropTypes.bool,
};

Title.defaultProps = {
    align: "side",
    color: null,
    fontsize: null,
    strong: false,
    wrap: true,
};
