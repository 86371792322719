import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useAnimation } from "../../lib/animation";

/**
 * CompassUserCircles shows the users position in the progressive/conservative left wing/right wing quadrant.
 * FIXME: Investigate render loop.
 */
export const CompassUserCircles = ({ color, count, size, x, y, ...props }) => {
    const renderedX = useAnimation(x);
    const renderedY = useAnimation(y);

    const getX = () => `${renderedX * 25 + 0}%`;
    const getY = () => `${-renderedY * 25 + 0}%`;

    const className = classNames({
        CompassUserCircles: true,
    });

    const renderCircles = () => {
        return new Array(count)
            .fill("")
            .map((value, index) => (
                <circle
                    key={index}
                    cx="50%"
                    cy="50%"
                    r={`${((size / count) * (index + 1)) / 2}%`}
                    stroke={color}
                    strokeWidth={2}
                    fill="none"
                ></circle>
            ));
    };

    return (
        <svg
            className={className}
            width="100%"
            height="100%"
            x={getX()}
            y={getY()}
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            {...props}
        >
            <title>User position area.</title>

            {renderCircles()}
        </svg>
    );
};

CompassUserCircles.propTypes = {
    /** Color. */
    color: PropTypes.string,

    /** The amount of circles. */
    count: PropTypes.number,

    /** The outer circle size (in percent). */
    size: PropTypes.number,

    /** The x position. */
    x: PropTypes.number,

    /** The y position. */
    y: PropTypes.number,
};

CompassUserCircles.defaultProps = {
    color: "#dfeff8",
    count: 4,
    size: 50,
    x: 0,
    y: 0,
};
