import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./menu-link.scss";

/**
 * MenuLink is a special link for use as child of a Menu component.
 */
export const MenuLink = ({ href, ...props }) => {
    const className = classNames({
        MenuLink: true,
    });

    return (
        <a className={className} href={href || "#"} {...props}>
            {props.children}
        </a>
    );
};

MenuLink.propTypes = {
    /** Child components/content.  */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.element, PropTypes.string])
        ),
    ]),

    /** The href attribute of the menuLink. */
    href: PropTypes.string,
};

MenuLink.defaultProps = {};
