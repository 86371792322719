import React from "react";
import { IconButton } from "../../components/icon-button/IconButton";
import { deepGet } from "../../lib/util";

/** The base Facebook share url. */
const FACEBOOK_SHARE_BASE_URL = "https://www.facebook.com/sharer/sharer.php?u=";

/** {number} Representing the initial width (in pixels) of the share window */
const WINDOW_WIDTH = 520;

/** {number} representing the initial height (in pixels) of the share window */
const WINDOW_HEIGHT = 350;

/**
 * Renders the Facebook share button.
 * @param {Tool} tool
 * @param [background]
 * @param [color]
 * @param [size]
 * @param [pad]
 * @param [tabIndex]
 * @return {JSX.Element|null}
 */
export const renderFacebook = (
    tool,
    background = "#4267B2",
    color = "#FFF",
    size = "normal",
    pad = false,
    tabIndex = 302
) => {
    if (
        !tool.social_tool_setting ||
        !tool.social_tool_setting.use_facebook_share
    ) {
        return;
    }

    return (
        <IconButton
            icon="facebook"
            alt={`${deepGet(
                tool,
                "social_tool_setting.share",
                "delen"
            )}: Facebook`}
            background={background}
            color={color}
            label="Facebook"
            pad={pad}
            size={size}
            onClick={() => openFacebookPopup(tool)}
            tabIndex={tabIndex}
        />
    );
};

/**
 * Opens the Facebook share popup.
 */
const openFacebookPopup = (tool) => {
    const url =
        FACEBOOK_SHARE_BASE_URL +
        tool.social_tool_setting.forced_general_share_url;
    window.open(
        url,
        tool.social_tool_setting.like,
        `width=${WINDOW_WIDTH},height=${WINDOW_HEIGHT}`
    );
};
