import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Icon } from "../icon/Icon";
import { Panel } from "../panel/Panel";
import { Text } from "../typography/Text";
import "./ptv-header.scss";

/**
 * PtvHeader is a header component for Ptv.
 */
export const PtvHeader = ({ labelLess, labelMore, ...props }) => {
    const className = classNames({
        PtvHeader: true,
    });

    return (
        <header className={className} {...props}>
            <Panel align="justify" pad={false} size="smaller" shadow={true}>
                <Text>
                    <Icon icon="chevron-left" /> {labelLess}
                </Text>
                <Text>
                    {labelMore} <Icon icon="chevron-right" />
                </Text>
            </Panel>
        </header>
    );
};

PtvHeader.propTypes = {
    /** Label indicating a lesser value. */
    labelLess: PropTypes.string,

    /** Label indicating a a greater value. */
    labelMore: PropTypes.string,
};

PtvHeader.defaultProps = {
    labelLess: "Zeer slecht",

    labelMore: "Zeer goed",
};
