import React from "react";
import { Col } from "../../components/layout/Col";
import { Ranking } from "../../components/ranking/Ranking";
import { filterStatementsByThemes } from "../../lib/statement";
import {
    score,
    sortComparativeEntitiesByScore,
} from "../../lib/score/comparative_entity_score";

/**
 * Renders the ranking.
 * @return {JSX.Element}
 */
export const renderRanking = ({
    statementsState,
    activeComparativeEntitiesState,
    activeThemesState,
    toolState,
}) => {
    const activeComparativeEntities = Array.from(
        activeComparativeEntitiesState
    ); // Get rid of ConsumerJS keys.

    const _statements = filterStatementsByThemes(
        statementsState,
        activeThemesState
    );
    const scoredComparativeEntities = sortComparativeEntitiesByScore(
        _statements,
        activeComparativeEntities,
        toolState.preferred_calculation
    );

    // Render sorted comparative entities.
    return (
        <Col pad={true} padright={true}>
            {activeComparativeEntities.map(
                (comparativeEntity, initialOrder) => {
                    const order = scoredComparativeEntities.findIndex(
                        (c) => c.pk === comparativeEntity.pk
                    );
                    const relativeOrder = order - initialOrder;
                    const offset = relativeOrder * 104; // FIXME: Get height dynamically?

                    return (
                        <Ranking
                            key={comparativeEntity.pk}
                            color={comparativeEntity.chart_color}
                            src={comparativeEntity.logo}
                            title={comparativeEntity.name}
                            value={comparativeEntity[score]}
                            style={{ transform: `translateY(${offset}px)` }}
                        />
                    );
                }
            )}
        </Col>
    );
};
