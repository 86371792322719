/**
 * Returns whether the selected/primary language requires RTL (right to left) mode.
 * @param {Tool} tool object.
 * @param {string} isoCode.
 * @return {boolean}
 */
export const isRtlLanguage = (tool, isoCode) => {
    const _isoCode = isoCode || getLanguageFromUrl();

    if (tool) {
        if (_isoCode) {
            const language = tool.languages.find(
                (language) => language.iso_code === _isoCode
            );

            if (language) {
                return language.is_rtl;
            }
        }
        return tool.primary_language.is_rtl;
    }
    return false;
};

/**
 * Return the language from the URL.
 * @return {string}
 */
export const getLanguageFromUrl = () => {
    const fallback = "nl"; // fallback to 'nl' for development purposes.

    try {
        const pathArray = window.location.pathname.split("/");
        return String(pathArray[1] || fallback).toLowerCase();
    } catch (e) {
        return String(fallback).toLowerCase();
    }
};
