import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Image } from "../image/Image";
import { Caption } from "../typography/Caption";
import { SubCaption } from "../typography/SubCaption";
import "./avatar.scss";

/**
 * The organisation's avatar with a configurable link.
 */
export const Avatar = ({ alt, color, src, width, height, size, ...props }) => {
    const className = classNames({
        Avatar: true,
        "Avatar--color": color,
    });

    const getCaption = () => {
        if (size === "big") {
            return <Caption color={color}>{props.children}</Caption>;
        }
        return <SubCaption color={color}>{props.children}</SubCaption>;
    };

    return (
        <div className={className}>
            <Image
                alt={alt || String(props.children)}
                src={src}
                width={width}
                height={height}
                objectFit="contain"
            />
            {getCaption()}
        </div>
    );
};

Avatar.propTypes = {
    /** Child components/content.  */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.element, PropTypes.string])
        ),
    ]),

    /** The alt attribute of the image. */
    alt: PropTypes.string,

    /** Color. */
    color: PropTypes.string,

    /** The src attribute of the desktop and fallback image. */
    src: PropTypes.string,

    /** The image width (in pixels). */
    width: PropTypes.number,

    /** The image height (in pixels). */
    height: PropTypes.number,

    /** Font size preset. */
    size: PropTypes.oneOf(["normal", "big"]),
};

Avatar.defaultProps = {
    alt: "",
    color: "black",
    src: "",
    width: 64,
    height: 64,
    size: "normal",
};
