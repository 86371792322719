/**
 * Calculates agreement score between the user and a comparativeEntity.
 * @param {Object[]} statements
 * @param {Object} comparativeEntity
 * @returns {number|null}
 */
export const calculateAgreementPercentage = (statements, comparativeEntity) => {
    const [agreementScore, maxAgreementScore] = calculateAgreementScore(
        statements,
        comparativeEntity
    );

    if (agreementScore === null) {
        return null;
    }

    return agreementScoreToPercentage(agreementScore, maxAgreementScore);
};

/**
 * Calculates agreement score between the user and a comparativeEntity.
 * @param {Object[]} statements
 * @param {Object} comparativeEntity
 * @returns {[number, number]|[null, null]}
 */
export const calculateAgreementScore = (statements, comparativeEntity) => {
    let score = 0;
    let maxScore = 0;
    let hasAnswers = false;

    // Loop over statements, increase score and max_score for each statement.
    statements
        .filter((statement) => statement.use_in_calculation !== false)
        .forEach((statement) => {
            const statementId = parseInt(statement.id);
            const userAnswerValue = getUserAnswerValue(statements, statementId);
            const comparativeEntityAnswer = getComparativeEntityAnswer(
                comparativeEntity,
                statementId
            );
            const comparativeEntityAnswerValue = comparativeEntityAnswer
                ? parseInt(comparativeEntityAnswer.value)
                : null;

            // Comparative entity did not report an answer.
            if (
                !comparativeEntityAnswer ||
                comparativeEntityAnswer.is_skip ||
                comparativeEntityAnswerValue === 99 ||
                isNaN(parseInt(comparativeEntityAnswerValue))
            ) {
                return;
            }

            // User has no opinion.
            if (userAnswerValue === null || userAnswerValue === 99) {
                return;
            }

            // Increase score based on matrix.
            // See: https://taiga.maykinmedia.nl/media/attachments/a/a/c/3/e6cc4f1815c0ec25ebf28fd462a9bb25eabb5d7a0cff942d45ccffdba415/formules_ranking.pdf.
            // prettier-ignore
            const matrix = [
                [+2, +1, -1, -1, -2],
                [+1, +2, -1, -1, -1],
                [-1, -1, +2, -1, -1],
                [-1, -1, -1, +2, +1],
                [-2, -1, -1, +1, +2],
            ];

            const row = matrix[userAnswerValue + 2];
            const column = row[comparativeEntityAnswerValue + 2];

            // Results.
            hasAnswers = true;
            score += column;
            maxScore += 2;
        });

    // Return results.
    if (!hasAnswers) {
        return [null, null];
    }
    return [score, maxScore];
};

/**
 * Converts agreement score to percentage.
 * @param {number} agreementScore Most likely the output of `calculateAgreementScore()`.
 * @param {number} maxAgreementScore The maximum possible score.
 * @returns {number}
 */
export const agreementScoreToPercentage = (
    agreementScore,
    maxAgreementScore
) => {
    if (maxAgreementScore === 0) {
        return 0;
    }

    return (
        ((agreementScore + maxAgreementScore) / (2 * maxAgreementScore)) * 100
    );
};

/**
 * Returns the user's answer value.
 * @param {Object[]} statements
 * @param {number} statementId
 * @returns {number|null}
 */
export const getUserAnswerValue = (statements, statementId) => {
    const statement = statements.find(
        (statement) => parseInt(statement.id) === parseInt(statementId)
    );
    const answer = statement.value;

    if (typeof answer === "undefined") {
        return null;
    }

    return parseInt(answer);
};

/**
 * Returns a comparative entity answer object.
 * @param {Object} comparativeEntity
 * @param {number} statementId
 * @return {Object}
 */
export const getComparativeEntityAnswer = (comparativeEntity, statementId) => {
    const comparativeEntityStatement = getComparativeEntityFkAnswer(
        comparativeEntity,
        statementId
    );
    return comparativeEntityStatement
        ? comparativeEntityStatement.answer
        : null;
};

/**
 * Returns a comparative entity answer object.
 * @param {Object} comparativeEntity
 * @param {number} statementId
 * @return {Object[]}
 */
export const getComparativeEntityJustifications = (
    comparativeEntity,
    statementId
) => {
    const comparativeEntityStatement = getComparativeEntityFkAnswer(
        comparativeEntity,
        statementId
    );

    return comparativeEntityStatement
        ? [
              comparativeEntityStatement.justification_1,
              comparativeEntityStatement.justification_2,
              comparativeEntityStatement.justification_3,
          ].filter(
              (justification) => justification && justification.justification
          )
        : [];
};

/**
 * Returns the "fk_answer" section of a comparative entity matched by a statements id.
 * @param comparativeEntity
 * @param statementId
 * @return {Object|null}
 */
const getComparativeEntityFkAnswer = (comparativeEntity, statementId) => {
    const comparativeEntityStatements = comparativeEntity.fk_answers;
    return (
        comparativeEntityStatements.find(
            (comparativeEntityStatement) =>
                parseInt(comparativeEntityStatement.statement.id) ===
                parseInt(statementId)
        ) || null
    );
};
