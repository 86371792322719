import { CrudConsumer, CrudConsumerObject } from "consumerjs";
import { API_KEY, getApiBaseEndpoint } from "./api";
import {
    LOCALSTORAGE_TOOL_KEY,
    LOCALSTORAGE_TIMEOUT,
    LOCALSTORAGE_TIMEOUT_KEY,
} from "../settings/config";

/**
 * Tool.
 */
class Tool extends CrudConsumerObject {
    /**
     * Caches the consumer object and returns a promise.
     * Promise resolves or rejects with consumer object based on cache success.
     * @param {CrudConsumerObject} consumerObject
     * @returns {Promise}
     */
    setCache() {
        return this.__consumer__.setCache(this);
    }
}

/**
 * Tool consumer.
 */
export class ToolConsumer extends CrudConsumer {
    constructor(
        endpoint = `${getApiBaseEndpoint()}/tools/`,
        objectClass = Tool,
        options = { csrfProtection: false }
    ) {
        super(endpoint, objectClass, options);

        /** @type {string} The localstorage key to use for cache. */
        this.localstorageKey = LOCALSTORAGE_TOOL_KEY;

        this.addHeader("AUTHORIZATION", API_KEY);
    }

    /**
     * Returns a promise for the current consumer object, retrieved from cache or received.
     * @returns {Promise}
     */
    getOrRead() {
        return this.getFromCache()
            .catch(this.read.bind(this))
            .then(this.setCache.bind(this));
    }

    /**
     * Returns the a promise for the cached consumer object (if set).
     * Promise resolves with consumer object or rejects.
     * @returns {Promise}
     */
    getFromCache() {
        try {
            if (LOCALSTORAGE_TIMEOUT) {
                const timestamp = localStorage.getItem(
                    LOCALSTORAGE_TIMEOUT_KEY
                );

                if (
                    !timestamp ||
                    (new Date() - new Date(parseInt(timestamp))) / 1000 >
                        LOCALSTORAGE_TIMEOUT
                ) {
                    localStorage.clear();
                }
            }

            const value = localStorage.getItem(this.localstorageKey);
            const data = JSON.parse(value);
            const consumerObject = data
                ? new this.objectClass(data, this)
                : null;
            if (consumerObject) {
                return Promise.resolve(consumerObject);
            }
        } catch (e) {
            console.warn(e);
        }
        return Promise.reject();
    }

    /**
     * Caches the consumer object and returns a promise.
     * Promise resolves or rejects with consumer object based on cache success.
     * @param {CrudConsumerObject} consumerObject
     * @returns {Promise}
     */
    setCache(consumerObject) {
        try {
            const value = consumerObject.asJSON();
            localStorage.setItem(this.localstorageKey, value);
            localStorage.setItem(
                LOCALSTORAGE_TIMEOUT_KEY,
                new Date().getTime()
            );
            return Promise.resolve(consumerObject);
        } catch (e) {
            console.warn(e);
        }
        return Promise.resolve(consumerObject);
    }

    /**
     * Sets the language.
     * @param {Object} language
     */
    setLanguage(language) {
        this.endpoint = `${getApiBaseEndpoint(language)}/tools/`;
    }

    /**
     * Sets the session header.
     * @param {Session} session
     */
    setSession(session) {
        this.addHeader("x-sessionid", session.session);
    }

    /**
     * Custom parseList implementation.
     * Deals with Array format for tool.
     * @param {Object[]} array
     * @param {Object} responseData The response data as Object.
     * @param {string} method The request method.
     * @param {string} path The request path.
     * @param {Object} data The request data payload.
     * @returns {Tool}
     */
    parseList(array, responseData, method, path, data) {
        const object = array.length ? array[0] : {};
        return this.parseScalar(object, responseData, method, path, data);
    }
}
