import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Panel } from "../panel/Panel";
import { SubCaption } from "../typography/SubCaption";
import { Toggle } from "../form/Toggle";
import "./range-filter.scss";

/**
 * Returns the active comparative entities based on nationalActive and regionalActive.
 * @param {ComparativeEntity[]} comparativeEntities
 * @param {boolean} nationalActive
 * @param {boolean} regionalActive
 * @return {Object[]}
 */
export const getActiveComparativeEntities = (
    comparativeEntities,
    nationalActive,
    regionalActive
) => {
    // National and regional.
    if (nationalActive && regionalActive) {
        return comparativeEntities;
    }

    // National only.
    if (nationalActive && !regionalActive) {
        return comparativeEntities.filter(
            (c) => c.regional_or_national === "national"
        );
    }

    // Regional only.
    if (!nationalActive && regionalActive) {
        return comparativeEntities.filter(
            (c) => c.regional_or_national === "regional"
        );
    }

    return [];
};

export const RangeFilter = ({
    comparativeEntities,
    labelNational,
    labelRegional,
    defaultNational,
    defaultRegional,
    title,
    onChange,
    ...props
}) => {
    const [regionalActiveState, setRegionalActiveState] =
        useState(defaultRegional);
    const [nationalActiveState, setNationalActiveState] =
        useState(defaultNational);

    /**
     * Calls onChange with `activeComparativeEntities`.
     * To prevent possible infinite loops, `onChange` is omitted from dependency array.
     */
    const updateComparativeEntities = useCallback(() => {
        const activeComparativeEntities = getActiveComparativeEntities(
            comparativeEntities,
            nationalActiveState,
            regionalActiveState
        );
        onChange(null, activeComparativeEntities);
    }, [comparativeEntities, nationalActiveState, regionalActiveState]); // eslint-disable-line react-hooks/exhaustive-deps

    const onNationalToggleChange = () => {
        setNationalActiveState(!nationalActiveState);
    };

    const onRegionalToggleChange = () => {
        setRegionalActiveState(!regionalActiveState);
    };

    const className = classNames({
        RangeFilter: true,
    });

    useEffect(updateComparativeEntities, [
        comparativeEntities,
        nationalActiveState,
        regionalActiveState,
        updateComparativeEntities,
    ]);
    useEffect(() => {
        setTimeout(() => {
            updateComparativeEntities();
        }, 0);
    }, [comparativeEntities, updateComparativeEntities]);

    const renderToggles = () => {
        return (
            <>
                <Toggle
                    checked={nationalActiveState}
                    onChange={onNationalToggleChange}
                    tabIndex={201}
                >
                    {labelNational}
                </Toggle>

                <Toggle
                    checked={regionalActiveState}
                    onChange={onRegionalToggleChange}
                    tabIndex={202}
                >
                    {labelRegional}
                </Toggle>
            </>
        );
    };

    delete props.statements;
    return (
        <aside className={className} {...props}>
            <header className="RangeFilter__head">
                <Panel align="side" direction="vertical" grow={true}>
                    <SubCaption color="black">{title}</SubCaption>
                </Panel>
            </header>

            <div className="RangeFilter__body">
                <Panel align="side" direction="vertical" grow={true}>
                    {renderToggles()}
                </Panel>
            </div>
        </aside>
    );
};

RangeFilter.propTypes = {
    /** The comparativeEntities. */
    comparativeEntities: PropTypes.array,

    /** The "national" label. */
    labelNational: PropTypes.string,

    /** The "regional" label. */
    labelRegional: PropTypes.string,

    /** The statements. */
    statements: PropTypes.array,

    /** The title. */
    title: PropTypes.string,

    /** The "onChange" callback. */
    onChange: PropTypes.func,
};

RangeFilter.defaultProps = {
    labelNational: "Nationale partijen",
    labelRegional: "Regionale partijen",
    statements: [],
    comparativeEntities: [],
    title: "Selecteer partijen",
    onChange: () => {},
};
