import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./form-actions.scss";

/**
 * FormActionss are horizontal components that can contain one or more form widgets.
 */
export const FormActions = ({ ...props }) => {
    const className = classNames({
        FormActions: true,
    });

    return (
        <div className={className} {...props}>
            {props.children}
        </div>
    );
};

FormActions.propTypes = {
    /** Child components/content.  */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.element, PropTypes.string])
        ),
    ]),
};

FormActions.defaultProps = {};
