import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./text.scss";

/**
 * Text is a simple component that can be used to style text.
 */
export const Text = ({
    align,
    color,
    fontsize,
    html,
    muted,
    size,
    strong,
    tag,
    ...props
}) => {
    const className = classNames({
        Text: true,
        [`Text--${align}`]: align,
        [`Text--${size}`]: size && !fontsize,
        [`Text--color-${color}`]: [
            "primary",
            "secondary",
            "tertiary",
            "primary-contrast",
        ].includes(String(color).toLowerCase()),
        "Text--muted": muted,
        "Text--strong": strong,
        "Text--wysiwyg": html,
    });

    const getStyle = () => {
        if (!color && !fontsize) {
            return null;
        }

        return {
            color: [
                "primary",
                "secondary",
                "tertiary",
                "primary-contrast",
            ].includes(String(color).toLowerCase())
                ? null
                : color,
            fontSize: fontsize ? `${fontsize / 16}rem` : null,
        };
    };

    const Tag = String(tag);

    if (html) {
        const { children, ...otherProps } = props; // eslint-disable-line

        return (
            <Tag
                className={className}
                dangerouslySetInnerHTML={{ __html: html }}
                style={getStyle()}
                {...otherProps}
            />
        );
    }
    return (
        <Tag className={className} style={getStyle()} {...props}>
            {props.children}
        </Tag>
    );
};

Text.propTypes = {
    /** Child components/content.  */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.array,
    ]),

    /** The alignment based on the text direction (LTR/RTL). */
    align: PropTypes.oneOf(["side", "center"]),

    /** Color. */
    color: PropTypes.oneOfType([
        PropTypes.oneOf([
            "primary",
            "secondary",
            "tertiary",
            "primary-contrast",
        ]), // Predefined.
        PropTypes.string, // CSS color code.
    ]),

    /** Font size in pixels. */
    fontsize: PropTypes.number,

    /** HTML to inject instead of children. */
    html: PropTypes.string,

    /** Whether to use muted text. */
    muted: PropTypes.bool,

    /** Font size preset. */
    size: PropTypes.oneOf(["normal", "small", "big"]),

    /** Whether to use strong text. */
    strong: PropTypes.bool,

    /** The tag to use. */
    tag: PropTypes.string,
};

Text.defaultProps = {
    align: "side",
    color: null,
    fontsize: null,
    html: null,
    muted: true,
    size: "normal",
    strong: false,
    tag: "p",
};
