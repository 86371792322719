import React, { useState } from "react";
import { useHistory, generatePath } from "react-router-dom";
import { Body } from "../../components/typography/Body";
import { isMobile } from "../../lib/responsive";
import { Form } from "../../components/form/Form";
import { EmailPopup } from "./EmailPopup";
import { ROUTES } from "../../routes";
import { BackgroundQuestionAnswerConsumer } from "../../data/background_question_answer";
import { deepGet } from "../../lib/util";

/**
 * Background questions page.
 * @return {JSX.Element}
 */
export const BackgroundQuestionsView = ({ ...props }) => {
    const [emailPopupActiveState, setEmailPopupActiveState] = useState(false);
    const history = useHistory();

    /**
     * Returns the background_question_page.
     * @returns {Object|null}
     */
    const getPage = () => {
        return props.toolState.background_question_page;
    };

    /**
     * Returns the correct title for the device.
     * @return {string}
     */
    const getTitle = () => {
        const page = getPage();

        if (!page) {
            return "";
        }

        if (isMobile()) {
            return page.title_mobile || page.title;
        }
        return page.title;
    };

    /**
     * Returns form inputs.
     * @param {Object[]} source
     * @return {Object[]}
     */
    const getFormInputs = (source = props.backgroundQuestionsState) =>
        source
            .filter((question) => question.show_in_separate_popup === false)
            .filter((question) => question.active);

    /**
     * Returns extra form inputs (show_in_separate_popup===true).
     * @param {Object[]} source
     * @return {Object[]}
     */
    const getExtraFormInputs = (source = props.backgroundQuestionsState) =>
        source
            .filter((question) => question.show_in_separate_popup === true)
            .filter((question) => question.active);

    /**
     * Returns form actions.
     * @return {Object[]}
     */
    const getFormActions = () => [
        {
            label: getPage().submit_button_text,
            onClick: onFormSubmit,
        },
    ];

    /**
     * Returns whether the email popup should be shown.
     * @return {boolean}
     */
    const shouldEmailPopupBeShown = () => {
        return Boolean(!props.hideEmailPopup && getPage().email_popup);
    };

    /**
     * Continues to the next route.
     * @param {string} [path]
     */
    const nextRoute = (path = null) => {
        // "nextRoute" callback passed as prop.
        if (props.nextRoute) {
            props.nextRoute(path);
            return;
        }

        if (path) {
            history.push(path);
            return;
        }

        if (getExtraFormInputs().length) {
            const _path =
                generatePath(ROUTES.EXTRA_BACKGROUND_QUESTIONS.path, {
                    language: props.match.params.language,
                }) || path;
            history.push(_path);
        } else {
            const _path =
                generatePath(ROUTES.STATEMENTS.path, {
                    language: props.match.params.language,
                }) || path;
            history.push(_path);
        }
    };

    /**
     * Form change handler.
     * @param {SyntheticEvent} e
     * @param {Object[]} formState
     */
    const onFormChange = (e, formState) => {
        props.setBackgroundQuestionsState(formState);
    };

    /**
     * Form submit hook.
     */
    const onFormSubmit = () => {
        // Get answers.
        const answeredBackgroundQuestions = props.backgroundQuestionsState
            .filter((question) => question.value)
            .map((question) => ({
                question: question.id,
                value: question.value,
            }));

        // Send answers to API.
        const backgroundAnswerConsumer = new BackgroundQuestionAnswerConsumer();
        backgroundAnswerConsumer.setSession(props.sessionState);
        backgroundAnswerConsumer
            .create(answeredBackgroundQuestions)
            .catch((error) => {
                if (error.statusCode === 403) {
                    let parsedMessage = JSON.parse(
                        error.statusText.response.data
                    );
                    if (parsedMessage.code === "session_expired") {
                        history.push(
                            generatePath(ROUTES.RESTART.path, {
                                language: props.match.params.language,
                            })
                        );
                    } else {
                        props.setErrorState(error);
                    }
                } else {
                    props.setErrorState(error);
                }
            })
            .finally(() => {
                if (shouldEmailPopupBeShown()) {
                    setEmailPopupActiveState(true); // Show email popup.
                    return;
                }
                nextRoute();
            });
    };

    /**
     * Renders the main content.
     * @return {*}
     */
    const renderView = () => (
        <>
            {shouldEmailPopupBeShown() && (
                <EmailPopup active={emailPopupActiveState} {...props} />
            )}

            <Body pad={props.pad} size={props.size}>
                <Form
                    align={props.align}
                    title={getTitle()}
                    inputs={getFormInputs()}
                    actions={getFormActions()}
                    emptyLabel={props.toolState.general_texts.pick_your_answer}
                    pad={props.pad}
                    strong={props.strong}
                    onChange={onFormChange}
                ></Form>
            </Body>
        </>
    );

    if (
        props.toolState &&
        !getFormInputs(deepGet(getPage(), "backgroundquestion_set", [])).length
    ) {
        setTimeout(nextRoute);
        return null;
    } else {
        return renderView();
    }
};
