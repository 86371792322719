import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./footer.scss";

/**
 * The footer represents a caption for the content of its parent component.
 */
export const Footer = (props) => {
    const className = classNames({
        Footer: true,
    });

    return (
        <footer className={className} {...props}>
            <div className="Footer__body">{props.children}</div>
        </footer>
    );
};

Footer.propTypes = {
    /** Child components/content.  */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.element, PropTypes.string])
        ),
    ]),
};
