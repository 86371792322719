import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { getId } from "../../lib/id";
import { Icon } from "../icon/Icon";
import "./input.scss";

/**
 * The input component is a wrapper to the HTML input element with custom styling and additional functionality. It
 * accepts most of the same properties as the HTML input.
 */
export const Input = ({
    id,
    type,
    name,
    value,
    pattern,
    validationMessage,
    placeholder,
    extraText,
    onChange,
}) => {
    const [idState, setIdState] = useState();
    const [errorTextState, setErrorTextState] = useState();
    useEffect(() => setIdState(getId(id)), [id]);

    const className = classNames({
        Input: true,
    });

    return (
        <div className={className}>
            {extraText && (
                <label className="Input__extra-text">
                    <Icon icon="lock" label={extraText} />
                    {extraText}
                </label>
            )}

            {errorTextState && (
                <label className="Input__error-text">
                    <Icon icon="explanation-circle" label={errorTextState} />
                    {errorTextState}
                </label>
            )}

            <input
                className="Input__input"
                id={idState}
                name={name ? name : null}
                value={value}
                pattern={pattern}
                type={type}
                placeholder={placeholder}
                onChange={onChange}
                onInput={() => setErrorTextState("")}
                onInvalid={() => setErrorTextState(validationMessage)}
            />
        </div>
    );
};

Input.propTypes = {
    /** Child components/content.  */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.element, PropTypes.string])
        ),
    ]),

    /** The id attribute of the input. */
    id: PropTypes.string,

    /** The name attribute of the input. */
    name: PropTypes.string,

    /** The value attribute of the input. */
    value: PropTypes.string,

    /** The pattern attribute of the input. */
    pattern: PropTypes.string,

    /** The message to show when form validation does not pass. */
    validationMessage: PropTypes.string,

    /** The type attribute of the input. */
    type: PropTypes.string,

    /** The placeholder attribute of the input. */
    placeholder: PropTypes.string,

    /** An additional label on top of the input. */
    extraText: PropTypes.string,

    /** The "onChange" callback. */
    onChange: PropTypes.func,
};

Input.defaultProps = {
    id: null,
    name: null,
    value: "",
    pattern: null,
    validationMessage: null,
    placeholder: null,
    extraText: null,
    type: "text",
    onChange: () => {},
};
