import { v4 as uuidv4 } from "uuid";

/**
 * Returns id if truthy, defaults to a random (uuid4) id.
 * @param {string} id
 * @return {string}
 */
export const getId = (id) => {
    if (id) {
        return id;
    }
    return `rand-id-${uuidv4()}`;
};
