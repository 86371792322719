import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Image } from "../image/Image";
import { Body } from "../typography/Body";
import { Caption } from "../typography/Caption";
import { Text } from "../typography/Text";
import { Title } from "../typography/Title";
import "./persona.scss";

/**
 * A persona shows details about a comparative entity.
 */
export const Persona = ({ comparativeEntity, ...props }) => {
    const className = classNames({
        Persona: true,
    });

    return (
        <div className={className} {...props}>
            <header className="Persona__head">
                <Title align="center">{comparativeEntity.name}</Title>
                <Image
                    alt={comparativeEntity.name}
                    src={comparativeEntity.image}
                    objectFit="contain"
                ></Image>
            </header>

            <section className="Persona__body">
                <Body pad={false} size="big">
                    <Caption>{comparativeEntity.name}</Caption>
                    <Text>{comparativeEntity.description}</Text>
                </Body>
            </section>
        </div>
    );
};

Persona.propTypes = {
    /** The comparative entity. */
    comparativeEntity: PropTypes.object,
};

Persona.defaultProps = {
    comparativeEntity: null,
};
