import { getStatementsSortedByAxes } from "./utils";

/**
 * Calculates the position of the user (with arbitrary axis/dimensions found in statements).
 * @param {{axis: string, inverse: boolean, use_in_calculation: boolean, value: *}[]} statements
 * @returns {{[index: string]: number|null}}
 */
export const calculateUserPosition = (statements) => {
    const statementsByAxis = getStatementsSortedByAxes(statements);

    return Object.fromEntries(
        Object.entries(statementsByAxis).map(([axis, statements]) => [
            axis,
            calculateCompassAxisPosition(axis, statements),
        ])
    );
};

/**
 * Calculates the position of a comparative entity (with arbitrary axis/dimensions found in statements).
 * @param {{fk_answers: {answer: {value: *}, statement: {axis: string, inverse: boolean, use_in_calculation: boolean, value: *}}}} comparativeEntity
 * @returns {{[index: string]: number|null}}
 */
export const calculateComparativeEntityPosition = (comparativeEntity) => {
    // Convert fk_answers format to statement format.
    const entityStatements = comparativeEntity.fk_answers
        .filter((fk_answer) => fk_answer.answer)
        .map((fk_answer) => {
            return {
                value: fk_answer.answer.value,
                ...fk_answer.statement,
            };
        });

    return calculateUserPosition(entityStatements);
};

/**
 * Calculate the position of the user on the compass.
 * NOTE: This should only be used for the compass!
 * @param {{axis: string, inverse: boolean, use_in_calculation: boolean, value: *}[]} statements
 * @returns {{x: (number|null), y: (number|null)}}
 */
export const calculateCompassPosition = (statements) => {
    return {
        x: calculateCompassAxisPosition("x", statements),
        y: calculateCompassAxisPosition("y", statements),
    };
};

/**
 * Calculate the position of the user on a compass axis.
 * @param {string} axis "x" or "y".
 * @param {{axis: string, inverse: boolean, use_in_calculation: boolean, value: *}[]} statements
 * @returns {number|null}
 */
export const calculateCompassAxisPosition = (axis, statements) => {
    const values = [];

    statements
        .filter((statement) => statement.use_in_calculation !== false)
        .forEach((statement) => {
            const answer = parseInt(statement.value);
            const statementAxis = statement.axis;
            const inverse = statement.inverse;

            if (isNaN(answer)) {
                return;
            }

            if (
                statementAxis.toLowerCase() === axis.toLowerCase() &&
                answer !== 99
            ) {
                const value = inverse ? answer * -1 : answer;
                values.push(value);
            }
        });

    if (!values.length) {
        return null;
    }

    const average = (arr) =>
        arr.reduce((acc, val) => acc + val, 0) / arr.length;
    return average(values.filter((value) => value !== null));
};
