import React from "react";
import { Col } from "../../components/layout/Col";
import { Wysiwyg } from "../../components/typography/Wysiwyg";
import { deepGet } from "../../lib/util";
import { ComparativeEntityInformation } from "../../components/comparative-entity-information/ComparativeEntityInformation";

/**
 * Renders comparative entity info.
 * @return {JSX.Element}
 */
export const renderInfo = ({ activeComparativeEntityState, toolState }) => {
    const renderBody = () => {
        if (activeComparativeEntityState) {
            return (
                <ComparativeEntityInformation
                    comparativeEntity={activeComparativeEntityState}
                    labelCandidates={deepGet(
                        toolState,
                        "general_texts.canidate_list_label"
                    )}
                    labelCandidatesButton={deepGet(
                        toolState,
                        "general_texts.ce_canidate_list_button"
                    )}
                    labelClose={deepGet(toolState, "general_texts.close")}
                    labelLeader={deepGet(
                        toolState,
                        "general_texts.leader_label"
                    )}
                    labelProgramButton={deepGet(
                        toolState,
                        "general_texts.ce_program_button"
                    )}
                />
            );
        }
        return (
            <Wysiwyg pad="extra" size="big">
                {deepGet(toolState, "general_texts.election_text")}
            </Wysiwyg>
        );
    };

    return (
        <Col pad={false} padright={true}>
            {renderBody()}
        </Col>
    );
};
