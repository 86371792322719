import ReactGA from "react-ga";

export default function start_google_analytics_universal(
    toolState,
    gaInitialized,
    setGaInitialized,
    listen
) {
    if (!gaInitialized) {
        ReactGA.initialize(toolState.analytics_site_id);
        ReactGA.set({ anonymizeIp: true });
        setGaInitialized(true);
    }
    ReactGA.ga(
        "set",
        "page",
        window.location.pathname + window.location.search
    );
    ReactGA.ga("send", "pageview");
    const unlisten = listen((location) => {
        ReactGA.ga("set", "page", location.pathname + location.search);
        ReactGA.ga("send", "pageview");
    });

    return unlisten;
}
