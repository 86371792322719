import React, { useRef } from "react";
import { generatePath, useHistory } from "react-router-dom";
import { Row } from "../../components/layout/Row";
import { Col } from "../../components/layout/Col";
import { Panel } from "../../components/panel/Panel";
import { Statement } from "../../components/statement/Statement";
import { PtvHeader } from "../../components/ptv/PtvHeader";
import { Ptv } from "../../components/ptv/Ptv";
import { Button } from "../../components/button/Button";
import { ROUTES } from "../../routes";
import { PtvAnswerConsumer } from "../../data/ptv_answer";
import { getParent } from "../../lib/dom";
import { deepGet } from "../../lib/util";

/**
 * PTV questions page.
 * @return {JSX.Element}
 */
export const PtvView = ({ ...props }) => {
    const history = useHistory();
    const wrapper = useRef();

    /**
     * Returns the correct title for the device.
     * @return {string|null}
     */
    const getTitle = () => {
        const page = getPage();

        if (!page) {
            return null;
        }

        return page.question || page.name;
    };

    /**
     * Returns the current PTV page object.
     * @returns {Object}
     */
    const getPage = () => {
        const pageIndex = getPageIndex();
        return props.toolState.ptv_question_pages[pageIndex];
    };

    /**
     * Gets and converts the page number to an index.
     * @returns {number}
     */
    const getPageIndex = () => {
        return getPageNumber() - 1;
    };

    /**
     * Gets the page number.
     * @returns {number}
     */
    const getPageNumber = () => {
        return parseInt(props.match.params.page);
    };

    /**
     * Returns form inputs.
     * @return {Object[]}
     */
    const getFormInputs = () =>
        props.ptvState.filter(
            (question) => question.pageIndex === props.match.params.page - 1
        );

    /**
     * Continues to the next route.
     * @param {string} [path]
     */
    const nextRoute = (
        path = generatePath(ROUTES.RESULTS.path, {
            language: props.match.params.language,
        })
    ) => {
        // "nextRoute" callback passed as prop.
        if (props.nextRoute) {
            props.nextRoute(path);
            return;
        }

        if (
            getPageNumber() <
            deepGet(props, "toolState.ptv_question_pages.length", 0)
        ) {
            // Scroll to top.
            if (wrapper.current) {
                const col = getParent(wrapper.current, ".Col");

                if (col) {
                    col.scrollTo(0, 0);
                }
            }

            const _path = generatePath(ROUTES.PTV.path, {
                language: props.match.params.language,
                page: String(getPageNumber() + 1),
            });
            history.push(_path);
        } else {
            history.push(path);
        }
    };

    /**
     * Ptv change handler.
     * @param {SyntheticEvent} e
     * @param {Object[]} question
     */
    const onPtvChange = (e, question) => {
        props.setPtvState([question]);
    };

    /**
     * Form submit hook.
     */
    const onFormSubmit = () => {
        // Get answers.
        const answeredPtvQuestions = props.ptvState
            .filter((question) => question.value !== undefined)
            .map((question) => ({
                question: question.id,
                value: question.value,
            }));

        // Send answers to API.
        const ptvAnswerConsumer = new PtvAnswerConsumer();
        ptvAnswerConsumer.setSession(props.sessionState);
        ptvAnswerConsumer
            .create(answeredPtvQuestions)
            .then(() => {
                nextRoute(); // Go to the next route.
            })
            .catch((error) => {
                if (error.statusCode === 403) {
                    let parsedMessage = JSON.parse(
                        error.statusText.response.data
                    );
                    if (parsedMessage.code === "session_expired") {
                        history.push(
                            generatePath(ROUTES.RESTART.path, {
                                language: props.match.params.language,
                            })
                        );
                    } else {
                        props.setErrorState(error);
                        nextRoute();
                    }
                } else {
                    props.setErrorState(error);
                    nextRoute();
                }
            });
    };

    /**
     * Renders the main content.
     * @return {JSX.Element|null}
     */
    const renderView = () => {
        const page = getPage();

        if (!page) {
            return null;
        }

        return (
            <>
                <Row top={2010}>
                    <Col fill={true} valign="top">
                        <Panel size="full" style="transparent">
                            <Statement align="center" size="medium">
                                {getTitle()}
                            </Statement>
                        </Panel>
                    </Col>
                </Row>

                <Row top={2000} overflow={true}>
                    <Col overflow={true}>
                        <PtvHeader
                            labelLess={page.not_very_probable_text}
                            labelMore={page.very_probable_text}
                        />
                    </Col>
                </Row>

                <Row grow={true}>
                    <Col valign="top">
                        <div ref={wrapper} style={{ width: "100%" }}>
                            {renderPtvs()}
                        </div>

                        <Panel style="transparent">
                            <Button
                                size="normal"
                                minwidth={true}
                                onClick={onFormSubmit}
                            >
                                {page.button_text}
                            </Button>
                        </Panel>
                    </Col>
                </Row>
            </>
        );
    };

    /**
     * Renders the Ptv's.
     * @returns {JSX.Element[]}
     */
    const renderPtvs = () =>
        getFormInputs().map((question, index) => (
            <Ptv
                key={index}
                index={index}
                question={question}
                avatarprops={{ src: question.image }}
                ariaLabel={`${getTitle()}: ${question.name}`} // FIXME: Improve this.
                labelDontKnow={getPage().dont_know_text}
                labelNoOpinion={getPage().no_opinion_text}
                onChange={onPtvChange}
            />
        ));

    if (props.toolState && !props.toolState.ptv_question_pages.length) {
        setTimeout(nextRoute);
    }

    return renderView();
};
