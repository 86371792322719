import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./button.scss";

/**
 * Buttons provide a clickable element, which can be used in forms, or anywhere that needs simple, standard button
 * functionality. They may display text, icons, or both. Buttons can be styled with several attributes to look a
 * specific way.
 */
export const ButtonLink = ({
    href,
    active,
    style,
    direction,
    minwidth,
    pad,
    size,
    ...props
}) => {
    const className = classNames({
        Button: true,
        "Button--active": active,
        "Button--min-width": minwidth,
        "Button--pad": pad,
        [`Button--${size}`]: size,
        [`Button--${direction}`]: direction,
        [`Button--${typeof style === "object" ? "styled" : style}`]: style,
    });

    const getStyle = () => {
        if (typeof style !== "object") {
            return null;
        }
        return style;
    };

    return (
        <a className={className} href={href} style={getStyle()} {...props}>
            {props.children}
        </a>
    );
};

ButtonLink.propTypes = {
    /** Child components/content.  */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.array,
    ]),

    /** The href attribute of the link. */
    href: PropTypes.string,

    /** The active state of the button. */
    active: PropTypes.bool,

    /** The style of the button, can be "primary", "secondary", "open" or a React style object. */
    style: PropTypes.oneOfType([
        PropTypes.oneOf([
            "primary",
            "primary-contrast",
            "secondary",
            "open",
            "transparent",
        ]),
        PropTypes.object,
    ]),

    /** The content direction. */
    direction: PropTypes.oneOf(["horizontal", "vertical"]),

    /** Whether the button should have a minimum width. */
    minwidth: PropTypes.bool,

    /** Whether the button should have padding. */
    pad: PropTypes.bool,

    /** Size preset. */
    size: PropTypes.oneOf(["big", "normal"]),
};

ButtonLink.defaultProps = {
    active: false,
    style: "primary",
    direction: "horizontal",
    minwidth: false,
    pad: true,
    size: "big",
};
