import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./lead.scss";

/**
 * A lead paragraph is the opening paragraph of an article.
 */
export const Lead = ({ align, color, fontsize, ...props }) => {
    const className = classNames({
        Lead: true,
        [`Lead--${align}`]: align,
    });

    const getStyle = () => {
        if (!color && !fontsize) {
            return null;
        }

        return {
            color,
            fontSize: fontsize ? `${fontsize / 16}rem` : null,
        };
    };

    return (
        <h4 className={className} style={getStyle()} {...props}>
            {props.children}
        </h4>
    );
};

Lead.propTypes = {
    /** Child components/content.  */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.element, PropTypes.string])
        ),
    ]),

    /** The alignment based on the text direction (LTR/RTL). */
    align: PropTypes.oneOf(["side", "center"]),

    /** Color. */
    color: PropTypes.string,

    /** Font size in pixels. */
    fontsize: PropTypes.number,
};

Lead.defaultProps = {
    align: "side",
    color: null,
    fontsize: null,
};
