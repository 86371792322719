/**
 * @const {number} timeout value for localstorage data in seconds.
 * If localstorage object is not updated within this value data gets removed.
 * */
export const LOCALSTORAGE_TIMEOUT = 900; // 900 seconds === 15 minutes.

/** @const {string} The name of the localstorage item for the "last updated" timestamp. */
export const LOCALSTORAGE_TIMEOUT_KEY = "localstorage_timestamp";

/** @const {string} The name of the localstorage item optionally containing whether cookie consent is given. */
export const LOCALSTORAGE_COOKIE_CONSENT_KEY = "cookie_consent";

/** @const {string} The name of the localstorage item for the session object. */
export const LOCALSTORAGE_SESSION_KEY = "session";

/** @const {string} The name of the localstorage item for the tool object. */
export const LOCALSTORAGE_TOOL_KEY = "tool";
