import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useAnimation } from "../../lib/animation";
import "./compass-user.scss";

/**
 * CompassUser shows the users position in the progressive/conservative left wing/right wing quadrant.
 */
export const CompassUser = ({
    colorize,
    label,
    src,
    title,
    x,
    y,
    ...props
}) => {
    const [href, setHref] = useState(src);
    const renderedX = useAnimation(x);
    const renderedY = useAnimation(y);

    // Inline image, allowing conversion to PNG on user's demand.
    useEffect(() => {
        let isUnounted = false;

        const image = new Image();
        image.crossOrigin = "Anonymous";
        image.onload = () => {
            if (isUnounted) {
                return;
            }

            // Create canvas
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            // Set width and height
            canvas.width = image.width;
            canvas.height = image.height;

            ctx.drawImage(image, 0, 0);
            const dataURL = canvas.toDataURL();
            setHref(dataURL);
        };

        image.src = src;

        return () => (isUnounted = true);
    }, [src]);

    const getX = () => `${renderedX * 25 + 25}%`;
    const getY = () => `${-renderedY * 25 + 25}%`;

    const className = classNames({
        CompassUser: true,
    });

    return (
        <svg
            className={className}
            width="50%"
            height="50%"
            x={getX()}
            y={getY()}
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            {...props}
        >
            <title>{title || label || "User position"}</title>

            {colorize && (
                <circle
                    className="CompassUser__fill"
                    cx="50%"
                    cy="50%"
                    r="12"
                    href={href}
                />
            )}

            {!colorize && (
                <image
                    x="50%"
                    y="50%"
                    width="40"
                    height="40"
                    transform="translate(-20 -40)"
                    href={href}
                ></image>
            )}

            {label && (
                <text
                    fontFamily="Lora, Serif"
                    fontSize="14"
                    fontWeight="bold"
                    dominantBaseline="central"
                    textAnchor={x > 0 ? "end" : null}
                    transform={`translate(${x > 0 ? -20 : 20} ${
                        colorize ? 0 : -20
                    })`}
                    x="50%"
                    y="50%"
                >
                    {label}
                </text>
            )}
        </svg>
    );
};

CompassUser.propTypes = {
    /** Whether to replace image with solid color. */
    colorize: PropTypes.bool,

    /** Label to who next to the user. */
    label: PropTypes.string,

    /** The image source. */
    src: PropTypes.string,

    /** The title. */
    title: PropTypes.string,

    /** X position. */
    x: PropTypes.number,

    /** Y position. */
    y: PropTypes.number,
};

CompassUser.defaultProps = {
    colorize: false,
    label: "",
    src: "https://ucarecdn.com/57dd3dbe-e436-44d4-b5ef-9837598e0461/-/crop/303x302/122,338/-/resize/32x32/",
    title: "",
    x: null,
    y: null,
};
