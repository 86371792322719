import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Avatar } from "../avatar/Avatar";
import { RadioGroup } from "../form/RadioGroup";
import { Radio } from "../form/Radio";
import { Panel } from "../panel/Panel";
import { Slider } from "../form/Slider";
import { slugify } from "../../lib/format";
import "./ptv.scss";

/**
 * Propensities to vote.
 */
export const Ptv = ({
    ariaLabel,
    index,
    question,
    label,
    name,
    avatarprops,
    labelDontKnow,
    labelNoOpinion,
    onChange,
    ...props
}) => {
    const [value, _setValue] = useState();
    useEffect(() => {
        if (question) {
            _setValue(question.value);
        }
    }, [question]);

    const className = classNames({
        Ptv: true,
    });

    /**
     * Updates value and calls onChange.
     * @param {*} newValue
     * @param {SyntheticEvent} [e=null]
     */
    const setValue = (newValue, e = null) => {
        const _newValue = parseInt(newValue);
        _setValue(_newValue);

        if (question) {
            question.value = _newValue;
        }
        onChange(e, question || null, _newValue);
    };

    //
    // Merge avatar configuration.
    //
    const _avatarprops = avatarprops;
    if (question && question.image) {
        _avatarprops.src = question.image;
    }
    Object.assign(_avatarprops, { question });
    const questionName = question ? question.question : "";

    return (
        <div className={className} {...props}>
            <Panel pad={true} size="bigger">
                <Avatar {..._avatarprops}>{label || questionName}</Avatar>

                <Slider
                    value={
                        typeof value === "number" && value > -1 && value < 11
                            ? value
                            : ""
                    }
                    min={0}
                    max={10}
                    name={name || slugify(questionName)}
                    onChange={(e, value) => setValue(value)}
                    tabIndex={(index + 1) * 100}
                    ariaLabel={ariaLabel}
                />

                <RadioGroup>
                    {labelDontKnow && (
                        <Radio
                            checked={String(value) === "11"}
                            labelposition="outside"
                            name={name || slugify(questionName)}
                            value="11"
                            onChange={(e) => setValue("11", e)}
                            tabIndex={(index + 1) * 100 + 11}
                            aria-label={
                                ariaLabel
                                    ? `${ariaLabel} - ${labelDontKnow}`
                                    : null
                            }
                        >
                            {labelDontKnow}
                        </Radio>
                    )}

                    {labelNoOpinion && (
                        <Radio
                            checked={String(value) === "12"}
                            labelposition="outside"
                            name={name || slugify(questionName)}
                            value="12"
                            onChange={(e) => setValue("12", e)}
                            tabIndex={(index + 1) * 100 + 11}
                            aria-label={
                                ariaLabel
                                    ? `${ariaLabel} - ${labelNoOpinion}`
                                    : null
                            }
                        >
                            {labelNoOpinion}
                        </Radio>
                    )}
                </RadioGroup>
            </Panel>

            <input type="hidden" value={value || ""} />
        </div>
    );
};

Ptv.propTypes = {
    /** aria-label attribute. */
    ariaLabel: PropTypes.string,

    /** Index (for tabindex values). */
    index: PropTypes.number,

    /** Child components/content.  */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.element, PropTypes.string])
        ),
    ]),

    /** The ptv question. */
    question: PropTypes.object,

    /** The label of the PTV (can be derived from question). */
    label: PropTypes.string,

    /** The name attribute of the button (can be derived from question). */
    name: PropTypes.string,

    /** Props to pass to the Avatar (can be derived from question). */
    avatarprops: PropTypes.shape(Avatar.propTypes),

    /** "Don't know" label. */
    labelDontKnow: PropTypes.string,

    /** "No optinion" label. */
    labelNoOpinion: PropTypes.string,

    /** "onChange" callback. */
    onChange: PropTypes.func,
};

Ptv.defaultProps = {
    ariaLabel: null,
    index: 0,
    label: "",
    name: "",
    avatarprops: {},
    labelDontKnow: "",
    labelNoOpinion: "",
    onChange: () => {},
};
