import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import start_google_analytics_4 from "./ga4";
import start_google_analytics_universal from "./ga";

const usePageTracking = (toolState, gaInitialized, setGaInitialized) => {
    const { listen } = useHistory();

    useEffect(() => {
        if (toolState.google_analytics_4) {
            start_google_analytics_4(
                toolState,
                gaInitialized,
                setGaInitialized,
                listen
            );
        }

        if (toolState.analytics_site_id) {
            start_google_analytics_universal(
                toolState,
                gaInitialized,
                setGaInitialized,
                listen
            );
        }
    }, [gaInitialized, setGaInitialized, listen, toolState]);
};

export default usePageTracking;
