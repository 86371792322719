import React, { useState } from "react";
import { generatePath, useHistory } from "react-router-dom";
import { StatementCard } from "../../components/statement-card/StatementCard";
import { getParent } from "../../lib/dom";
import { ROUTES } from "../../routes";
import { StatementAnswerConsumer } from "../../data/statement_answer";
import { deepGet } from "../../lib/util";

/**
 * Statements page.
 * @return {JSX.Element}
 */
export const StatementsView = ({ ...props }) => {
    const history = useHistory();
    const [
        answeredPercentageTriggersState,
        setAnsweredPercentageTriggersState,
    ] = useState([50, 75, 90]);

    /**
     * Form change handler.
     * @param {SyntheticEvent} e
     * @param {Object[]} statementsState
     */
    const onStatementCardChange = (e, statementsState) => {
        // Check if button is clicked.
        const target = e.target;
        props.setStatementsState(statementsState);
        conditionallySubmitStatementAnswers(statementsState);

        const cardSlide = getParent(target, ".Card__slide");

        // Last slide.
        if (cardSlide && cardSlide.matches(":last-of-type")) {
            nextRoute(); // Go to the next route.
        }
    };

    /**
     * Submits statement answers based on certain conditions.
     * This filters unwanted API calls.
     * @param {Object[]} statements
     * @return Promise
     */
    const conditionallySubmitStatementAnswers = (statements) => {
        // Get answers.
        const answeredStatements = statements
            .filter((question) => question.value)
            .map((question) => ({
                statement: question.id,
                answer: getAnswerId(question),
            }));

        const percentageComplete =
            (answeredStatements.length / props.statementsState.length) * 100;
        const statementsComplete = percentageComplete === 100; // If every statement is answered completed, always submit.
        const nextTrigger = answeredPercentageTriggersState[0];
        const nextTriggerReached = percentageComplete >= nextTrigger;

        // Submit to API.
        if (statementsComplete || nextTriggerReached) {
            setAnsweredPercentageTriggersState(
                answeredPercentageTriggersState.slice(1)
            );

            const statementAnswerConsumer = new StatementAnswerConsumer();
            statementAnswerConsumer.setSession(props.sessionState);
            return statementAnswerConsumer
                .create(answeredStatements)
                .catch((error) => {
                    if (error.statusCode === 403) {
                        let parsedMessage = JSON.parse(
                            error.statusText.response.data
                        );
                        if (parsedMessage.code === "session_expired") {
                            history.push(
                                generatePath(ROUTES.RESTART.path, {
                                    language: props.match.params.language,
                                })
                            );
                        } else {
                            props.setErrorState(error);
                        }
                    } else {
                        props.setErrorState(error);
                    }
                });
        }

        // Do not update for now.
        return Promise.resolve();
    };

    /**
     * Return the given answer's id
     * @return {number|null}
     */
    const getAnswerId = (statement) => {
        const answer = Object.values(statement.answer_type).find(
            (answer) => parseInt(answer.value) === parseInt(statement.value)
        );
        return answer ? answer.id : null;
    };

    /**
     * Continues to the next route.
     * @param {string} [path]
     */
    const nextRoute = (
        path = generatePath(ROUTES.PTV.path, {
            language: props.match.params.language,
            page: 1,
        })
    ) => {
        // "nextRoute" callback passed as prop.
        if (props.nextRoute) {
            props.nextRoute(path);
            return;
        }
        history.push(path);
    };

    /**
     * Renders the main content.
     * @return {*}
     */
    const renderView = () => (
        <StatementCard
            buttonStyle={props.buttonStyle}
            statements={props.statementsState}
            labelStatement={deepGet(
                props,
                "toolState.statement_page.statement"
            )}
            labelAnswer={deepGet(props, "toolState.statement_page.your_answer")}
            labelBack={deepGet(
                props,
                "toolState.general_texts.back_button_text"
            )}
            labelOpen={deepGet(
                props,
                "toolState.statement_page.view_your_answers"
            )}
            labelNext={deepGet(props, "toolState.statement_page.next")}
            labelPrevious={deepGet(props, "toolState.statement_page.previous")}
            labelClose={deepGet(
                props,
                "toolState.statement_page.continue_answering"
            )}
            margin={props.margin}
            noOpinionOnNewLine={props.noOpinionOnNewLine}
            pad={props.pad}
            progressIndicatorPosition={props.progressIndicatorPosition}
            segmentSize={props.segmentSize}
            size={props.size}
            showCounter={props.showCounter}
            statementSize={props.statementSize}
            stretch={props.stretch}
            strong={props.strong}
            transparent={props.transparent}
            wrap={props.wrap}
            onChange={onStatementCardChange}
        />
    );

    if (!deepGet(props, "toolState.statement_page.statements.length")) {
        setTimeout(nextRoute);
    }

    return renderView();
};
