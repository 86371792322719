import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./sub-sub-caption.scss";

/**
 * SubSubCaption is a simple component that can be used to style smaller titles.
 */
export const SubSubCaption = ({
    align,
    backgroundColor,
    color,
    fontsize,
    ...props
}) => {
    const className = classNames({
        SubSubCaption: true,
        [`SubSubCaption--${align}`]: align,
        [`SubSubCaption--${color}`]: color && color.charAt(0) !== "#",
    });

    const getStyle = () => {
        return {
            backgroundColor: backgroundColor,
            color: color && color.charAt(0) === "#" ? color : null,
            fontSize: fontsize ? `${fontsize / 16}rem` : null,
        };
    };

    return (
        <h6 className={className} style={getStyle()} {...props}>
            {props.children}
        </h6>
    );
};

SubSubCaption.propTypes = {
    /** Child components/content.  */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.element, PropTypes.string])
        ),
    ]),

    /** The alignment based on the text direction (LTR/RTL), only use left and right when locale is not applicable. */
    align: PropTypes.oneOf(["side", "center", "left", "right"]),

    /** Background color. */
    backgroundColor: PropTypes.string,

    /** Color. */
    color: PropTypes.string,

    /** Font size in pixels. */
    fontsize: PropTypes.number,
};

SubSubCaption.defaultProps = {
    align: "side",
    backgroundColor: null,
    color: null,
    fontsize: null,
};
