import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Image } from "../image/Image";
import { Link } from "../typography/Link";
import "./logo.scss";

/**
 * The organisation's logo with a configurable link.
 */
export const Logo = ({
    alt,
    href,
    target,
    src,
    width,
    height,
    onClick,
    ...props
}) => {
    const className = classNames({
        Logo: true,
    });

    return (
        <div className={className} {...props}>
            <Link href={href} target={target} tabIndex="10" onClick={onClick}>
                <Image
                    alt={alt}
                    src={src}
                    width={width}
                    height={height}
                    objectFit="contain"
                />
            </Link>
        </div>
    );
};

Logo.propTypes = {
    /** The alt attribute of the image. */
    alt: PropTypes.string,

    /** The href attribute of the link. */
    href: PropTypes.string,

    /** The target attribute of the link. */
    target: PropTypes.string,

    /** The src attribute of the desktop and fallback image. */
    src: PropTypes.string,

    /** The image width (in pixels). */
    width: PropTypes.number,

    /** The image height (in pixels). */
    height: PropTypes.number,

    /** onClick callback. */
    onClick: PropTypes.func,
};

Logo.defaultProps = {
    alt: "Kieskompas Logo",
    href: null,
    src: "https://www.kieskompas.nl/static/img/kieskompas.jpg",
    target: null,
    width: null,
    height: null,
    onClick: null,
};
