import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { isMobile } from "../../lib/responsive";
import { Button } from "../button/Button";
import { Icon } from "../icon/Icon";
import { IconButton } from "../icon-button/IconButton";
import "./progress-indicator.scss";

/**
 * ProgressIndicators shows a list of "steps" and their completed state. On mobile, "keypad" like interface is shown.
 */
export const ProgressIndicator = ({
    buttonStyle,
    current,
    max,
    labelOpen,
    labelClose,
    labelPrevious,
    labelNext,
    getCompleted,
    getHref,
    wrap,
    onStepMouseEnter,
    onStepMouseLeave,
    onStepClick,
    ...props
}) => {
    const min = 1;
    const [isActive, setIsActive] = useState(false);
    const className = classNames({
        ProgressIndicator: true,
        "ProgressIndicator--active": isActive,
        "ProgressIndicator--wrap": wrap,
        "ProgressIndicator--nowrap": !wrap,
        [`ProgressIndicator--${buttonStyle}`]: buttonStyle,
    });

    const onTouch = (e, type) => {
        const targetTouch = e.changedTouches[0];
        const x = targetTouch.clientX;
        const y = targetTouch.clientY;
        const realTarget = document.elementFromPoint(x, y);
        const step =
            realTarget && realTarget.hash
                ? parseInt(realTarget.hash.replace("#", ""))
                : null;

        if (step === null) {
            return;
        }

        if (type === "start" || type === "move") {
            onStepClick(e, step);
            onStepMouseEnter(e, step);
        }

        if (type === "end") {
            onStepMouseLeave(e, step);
        }
    };

    /**
     * Returns the list item style.
     * @return {{transform: string}|null}
     */
    const getListItemStyle = () => {
        if (wrap || isMobile()) {
            return null;
        }

        const percent = (current - 1) * -50;

        return {
            transform: `translateX(${percent}px)`,
        };
    };

    const renderSteps = () => {
        return new Array(max - min + 1).fill().map((value, index) => {
            const index1 = index + min;
            const active = index1 === current;
            const completed = getCompleted(index1);
            const tabIndexOffset =
                index1 >= current
                    ? index1 - current + min
                    : max + (index1 - current) + min;
            return (
                <li
                    key={index1}
                    className={classNames({
                        "ProgressIndicator__list-item": true,
                        "ProgressIndicator__list-item--active": active,
                        "ProgressIndicator__list-item--completed": completed,
                    })}
                    style={getListItemStyle()}
                    onMouseEnter={(e) => onStepMouseEnter(e, index1)}
                    onMouseLeave={(e) => onStepMouseLeave(e, index1)}
                    onClick={(e) => onStepClick(e, index1)}
                    aria-current={active ? "step" : null}
                >
                    <a
                        className="ProgressIndicator__link"
                        href={getHref(index1)}
                        tabIndex={tabIndexOffset + 900}
                        onContextMenu={(e) => e.preventDefault()}
                    >
                        {index1}
                    </a>
                </li>
            );
        });
    };

    return (
        <nav className={className} {...props}>
            <IconButton
                alt={labelPrevious}
                icon="chevron-left"
                pad={false}
                onClick={(e) => onStepClick(e, Math.max(current - 1, min))}
                disabled={current <= min}
                tabIndex={900}
            />

            <ul
                className="ProgressIndicator__list"
                onTouchStart={(e) => onTouch(e, "start")}
                onTouchMove={(e) => onTouch(e, "move")}
                onTouchEnd={(e) => onTouch(e, "end")}
            >
                {renderSteps()}
            </ul>

            <IconButton
                alt={labelNext}
                icon="chevron-right"
                disabled={current >= max}
                pad={false}
                onClick={(e) => onStepClick(e, Math.min(current + 1, max))}
                tabIndex={max - min + 902}
            />

            <div className="ProgressIndicator__actions">
                <Button
                    style="dimmed"
                    disabled={current <= min}
                    pad={false}
                    onClick={(e) => onStepClick(e, Math.max(current - 1, min))}
                >
                    <Icon icon="chevron-left" />
                </Button>

                <Button
                    pad={false}
                    style={isActive ? "primary" : "dimmed"}
                    styleOnHover={true}
                    onClick={(e) => {
                        onStepMouseLeave(e, current);
                        setIsActive(!isActive);
                    }}
                >
                    {isActive ? labelClose : labelOpen}
                </Button>
            </div>
        </nav>
    );
};

ProgressIndicator.propTypes = {
    /** The style of ProgressIndicator buttons. */
    buttonStyle: PropTypes.oneOf(["circle", "square"]),

    /** Current step number. */
    current: PropTypes.number,

    /** Maximum step number. */
    max: PropTypes.number,

    /** Label shown on open button (mobile only). */
    labelOpen: PropTypes.string,

    /** Label shown on close button (mobile only). */
    labelClose: PropTypes.string,

    /** Alt label previous button. */
    labelPrevious: PropTypes.string,

    /** Alt label next button. */
    labelNext: PropTypes.string,

    /** Function called for every step returning the whether the step is completed. */
    getCompleted: PropTypes.func,

    /** Function called for every step returning the "href" attribute. */
    getHref: PropTypes.func,

    /** Whether to wrap step. */
    wrap: PropTypes.bool,

    /** Function called when mouse enters step. */
    onStepMouseEnter: PropTypes.func,

    /** Function called when mouse leaves step. */
    onStepMouseLeave: PropTypes.func,

    /** Function called when step is clicked. */
    onStepClick: PropTypes.func,
};

ProgressIndicator.defaultProps = {
    buttonStyle: "circle",
    current: 1,
    max: 26,
    getCompleted: () => {},
    getHref: (stepNumber) => `#step-${stepNumber}`,
    labelOpen: "Bekijk uw antwoorden",
    labelClose: "Ga door",
    labelPrevious: "",
    labelNext: "",
    wrap: true,
    onStepMouseEnter: (e, step) => {
        // eslint-disable-line
    },
    onStepMouseLeave: (e, step) => {
        // eslint-disable-line
    },
    onStepClick: (e, step) => {
        // eslint-disable-line
    },
};
