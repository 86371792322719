import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Icon } from "../icon/Icon";
import "./chip.scss";

/**
 * A chip is used to navigate to a specified page.
 */
export const Chip = ({ href, external, ...props }) => {
    const Tag = href ? "a" : "span";
    const _external = external || (href && String(href).startsWith("http"));

    const className = classNames({
        Chip: true,
        "Chip--external": _external,
    });

    return (
        <Tag
            className={className}
            href={href ? href : null}
            target={_external ? "_blank" : null}
            {...props}
        >
            {props.children}
            {_external && <Icon icon="external-link" />}
        </Tag>
    );
};

Chip.propTypes = {
    /** Child components/content.  */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.element, PropTypes.string])
        ),
    ]),

    /** The href attribute of the chip. */
    href: PropTypes.string.isRequired,

    /** Whether the link is external. */
    external: PropTypes.bool,
};

Chip.defaultProps = {
    href: "",
    external: false,
};
