import { React, useEffect } from "react";
import PropTypes from "prop-types";
import { generatePath, useHistory } from "react-router-dom";
import { Text } from "../../components/typography/Text";
import { ROUTES } from "../../routes";
import { getLanguageFromUrl } from "../../lib/i18n";

/**
 * Redirect view.
 */
export const RedirectView = ({ restart, url, ...props }) => {
    const history = useHistory();
    const _url =
        url ||
        generatePath(ROUTES.LANDING.path, {
            language: props.languageState || getLanguageFromUrl(),
        });

    useEffect(() => {
        if (restart) {
            try {
                props.clearState();
            } catch (e) {
                console.warn(e);
            }
        }
    }, [restart, props]);

    useEffect(() => {
        history.push(_url);
    }, [props, _url, history]);
    return <Text>Redirecting to: {JSON.stringify(_url)}</Text>;
};

RedirectView.propTypes = {
    /** Whether to clear the state and restart on redirect. */
    restart: PropTypes.bool,

    /** The URL to redirect to. */
    url: PropTypes.string,
};

RedirectView.defaultProps = {
    restart: false,
    url: "",
};
