import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SubTitle } from "../typography/SubTitle";
import "./legend.scss";

/**
 * The legend represents a caption for the content of its parent component.
 */
export const Legend = ({ ...props }) => {
    const className = classNames({
        Legend: true,
    });

    return (
        <div className={className} {...props}>
            <div className="Legend__body">
                <SubTitle strong={true}>{props.children}</SubTitle>
            </div>
        </div>
    );
};

Legend.propTypes = {
    /** Child components/content.  */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.element, PropTypes.string])
        ),
    ]),

    /** Styling configuration. */
    generalStyle: PropTypes.shape({
        logo: PropTypes.string,
        primary_color: PropTypes.string,
        primary_contrast_color: PropTypes.string,
        secondary_color: PropTypes.string,
        secondary_contrast_color: PropTypes.string,
        tertiary_color: PropTypes.string,
        tertiary_contrast_color: PropTypes.string,
        sponsor_images: PropTypes.array,
    }),
};

Legend.defaultProps = {
    generalStyle: null,
};
