import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./header-button.scss";

/**
 * HeaderButton is a special button for use as child of a Header component.
 */
export const HeaderButton = ({ href, active, ...props }) => {
    const className = classNames({
        HeaderButton: true,
        "HeaderButton--active": active,
    });

    return (
        <a className={className} href={href || "#"} {...props}>
            <span className="HeaderButton__label">{props.children}</span>
        </a>
    );
};

HeaderButton.propTypes = {
    /** The href attribute of the link. */
    href: PropTypes.string,

    /** The active state of the button. */
    active: PropTypes.bool,
};

HeaderButton.defaultProps = {
    href: null,
    active: false,
};
