import { CrudConsumer, CrudConsumerObject } from "consumerjs";
import { API_KEY, getApiBaseEndpoint } from "./api";

/**
 * ComparativeEntity.
 */
class ComparativeEntity extends CrudConsumerObject {}

/**
 * ComparativeEntity consumer.
 */
export class ComparativeEntityConsumer extends CrudConsumer {
    constructor(
        endpoint = `${getApiBaseEndpoint()}/comparative-entity/`,
        objectClass = ComparativeEntity,
        options = { csrfProtection: false }
    ) {
        super(endpoint, objectClass, options);

        this.addHeader("AUTHORIZATION", API_KEY);
    }

    /**
     * Sets the language.
     * @param {Object} language
     */
    setLanguage(language) {
        this.endpoint = `${getApiBaseEndpoint(language)}/comparative-entity/`;
    }

    /**
     * Sets the session header.
     * @param {Session} session
     */
    setSession(session) {
        this.addHeader("x-sessionid", session.session);
    }
}
