/**
 * Bleaches string (removes HTML).
 * @param {string} string
 * @return {string}
 */
export const bleach = (string, allowedTags = []) => {
    const recurse = (node, allowedTags) => {
        return [...node.childNodes]
            .map((childNode) => {
                // Tag is allowed.
                if (allowedTags.indexOf(childNode.tagName) > -1) {
                    return childNode.outerHTML;
                }

                // Tag is text content.
                if (!childNode.tagName) {
                    return childNode.textContent;
                }

                // Tag  has children.
                if (node.childElementCount) {
                    return recurse(childNode, allowedTags);
                }

                return childNode.textContent;
            })
            .join("");
    };

    try {
        const _allowedTags = allowedTags.map((tag) => tag.toUpperCase());
        const div = document.createElement("div");
        div.innerHTML = string;
        return recurse(div, _allowedTags);
    } catch (e) {
        console.warn(e);
        return string;
    }
};

/**
 * Converts a generic YouTube url to an embedable YouTube URL (iframe source).
 * @param {string} youtubeURL
 * @returns {string}
 */
export const createYouTubeEmbedURL = (youtubeURL) => {
    const url = String(youtubeURL);

    if (url.match("watch?")) {
        return url.replace("watch?v=", "embed/").replace(/&.+/, "");
    }
    return url;
};

/**
 * Coverts string to slug format e.g.: "Foo Bar" -> "foo-bar".
 * @param {string} string
 * @returns {string}
 */
export const slugify = (string) => {
    return String(string).replace(/\s/g, "-").toLowerCase();
};

/**
 * Return string with first letter as capital.
 * @param {string} string
 * @return {string}
 */
export const ucfirst = (string) => {
    return String(string[0]).toUpperCase() + String(string).slice(1);
};

/**
 * Converts camelcase string to snake case format e.g.: "fooBar" -> "foo_bar".
 * @param {string} camelCaseString
 * @returns {string}
 */
export const camelCaseToSnakeCase = (camelCaseString) => {
    return String(camelCaseString)
        .replace(/([A-Z])/g, "_$1")
        .toLowerCase();
};
