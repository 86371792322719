import React from "react";
import { IconButton } from "../../components/icon-button/IconButton";
import { deepGet } from "../../lib/util";

/** The base Twitter share url. */
const TWITTER_SHARE_BASE_URL = "https://twitter.com/share?text=";

/** {number} Representing the initial width (in pixels) of the share window */
const WINDOW_WIDTH = 520;

/** {number} representing the initial height (in pixels) of the share window */
const WINDOW_HEIGHT = 350;

/**
 * Renders the Twitter share button.
 * @param {Tool} tool
 * @param {string} [background]
 * @param {string} [color]
 * @param {size} [size]
 * @param [pad]
 * @param [tabIndex]
 * @return {JSX.Element|null}
 */
export const renderTwitter = (
    tool,
    background = "#000",
    color = "#FFF",
    size = "normal",
    pad = false,
    tabIndex = 301
) => {
    if (
        !tool.social_tool_setting ||
        !tool.social_tool_setting.use_facebook_share
    ) {
        return;
    }

    return (
        <IconButton
            icon="twitter"
            alt={`${deepGet(
                tool,
                "social_tool_setting.share",
                "delen"
            )}: Twitter`}
            background={background}
            color={color}
            label="X"
            pad={pad}
            size={size}
            onClick={() => openTwitterPopup(tool)}
            tabIndex={tabIndex}
        />
    );
};

/**
 * Opens the Twitter share popup.
 */
const openTwitterPopup = (tool) => {
    const url =
        TWITTER_SHARE_BASE_URL +
        encodeURIComponent(
            tool.social_tool_setting.facebook_open_graph_share_description
        ) +
        "&url=" +
        encodeURIComponent(tool.social_tool_setting.forced_general_share_url);

    window.open(
        url,
        tool.social_tool_setting.like,
        `width=${WINDOW_WIDTH},height=${WINDOW_HEIGHT}`
    );
};
