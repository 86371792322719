import React from "react";
import { useHistory, generatePath } from "react-router-dom";
import { Body } from "../../components/typography/Body";
import { Form } from "../../components/form/Form";
import { ROUTES } from "../../routes";
import { BackgroundQuestionAnswerConsumer } from "../../data/background_question_answer";
import { isMobile } from "../../lib/responsive";
import { deepGet } from "../../lib/util";

/**
 * Popup questions page.
 * @return {JSX.Element}
 */
export const ExtraBackgroundQuestionsView = ({ ...props }) => {
    const history = useHistory();

    /**
     * Returns form inputs.
     * @param {Object[]} source
     * @return {Object[]}
     */
    const getFormInputs = (source = props.backgroundQuestionsState) =>
        source
            .filter((question) => question.show_in_separate_popup === true)
            .filter((question) => question.active);
    /**
     * Returns form actions.
     * @return {Object[]}
     */
    const getFormActions = () => [
        {
            label: deepGet(getPage(), "submit_button_text", ""),
            onClick: onFormSubmit,
        },
    ];

    /**
     * Returns the background_question_page.
     * @returns {Object|null}
     */
    const getPage = () => {
        return props.toolState.background_question_page;
    };

    /**
     * Returns the correct title for the device.
     * @return {string}
     */
    const getTitle = () => {
        const page = getPage();

        if (!page) {
            return "";
        }

        if (isMobile()) {
            return (
                page.extra_questions_title_mobile || page.extra_questions_title
            );
        }
        return page.extra_questions_title;
    };

    /**
     * Continues to the next route.
     * @param {string} [path]
     */
    const nextRoute = (
        path = generatePath(ROUTES.STATEMENTS.path, {
            language: props.match.params.language,
        })
    ) => {
        // "nextRoute" callback passed as prop.
        if (props.nextRoute) {
            props.nextRoute(path);
            return;
        }
        history.push(path);
    };

    /**
     * Form change handler.
     * @param {SyntheticEvent} e
     * @param {Object[]} formState
     */
    const onFormChange = (e, formState) => {
        props.setBackgroundQuestionsState(formState);
    };

    /**
     * Form submit hook.
     */
    const onFormSubmit = () => {
        // Get answers.
        const answeredBackgroundQuestions = props.backgroundQuestionsState
            .filter((question) => question.value)
            .map((question) => ({
                question: question.id,
                value: question.value,
            }));

        // Send answers to API.
        const backgroundAnswerConsumer = new BackgroundQuestionAnswerConsumer();
        backgroundAnswerConsumer.setSession(props.sessionState);
        backgroundAnswerConsumer
            .create(answeredBackgroundQuestions)
            .then(() => {
                nextRoute(); // Go to the next route.
            })
            .catch((error) => {
                if (error.statusCode === 403) {
                    let parsedMessage = JSON.parse(
                        error.statusText.response.data
                    );
                    if (parsedMessage.code === "session_expired") {
                        history.push(
                            generatePath(ROUTES.RESTART.path, {
                                language: props.match.params.language,
                            })
                        );
                    } else {
                        props.setErrorState(error);
                        nextRoute();
                    }
                } else {
                    props.setErrorState(error);
                    nextRoute();
                }
            });
    };

    /**
     * Renders the main content.
     * @return {*}
     */
    const renderView = () => (
        <Body pad={props.pad} size={props.size}>
            <Form
                align={props.align}
                inputs={getFormInputs()}
                actions={getFormActions()}
                emptyLabel={props.toolState.general_texts.pick_your_answer}
                size={props.size}
                strong={props.strong}
                onChange={onFormChange}
                pad={props.pad}
                title={getTitle()}
            />
        </Body>
    );

    if (
        props.toolState &&
        !getFormInputs(deepGet(getPage(), "backgroundquestion_set", [])).length
    ) {
        nextRoute();
    }

    return renderView();
};
