import React from "react";
import { generatePath } from "react-router-dom";
import { HeaderButton } from "../../components/header/HeaderButton";
import { ROUTES } from "../../routes";
import { Icon } from "../../components/icon/Icon";
import { TOOLTYPES } from "./constants";

/**
 * Renders the header buttons.
 * @param {Object} match
 * @param {Tool} tool
 * @param history
 * @param {Object} [activeTool]
 * @param {Function} [onClick]
 * @return {JSX.Element[]}
 */
export const renderHeaderButtons = (
    match,
    tool,
    history,
    activeTool,
    onClick = () => {}
) => {
    const configuration = tool.result_configuration;

    const buttonParams = [
        {
            label: configuration.main_result_button_text,
            tool: tool.first_tab,
            comparativeEntity: match.params.comparativeEntity,
        },
        {
            label: configuration.second_tab_text,
            tool: tool.second_tab,
            comparativeEntity: match.params.comparativeEntity,
        },
        {
            label: configuration.third_tab_text,
            tool: tool.third_tab,
            comparativeEntity: match.params.comparativeEntity,
        },
        {
            label: configuration.fourth_tab_text,
            tool: tool.fourth_tab,
            comparativeEntity: match.params.comparativeEntity,
        },
        {
            label: configuration.fifth_tab_text,
            tool: tool.fifth_tab,
            comparativeEntity: match.params.comparativeEntity,
        },
    ].filter((buttonLabel) => buttonLabel.label && buttonLabel.tool);

    /**
     * Gets called when header button is clicked.
     * @param {Object} buttonLabel
     * @param {Event} e
     */
    const _onClick = (buttonLabel, e) => {
        history.push(
            generatePath(ROUTES.RESULTS.path, {
                language: match.params.language,
                tab: buttonLabel.tool,
                comparativeEntity: buttonLabel.comparativeEntity,
            })
        );

        if (onClick) {
            onClick(e, buttonLabel);
        }
    };

    return buttonParams.map((buttonLabel, index) => {
        return (
            <HeaderButton
                key={index}
                active={activeTool && activeTool.slug === buttonLabel.tool}
                onClick={_onClick.bind(null, buttonLabel)}
                tabIndex={index + 12}
            >
                {renderToolIcon(buttonLabel.tool, buttonLabel.label)}
                {buttonLabel.label}
            </HeaderButton>
        );
    });
};

/**
 * Renders a tool icon.
 * @param {string} tool
 * @param {string} label
 * @param [color=null]
 * @param [background=null]
 * @return {JSX.Element}
 */
export const renderToolIcon = (
    tool,
    label,
    color = null,
    background = null
) => {
    const tooltype = Object.values(TOOLTYPES).find((tooltype) => {
        if (tooltype && tooltype.slug && tool) {
            return tooltype.slug.toLowerCase() === tool.toLowerCase();
        }
        return false;
    });

    if (!tooltype) {
        return null;
    }

    return (
        <Icon
            icon={tooltype.icon}
            label={label}
            color={color}
            background={background}
        />
    );
};
