import React from "react";
import PropTypes from "prop-types";
import "./divider.scss";

export const Divider = ({ color }) => {
    return <hr className="Divider" style={{ "--border-color": color }}></hr>;
};

Divider.propTypes = {
    color: PropTypes.string.isRequired,
};

Divider.defaultProps = {
    color: "#000",
};
