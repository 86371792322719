import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./menu.scss";
import { Caption } from "../typography/Caption";

/**
 * The Menu component is a navigation drawer that typically slides in from the side of the current view for which in
 * needs to be wrapped in a Toast component.
 */
export const Menu = ({ id, languageSwitcher, title, ...props }) => {
    const className = classNames({
        Menu: true,
    });

    const renderItems = () => {
        const _children = props.children.length
            ? props.children
            : [props.children];
        return _children.map((child, index) => (
            <li key={index} className="Menu__list-item">
                {child}
            </li>
        ));
    };

    return (
        <nav id={id} className={className}>
            {title && (
                <header className="Menu__header">
                    {languageSwitcher}
                    <Caption>{title}</Caption>
                </header>
            )}

            <div className="Menu__body">
                <ul className="Menu__list">{renderItems()}</ul>
            </div>
        </nav>
    );
};

Menu.propTypes = {
    /** Child components/content.  */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.array,
    ]),

    /** The id attribute. */
    id: PropTypes.string,

    /** Language switcher component. */
    languageSwitcher: PropTypes.element,

    /** The title of the menu. */
    title: PropTypes.string,
};

Menu.defaultProps = {
    children: [],
    id: null,
    languageSwitcher: null,
    title: "",
};
