import { CrudConsumer, CrudConsumerObject } from "consumerjs";
import { API_KEY, getApiBaseEndpoint } from "./api";

/**
 * Popup question answer.
 */
class PopupQuestionAnswer extends CrudConsumerObject {}

/**
 * Popup question answer consumer.
 */
export class PopupQuestionAnswerConsumer extends CrudConsumer {
    constructor(
        endpoint = `${getApiBaseEndpoint()}/popup-answers/`,
        objectClass = PopupQuestionAnswer,
        options = { csrfProtection: false }
    ) {
        super(endpoint, objectClass, options);

        this.addHeader("AUTHORIZATION", API_KEY);
    }

    /**
     * Sets the language.
     * @param {Object} language
     */
    setLanguage(language) {
        this.endpoint = `${getApiBaseEndpoint(language)}/popup-answers/`;
    }

    /**
     * Sets the session header.
     * @param {Session} session
     */
    setSession(session) {
        this.addHeader("x-sessionid", session.session);
    }
}
