import React from "react";
import { Col } from "../../components/layout/Col";
import { Universe } from "../../components/universe/Universe";
import { filterStatementsByThemes } from "../../lib/statement";
import { deepGet } from "../../lib/util";

/**
 * Renders the universe.
 * @return {JSX.Element}
 */
export const renderUniverse = ({
    toolState,
    statementsState,
    activeComparativeEntitiesState,
    activeThemesState,
}) => {
    const _statements = filterStatementsByThemes(
        statementsState,
        activeThemesState
    );

    // Render sorted comparative entities.
    return (
        <Col pad={false} padright={false}>
            <Universe
                calculation={toolState.preferred_calculation}
                comparativeEntities={activeComparativeEntitiesState}
                labelAgreementWith={deepGet(
                    toolState,
                    "result_configuration.universe_configuration.agreement_with_label",
                    "Overeenkomst met"
                )}
                labelMarker={deepGet(
                    toolState,
                    "result_configuration.universe_configuration.marker_explanation",
                    "Dit bent u."
                )}
                srcPin={toolState.result_configuration.user_location_icon}
                statements={_statements}
            />
        </Col>
    );
};
