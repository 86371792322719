import React from "react";
import classNames from "classnames";
import "./list-item.scss";

export const ListItem = ({ ...props }) => {
    const className = classNames({
        ListItem: true,
    });

    return <li className={className}>{props.children}</li>;
};

ListItem.propTypes = {};

ListItem.defaultProps = {};
