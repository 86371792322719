import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./panel.scss";

/**
 * Panel is a footer component for Card.
 */
export const Panel = ({
    align,
    direction,
    grow,
    size,
    style,
    shadow,
    pad,
    ...props
}) => {
    const className = classNames({
        Panel: true,
        "Panel--grow": grow,
        "Panel--pad": pad,
        "Panel--shadow": shadow,
        [`Panel--${align}`]: align,
        [`Panel--${direction}`]: direction,
        [`Panel--${size}`]: size,
        [`Panel--${style}`]: style,
    });

    return (
        <div className={className} {...props}>
            <div className="Panel__body">{props.children}</div>
        </div>
    );
};

Panel.propTypes = {
    /** Child components/content.  */
    children: PropTypes.any,

    /** The alignment. */
    align: PropTypes.oneOf([
        "center",
        "justify",
        "side",
        "space-around",
        "stretch",
    ]),

    /** The content direction. */
    direction: PropTypes.oneOf(["horizontal", "vertical"]),

    /** Whether to grow this panel.  */
    grow: PropTypes.bool,

    /** Font size preset. */
    size: PropTypes.oneOf([
        "big",
        "smaller",
        "small",
        "normal",
        "bigger",
        "full",
    ]),

    /** The style. */
    style: PropTypes.oneOf(["solid", "transparent"]),

    /** Whether to show a shadow. */
    shadow: PropTypes.bool,

    /** Whether to apply vertical padding. */
    pad: PropTypes.bool,
};

Panel.defaultProps = {
    align: "center",
    direction: "horizontal",
    grow: false,
    size: "big",
    style: "solid",
    shadow: false,
    pad: true,
};
