import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./radio-group.scss";

/**
 * A radio group is a group of radio buttons. It allows a user to select at most one radio button from a set.
 */
export const RadioGroup = ({ size, ...props }) => {
    const className = classNames({
        RadioGroup: true,
        [`RadioGroup--${size}`]: true,
    });

    return (
        <div className={className} {...props}>
            {props.children}
        </div>
    );
};

RadioGroup.propTypes = {
    /** Child components/content.  */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.element, PropTypes.string])
        ),
    ]),

    /** Size preset. */
    size: PropTypes.oneOf(["normal", "big"]),
};

RadioGroup.defaultProps = {
    size: "normal",
};
