import React, { useEffect, useRef, useState } from "react";
import { Layout } from "../../components/layout/Layout";
import { Container } from "../../components/layout/Container";
import { Row } from "../../components/layout/Row";
import { Col } from "../../components/layout/Col";
import { Hero } from "../../components/hero/Hero";
import { Body } from "../../components/typography/Body";
import { Lead } from "../../components/typography/Lead";
import { Title } from "../../components/typography/Title";
import { Text } from "../../components/typography/Text";
import { Legend } from "../../components/legend/Legend";
import { renderCompass } from "../results/renderCompass";
import { getThemesByStatements } from "../../lib/statement";
import { ComparativeEntityConsumer } from "../../data/comparitive_entity";
import { ListItem } from "../../components/list/ListItem";
import { SubSubCaption } from "../../components/typography/SubSubCaption";
import { List } from "../../components/list/List";
import { deepGet } from "../../lib/util";
import { reverseRoute, ROUTES } from "../../routes";
import { SubTitle } from "../../components/typography/SubTitle";
import { Logo } from "../../components/logo/Logo";
import { generatePath, useHistory } from "react-router-dom";
import { Footer } from "../../components/footer/Footer";
import { Sponsors } from "../../components/sponsors/Sponsors";
import { Toolbar } from "../../components/toolbar/Toolbar";
import { renderTwitter } from "../results/renderTwitter";
import { renderFacebook } from "../results/renderFacebook";
import { renderMoreTools } from "../results/renderMoreTools";
import { renderMoreToolsModal } from "../results/renderMoreToolsModal";
import { renderDownloadCompass } from "../../components/compass/Compass";
import usePageTracking from "../../lib/use_page_tracking";
import { Toggle } from "../../components/form/Toggle";
import { getActiveComparativeEntities } from "../../components/range-filter/RangeFilter";
import { LanguageSwitcher } from "../../components/language-switcher/LanguageSwitcher";

/**
 * Single page view.
 * @return {JSX.Element}
 */
export const SinglePageView = ({ ...props }) => {
    const history = useHistory();
    const [activeToolState, setActiveToolState] = useState(
        ROUTES.BACKGROUND_QUESTIONS
    );
    const [comparativeEntitiesState, setComparativeEntitiesState] = useState(
        []
    );
    const [compassSvg, setCompassSvg] = useState();
    const [moreToolsActiveState, setMoreToolsActiveState] = useState(false);
    const [nationalActiveState, setNationalActiveState] = useState(
        deepGet(
            props,
            "toolState.result_configuration.national_default_selected"
        )
    );
    const [regionalActiveState, setRegionalActiveState] = useState(
        deepGet(
            props,
            "toolState.result_configuration.regional_default_selected"
        )
    );
    const [pageState, setPageState] = useState(1);
    const toolSectionRef = useRef();

    usePageTracking(
        props.toolState,
        props.gaInitialized,
        props.setGaInitialized
    );

    /**
     * Fetch comparative entities.
     */
    useEffect(() => {
        const sessionState = props.sessionState;

        if (!sessionState) {
            return;
        }

        const comparativeEntityConsumer = new ComparativeEntityConsumer();
        comparativeEntityConsumer.setSession(sessionState);
        comparativeEntityConsumer
            .read()
            .then((comparativeEntity) =>
                setComparativeEntitiesState(comparativeEntity)
            );
    }, [props.sessionState]);

    /**
     * Continues to the next route.
     * @param {string} [path]
     */
    const nextRoute = (path) => {
        const reverse = reverseRoute(path);

        // Reverse route found (view/component determines next view/component).
        if (reverse) {
            // Simulate page by utilizing state.
            if (reverse.params.page) {
                setPageState(reverse.params.page);
            }

            // Use reverse match.
            setActiveToolState(reverse.route);
        }

        // Use route nextRoute.
        else {
            setActiveToolState(activeToolState.nextRoute("", props));
        }

        // Scroll to tool.
        if (toolSectionRef.current) {
            setTimeout(() => {
                toolSectionRef.current.scrollIntoView();
            });
        }
    };

    /**
     * Logo click handler.
     */
    const onLogoClick = () => {
        history.push(
            generatePath(ROUTES.RESTART.path, {
                language: props.match.params.language,
            })
        ); // Clears state.
        window.location.reload();
    };

    /**
     * renders the hero section.
     * @return {JSX.Element}
     */
    const renderHero = () => {
        return (
            <Row center={true}>
                <Col noOverflow={true}>
                    <Hero
                        alt={deepGet(
                            props,
                            "toolState.start_page.title",
                            "",
                            true
                        )}
                        src={deepGet(
                            props,
                            "toolState.start_page.background_image",
                            "",
                            true
                        )}
                        languageSwitcher={
                            <LanguageSwitcher
                                languageState={props.languageState}
                                setLanguageState={props.setLanguageState}
                                languages={props.toolState.languages}
                                callback={props.clearState}
                            />
                        }
                        logo={
                            <Logo
                                onClick={onLogoClick}
                                src={deepGet(
                                    props,
                                    "toolState.general_style.logo"
                                )}
                                alt={deepGet(
                                    props,
                                    "toolState.general_texts.header_title"
                                )}
                            />
                        }
                    >
                        <Body pad={false}>
                            <Lead>
                                {deepGet(
                                    props,
                                    "toolState.general_texts.header_title",
                                    "",
                                    true
                                )}
                            </Lead>

                            <Title strong={true} wrap={false}>
                                {deepGet(
                                    props,
                                    "toolState.start_page.title",
                                    "",
                                    true
                                )}
                            </Title>

                            <Lead>
                                {deepGet(
                                    props,
                                    "toolState.start_page.subtitle",
                                    "",
                                    true
                                )}
                            </Lead>

                            <Text>
                                {deepGet(
                                    props,
                                    "toolState.start_page.body",
                                    "",
                                    true
                                )}
                            </Text>
                        </Body>
                    </Hero>
                </Col>
            </Row>
        );
    };

    /**
     * Renders the comparative entity positions section.
     * @return {JSX.Element}
     */
    const renderComparativeEntityPositions = () => {
        return (
            <>
                <Row overflow={true}>
                    <Col overflow={true}>
                        <Legend>
                            {deepGet(
                                props,
                                "toolState.general_texts.begin_navigation_text",
                                "",
                                true
                            )}
                        </Legend>
                    </Col>
                </Row>

                <Row center={true}>
                    <Col span={8} pad={true}>
                        {renderPositions(false)}
                    </Col>
                </Row>

                {deepGet(
                    props,
                    "toolState.result_configuration.show_party_leader",
                    false
                ) && (
                    <Row>
                        <Col span={8} pad={true}>
                            <List cols={4}>{renderListItems()}</List>
                        </Col>
                    </Row>
                )}
            </>
        );
    };

    /**
     * Renders the compass.
     * @param {boolean} showUser Whether to show the users position.
     */
    const renderPositions = (showUser) => {
        return (
            <>
                {renderComparativeEntityFilter()}

                {renderCompass(
                    {
                        aspect: true,
                        activeThemesState: getThemesByStatements(
                            props.statementsState
                        ),
                        activeComparativeEntitiesState:
                            getActiveComparativeEntities(
                                comparativeEntitiesState,
                                nationalActiveState,
                                regionalActiveState
                            ),
                        languageState: props.languageState,
                        match: props.match,
                        statementsState: props.statementsState,
                        toolState: props.toolState,
                        callback: setCompassSvg,
                    },
                    {
                        circleCount: 7,
                        circlePostitionFixed: true,
                        circleSize: 80,
                        comparativeEntityClick: false,
                        comparativeEntityColorize: true,
                        comparativeEntityNameField: "description",
                        comparativeEntitySize: 4,
                        comparativeEntityShowName: true,
                        userColorize: false,
                        combineLabels: true,
                        showUser: showUser,
                        userShowName: true,
                        showLink: false,
                    }
                )}
            </>
        );
    };

    const renderComparativeEntityFilter = () => {
        if (
            !comparativeEntitiesState.filter(
                (c) => c.regional_or_national === "national"
            ).length ||
            !comparativeEntitiesState.filter(
                (c) => c.regional_or_national === "regional"
            ).length
        ) {
            return;
        }

        return (
            <Toolbar stretch={true} align="end">
                <Toggle
                    checked={nationalActiveState}
                    stretch={false}
                    onChange={() =>
                        setNationalActiveState(!nationalActiveState)
                    }
                >
                    {deepGet(
                        props,
                        "toolState.result_configuration.national"
                    ) || "national"}
                </Toggle>

                <Toggle
                    checked={regionalActiveState}
                    stretch={false}
                    onChange={() =>
                        setRegionalActiveState(!regionalActiveState)
                    }
                >
                    {deepGet(
                        props,
                        "toolState.result_configuration.regional"
                    ) || "regional"}
                </Toggle>
            </Toolbar>
        );
    };

    /**
     * Renders the list items.
     * @return {JSX.Element[]}
     */
    const renderListItems = () => {
        return comparativeEntitiesState.map((comparativeEntity) => (
            <ListItem
                key={`${comparativeEntity.description}_${comparativeEntity.regional_or_national}`}
            >
                <SubSubCaption>{comparativeEntity.description}</SubSubCaption>
                <Text>
                    {deepGet(comparativeEntity, "party_leader", "", true)}
                </Text>
            </ListItem>
        ));
    };

    /**
     * Renders the tool.
     * @return {JSX.Element}
     */
    const renderTool = () => {
        const Component = activeToolState.Component;

        const matchStub = {
            isExact: props.match.path,
            params: {
                ...props.match.params,
                page: pageState,
            },
            path: props.match.isExact,
            ur: props.match.url,
        };

        return (
            <>
                <Row overflow={true}>
                    <Col overflow={true}>
                        <Legend>
                            {deepGet(
                                props,
                                "toolState.general_texts.statements_navigation_text",
                                "",
                                true
                            )}
                        </Legend>
                    </Col>
                </Row>

                <Row center={true}>
                    <div ref={toolSectionRef} />
                    <Col align="side" pad={true} span={8} valign="center">
                        <Component
                            {...props}
                            match={matchStub}
                            nextRoute={nextRoute}
                        />
                    </Col>
                </Row>
            </>
        );
    };

    /**
     * Renders the result compass.
     * @return {JSX.Element}
     */
    const renderResult = () => {
        return (
            <>
                <Row overflow={true}>
                    <Col overflow={true}>
                        <Legend>
                            {deepGet(
                                props,
                                "toolState.general_texts.result_navigation_text",
                                "",
                                true
                            )}
                        </Legend>
                    </Col>
                </Row>

                <Row center={true}>
                    <Col align="side" span={8} pad={true}>
                        {/* Compass... */}
                        {activeToolState === ROUTES.RESULTS && (
                            <>
                                {renderPositions(true)}

                                <Toolbar stretch={true}>
                                    {renderFacebook(
                                        props.toolState,
                                        null, // legacy,
                                        null, // legacy,
                                        "small",
                                        true
                                    )}

                                    {renderTwitter(
                                        props.toolState,
                                        null, // legacy,
                                        null, // legacy,
                                        "small",
                                        true
                                    )}

                                    {renderDownloadCompass(
                                        props.toolState,
                                        compassSvg,
                                        null, // legacy,
                                        null, // legacy,
                                        "small",
                                        true,
                                        true
                                    )}

                                    {renderMoreTools(
                                        props.toolState,
                                        () =>
                                            setMoreToolsActiveState(
                                                !moreToolsActiveState
                                            ),
                                        null, // legacy,
                                        null, // legacy,
                                        "small",
                                        true
                                    )}
                                </Toolbar>
                            </>
                        )}

                        {/* Please fill in statements text... */}
                        {activeToolState !== ROUTES.RESULTS && (
                            <Body centered={false} pad={false}>
                                <SubTitle
                                    strong={true}
                                    maxWidth={`${252 / 15}rem`}
                                >
                                    {deepGet(
                                        props,
                                        "toolState.result_configuration.enter_statements_title",
                                        "",
                                        true
                                    )}
                                </SubTitle>

                                <Text>
                                    {deepGet(
                                        props,
                                        "toolState.result_configuration.enter_statements_text",
                                        "",
                                        true
                                    )}
                                </Text>
                            </Body>
                        )}
                    </Col>
                </Row>
            </>
        );
    };

    /**
     * Renders the sponsor images.
     * @return {*}
     */
    const renderSponsors = () => {
        return deepGet(props, "toolState.general_style.sponsor_images", []).map(
            (sponsorImage, index) => (
                <Logo
                    key={index}
                    href={sponsorImage.link}
                    target="_blank"
                    src={sponsorImage.image}
                />
            )
        );
    };

    return (
        <Layout
            backgroundImage={deepGet(
                props,
                "toolState.general_style.background_image",
                ""
            )}
            scroll={true}
        >
            {/* Hero (image) */}
            {renderHero()}

            {/* Main content */}
            <Container>
                {renderComparativeEntityPositions()}
                {renderTool()}
                {renderResult()}
                {renderMoreToolsModal(
                    props.toolState,
                    () => setMoreToolsActiveState(false),
                    moreToolsActiveState
                )}
            </Container>

            {/* Credits, sponsors */}
            <Row>
                <Col>
                    <Footer>
                        <Text
                            align="center"
                            color="primary-contrast"
                            muted={false}
                        >
                            {deepGet(
                                props,
                                "toolState.general_texts.footer_text",
                                "",
                                true
                            )}
                        </Text>
                    </Footer>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Sponsors>{renderSponsors()}</Sponsors>
                </Col>
            </Row>
        </Layout>
    );
};
