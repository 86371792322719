import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./menu-button.scss";

/**
 * A button which intended use it to open the Menu component.
 */
export const MenuButton = ({ active, ...props }) => {
    const [isActive, setIsActive] = useState(active);
    useEffect(() => setIsActive(active), [active]);

    const className = classNames({
        MenuButton: true,
        [`MenuButton--active`]: isActive,
    });

    const onClick = (e) => {
        setIsActive(!isActive);

        if (props.onClick) {
            props.onClick(e);
        }
    };

    return (
        <button
            className={className}
            type="button"
            onClick={onClick}
            {...props}
        >
            <span className={"MenuButton__bars"} />
            <span className={"MenuButton__label"}>
                {isActive ? "Menu sluiten" : "Menu openen"}
            </span>
        </button>
    );
};

MenuButton.propTypes = {
    /** The active state of the menu button. */
    active: PropTypes.bool,
};

MenuButton.defaultProps = {
    active: false,
};
