import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./card-body.scss";

/**
 * CardBody is a body component for Card.
 */
export const CardBody = ({ ...props }) => {
    const className = classNames({
        CardBody: true,
    });

    return (
        <div className={className} {...props}>
            {props.children}
        </div>
    );
};

CardBody.propTypes = {
    /** Child components/content.  */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.element, PropTypes.string])
        ),
    ]),
};

CardBody.defaultProps = {};
