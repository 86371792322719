import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ProgressBar } from "../progress-bar/ProgressBar";
import { isRtl } from "../../lib/responsive";
import "./card.scss";

/**
 * Cards are a standard piece of UI that serves as an entry point to more detailed information. Cards can contain one
 * ore more <CardBody> components as children. If more than one <CardBody> is given, a slider is rendered within the
 * card. Cards optionally can receive a <CardHeader> and/or a <CardFooter> component to as the "header" and "footer"
 * props. A <Toast> can be passed to the the "modal" prop.
 */
export const Card = ({
    header,
    footer,
    modal,
    headerPosition,
    maxwidth,
    size,
    slide,
    transparent,
    ...props
}) => {
    const className = classNames({
        Card: true,
        "Card--transparent": transparent,
        [`Card--card-header-${headerPosition}`]: headerPosition,
        [`Card--${size}`]: size,
    });

    const getStyle = () => {
        if (!maxwidth) {
            return null;
        }

        return {
            maxWidth: maxwidth ? `${maxwidth / 16}rem` : null,
        };
    };

    const getSlideStyle = () => {
        const translateX = 100 * -(slide - 1);
        const _translateX = isRtl() ? translateX * -1 : translateX;

        return {
            transform: `translateX(${_translateX}%)`,
        };
    };

    const getHeader = () => {
        return header ? header : null;
    };

    const getBody = () => {
        if (props.children && props.children.length) {
            return (
                <div className="Card__slider">
                    <ProgressBar
                        animationSpeed={2}
                        value={slide}
                        max={props.children.length}
                    />
                    {getSlides()}
                </div>
            );
        }
        return props.children;
    };

    const getSlides = () => {
        const children =
            props.children && props.children.length
                ? props.children
                : [props.children];
        return children.map((child, index) => (
            <div key={index} className="Card__slide" style={getSlideStyle()}>
                {child}
            </div>
        ));
    };

    const getModal = () => {
        return modal ? modal : null;
    };

    const getFooter = () => {
        return footer ? footer : null;
    };

    return (
        <aside className={className} style={getStyle()} {...props}>
            {getHeader()}
            {getBody()}
            {getFooter()}
            {getModal()}
        </aside>
    );
};

Card.propTypes = {
    /** Child components/content.  */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.element, PropTypes.string])
        ),
    ]),

    /** CardHeader component.  */
    header: PropTypes.element,

    /** CardFooter component.  */
    footer: PropTypes.element,

    /** Toast component.  */
    modal: PropTypes.element,

    /** The position of the card header. */
    headerPosition: PropTypes.oneOf(["top", "bottom"]),

    /** Maximum width in pixels. */
    maxwidth: PropTypes.number,

    /** Font size preset. */
    size: PropTypes.oneOf(["small", "normal", "big"]),

    /** If multiple CardBodies are passed to children, the active slide (1 indexed). */
    slide: PropTypes.number,

    /** Whether the card should be transparent. */
    transparent: PropTypes.bool,
};

Card.defaultProps = {
    header: null,
    footer: null,
    modal: null,
    headerPosition: "top",
    maxwidth: null,
    slide: 1,
    transparent: false,
    size: "small",
};
