import { LandingView } from "./views/landing/LandingView";
import { BackgroundQuestionsView } from "./views/background-questions/BackgroundQuestionsView";
import { ExtraBackgroundQuestionsView } from "./views/background-questions/ExtraBackgroundQuestionsView";
import { StatementsView } from "./views/statements/StatementsView";
import { PtvView } from "./views/ptv/PtvView";
import { ResultsComponent, ResultsView } from "./views/results/ResultsView";
import { ContentView } from "./views/content/ContentView";
import { FaqView } from "./views/faq/FaqView";
import { RedirectView } from "./views/abstract/RedirectView";
import { NotFoundView } from "./views/not_found/NotFoundView";
import { SinglePageView } from "./views/single-page/SinglePageView";
import { AbstractQuestionsView } from "./views/abstract/AbstractQuestionsView";
import React from "react";
import { Row } from "./components/layout/Row";
import { Col } from "./components/layout/Col";
import { EmailPopup } from "./views/background-questions/EmailPopup";
import { matchPath } from "react-router";
import { deepGet } from "./lib/util";

/**
 * Route mapping.
 * @type {Object}
 */
export const ROUTES = {
    // Tool pages.

    RESTART: {
        exact: true,
        path: "/:language/restart/",
        View: (props) => <RedirectView restart={true} {...props} />,
    },

    ROOT: {
        exact: true,
        path: "/",
        View: (props) => <RedirectView {...props} />,
    },

    LANDING: {
        exact: true,
        path: "/:language",
        View: (props) => (
            <AbstractQuestionsView {...props}>
                <LandingView {...props} />
            </AbstractQuestionsView>
        ),
    },

    BACKGROUND_QUESTIONS: {
        exact: true,
        path: "/:language/background-questions",
        nextRoute: (path, props) => {
            if (
                deepGet(
                    props,
                    "toolState.background_question_page.email_popup",
                    false
                )
            ) {
                return ROUTES.EMAIL_POPUP;
            }
            return ROUTES.EXTRA_BACKGROUND_QUESTIONS;
        },

        Component: (props) => (
            <BackgroundQuestionsView
                {...props}
                align="side"
                pad={false}
                size="big"
                strong={true}
                hideEmailPopup={true}
            />
        ),
        View: (props) => (
            <AbstractQuestionsView {...props}>
                <Row grow={true}>
                    <Col fill={true} valign="top">
                        <BackgroundQuestionsView {...props} />
                    </Col>
                </Row>
            </AbstractQuestionsView>
        ),
    },

    // Single page email popup.
    EMAIL_POPUP: {
        exact: true,
        path: "/:language/background-questions/email",
        nextRoute: () => ROUTES.EXTRA_BACKGROUND_QUESTIONS,

        Component: (props) => (
            <EmailPopup
                {...props}
                align="side"
                modal={false}
                pad={false}
                size="big"
                strong={true}
            />
        ),
        View: () => null,
    },

    EXTRA_BACKGROUND_QUESTIONS: {
        exact: true,
        path: "/:language/background-questions/extra",
        nextRoute: () => ROUTES.STATEMENTS,

        Component: (props) => (
            <ExtraBackgroundQuestionsView
                {...props}
                align="side"
                pad={false}
                size="big"
                strong={true}
            />
        ),
        View: (props) => (
            <AbstractQuestionsView {...props}>
                <Row grow={true}>
                    <Col fill={true} valign="top">
                        <ExtraBackgroundQuestionsView {...props} pad={false} />
                    </Col>
                </Row>
            </AbstractQuestionsView>
        ),
    },

    STATEMENTS: {
        exact: true,
        path: "/:language/statements",
        nextRoute: () => ROUTES.PTV,

        Component: (props) => (
            <StatementsView
                buttonStyle="square"
                margin={false}
                noOpinionOnNewLine={true}
                pad={false}
                progressIndicatorPosition="bottom"
                segmentSize="small"
                size="normal"
                showCounter={true}
                strong={true}
                transparent={true}
                wrap={false}
                {...props}
            />
        ),
        View: (props) => (
            <AbstractQuestionsView {...props}>
                <Row grow={true}>
                    <Col
                        backgroundImage={deepGet(
                            props,
                            "toolState.statement_page.background_image",
                            ""
                        )}
                        blur={deepGet(
                            props,
                            "toolState.statement_page.background_image_blur",
                            false
                        )}
                        compensatetop={true}
                        pad={false}
                        valign="center"
                    >
                        <StatementsView {...props} />
                    </Col>
                </Row>
            </AbstractQuestionsView>
        ),
    },

    PTV: {
        exact: true,
        path: "/:language/ptv/:page",
        nextRoute: () => ROUTES.RESULTS,

        Component: (props) => <PtvView {...props} />, // TODO
        View: (props) => (
            <AbstractQuestionsView headerProps={{ style: "flat" }} {...props}>
                <PtvView {...props} />
            </AbstractQuestionsView>
        ),
    },

    RESULTS: {
        exact: true,
        path: "/:language/results/:tab?/:comparativeEntity?",

        Component: (props) => <ResultsComponent {...props} />,
        View: (props) => <ResultsView {...props} />,
    },

    CONTENT: {
        exact: true,
        path: "/:language/content/:slug",
        View: (props) => (
            <AbstractQuestionsView {...props}>
                <ContentView {...props} />
            </AbstractQuestionsView>
        ),
    },

    FAQ: {
        exact: true,
        path: "/:language/faq/",
        View: (props) => (
            <AbstractQuestionsView {...props}>
                <FaqView {...props} />
            </AbstractQuestionsView>
        ),
    },

    // Single page.

    SINGLE_PAGE: {
        exact: true,
        path: "/:language/singlepage/",
        View: (props) => <SinglePageView {...props} />,
    },

    // Utility.

    NOT_FOUND: {
        View: (props) => (
            <AbstractQuestionsView {...props}>
                <NotFoundView {...props} />
            </AbstractQuestionsView>
        ),
    },
};

/**
 * Reverses path, returns matched route.
 * @param {string} path
 * @return {Object|null}
 */
export const reverseRoute = (path) => {
    const entry = Object.entries(ROUTES).find(([, value]) =>
        matchPath(path, value)
    );

    if (entry) {
        return {
            ...matchPath(path, entry[1]),
            route: entry[1],
        };
    }
    return null;
};
