import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./card-header.scss";

/**
 * CardHeader is a header component for Card.
 */
export const CardHeader = ({ pad, transparent, ...props }) => {
    const className = classNames({
        CardHeader: true,
        "CardHeader--transparent": transparent,
        "CardHeader--pad": pad,
    });

    return (
        <header className={className} {...props}>
            {props.children}
        </header>
    );
};

CardHeader.propTypes = {
    /** Child components/content.  */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.element, PropTypes.string])
        ),
    ]),

    /** Whether to apply horizontal padding. */
    pad: PropTypes.bool,

    /** Whether the card should be transparent. */
    transparent: PropTypes.bool,
};

CardHeader.defaultProps = {
    pad: true,
    transparent: false,
};
