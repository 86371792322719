import React from "react";
import { deepGet } from "../../lib/util";
import { bleach } from "../../lib/format";
import { Modal } from "../../components/modal/Modal";
import { Body } from "../../components/typography/Body";
import { SubTitle } from "../../components/typography/SubTitle";
import { Text } from "../../components/typography/Text";
import { Toolbar } from "../../components/toolbar/Toolbar";
import { Button } from "../../components/button/Button";

/**
 * The privacy popup asking the user to fill in background questions.
 */
export const PrivacyPopup = ({
    active,
    labelClose,
    onClose,
    onContinue,
    onSkip,
    page,
}) => {
    /**
     * Returns the title.
     * @return {string}
     */
    const getTitle = () => {
        return deepGet(page, "privacy_popup.title", "");
    };

    /**
     * Returns the body.
     * @return {string}
     */
    const getBody = () => {
        return deepGet(page, "privacy_popup.text", "");
    };

    /**
     * Returns the footnotes.
     * @return {string}
     */
    const getFootnotes = () => {
        return bleach(deepGet(page, "privacy_popup.small_text"), [
            "a",
            "strong",
            "em",
            "u",
            "h1",
            "h2",
            "h3",
        ]);
    };

    return (
        <Modal
            active={active}
            size="big"
            dark={true}
            allowclose={true}
            labelClose={labelClose}
            onClose={onClose}
        >
            <Body align="center">
                <SubTitle>{getTitle()}</SubTitle>
                <Text>{getBody()}</Text>

                <Toolbar>
                    <Button
                        minwidth={true}
                        style="primary"
                        onClick={onContinue}
                    >
                        {deepGet(page, "privacy_popup.continue_button")}
                    </Button>

                    <Button minwidth={true} style="secondary" onClick={onSkip}>
                        {deepGet(page, "privacy_popup.skip_button")}
                    </Button>
                </Toolbar>

                <Text size="small" html={getFootnotes()} />
            </Body>
        </Modal>
    );
};
