import React from "react";
import { Col } from "../../components/layout/Col";
import { Persona } from "../../components/persona/Persona";
import { filterStatementsByThemes } from "../../lib/statement";
import { sortComparativeEntitiesByScore } from "../../lib/score/comparative_entity_score";

/**
 * Renders the persona.
 * @return {JSX.Element}
 */
export const renderPersona = ({
    activeThemesState,
    activeComparativeEntitiesState,
    statementsState,
    toolState,
}) => {
    const _statements = filterStatementsByThemes(
        statementsState,
        activeThemesState
    );
    const scoredComparativeEntities = sortComparativeEntitiesByScore(
        _statements,
        activeComparativeEntitiesState,
        toolState.preferred_calculation
    );
    const comparativeEntity = scoredComparativeEntities[0] || null;

    if (!comparativeEntity) {
        return null;
    }

    // Render first comparative entity.
    return (
        <Col pad={false} padright={false}>
            <Persona comparativeEntity={comparativeEntity} />
        </Col>
    );
};
