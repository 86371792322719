import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { slugify } from "../../lib/format";
import {
    filterStatementsByTheme,
    getThemeChoicesByStatements,
} from "../../lib/statement";
import { Select } from "../form/Select";
import { Panel } from "../panel/Panel";
import { Statement } from "../statement/Statement";
import { SubCaption } from "../typography/SubCaption";
import "./statement-filter.scss";

/**
 * Statement filter.
 */
export const StatementFilter = ({
    statements,
    title,
    labelThemes,
    onChange,
    ...props
}) => {
    const [themeValueState, setThemeValueState] = useState("");
    const [activeStatementState, setActiveStatementState] = useState(
        statements.length ? statements[0] : null
    );
    const [scrolledStart, setScrolledStart] = useState(true);
    const [scrolledEnd, setScrolledEnd] = useState(false);

    // Update active statement when theme changes.
    useEffect(() => {
        const filteredStatements = themeValueState
            ? filterStatementsByTheme(statements, themeValueState)
            : statements;
        const value = filteredStatements.length ? filteredStatements[0] : null;
        setActiveStatementState(value);
    }, [themeValueState, statements]);

    useEffect(() => {
        onChange({}, activeStatementState);
    }, [activeStatementState, onChange]);

    const className = classNames({
        StatementFilter: true,
        "StatementFilter--scrolled-start": scrolledStart,
        "StatementFilter--scrolled-end": scrolledEnd,
    });

    /**
     * Handles state updates during scroll.
     * @param {SyntheticEvent} e
     */
    const onScroll = (e) => {
        const scrollStart = Math.max(e.target.scrollTop, e.target.scrollLeft);
        const clientSize =
            e.target.scrollHeight > e.target.scrollWidth
                ? e.target.clientHeight
                : e.target.clientWidth;
        const scrollSize = Math.max(
            e.target.scrollHeight,
            e.target.scrollWidth
        );

        setScrolledStart(false);
        setScrolledEnd(false);

        if (scrollStart === 0) {
            setScrolledStart(true);
            setScrolledEnd(false);
        }

        if (scrollStart + clientSize === scrollSize) {
            setScrolledStart(false);
            setScrolledEnd(true);
        }
    };

    /**
     * Renders the statements.
     * @return {unknown[]}
     */
    const renderStatements = () => {
        const filteredStatements = themeValueState
            ? filterStatementsByTheme(statements, themeValueState)
            : statements;
        return filteredStatements.map((statement, index) => (
            <Statement
                key={index}
                active={activeStatementState === statement}
                align="center"
                size="small"
                toggle={false}
                onClick={() => setActiveStatementState(statement)}
                tabIndex={210 + index}
            >
                {statement.statement}
            </Statement>
        ));
    };

    return (
        <aside className={className} {...props}>
            <header className="StatementFilter__head">
                <Panel align="side" direction="vertical" grow={true}>
                    <SubCaption color="black">
                        <label htmlFor={`select-${slugify(title)}`}>
                            {title}
                        </label>
                    </SubCaption>

                    <Select
                        className="StatementFilter__select-themes"
                        options={[
                            ["", labelThemes],
                            ...getThemeChoicesByStatements(statements),
                        ]}
                        value={themeValueState}
                        onChange={(e) => setThemeValueState(e.target.value)}
                        id={`select-${slugify(title)}`}
                    />

                    <Select
                        className="StatementFilter__select-statements"
                        options={filterStatementsByTheme(
                            statements,
                            themeValueState
                        ).map((statement) => [
                            String(statement.id),
                            statement.statement,
                        ])}
                        value={
                            activeStatementState
                                ? String(activeStatementState.id)
                                : null
                        }
                        onChange={(e) => {
                            const statement = statements.find(
                                (statement) =>
                                    String(statement.id) === e.target.value
                            );
                            setActiveStatementState(statement);
                        }}
                    />
                </Panel>
            </header>

            <div className="StatementFilter__body">
                <Panel
                    align="side"
                    direction="vertical"
                    grow={true}
                    pad={false}
                    onScroll={onScroll}
                >
                    {renderStatements()}
                </Panel>
            </div>
        </aside>
    );
};

StatementFilter.propTypes = {
    /** Child components/content.  */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.element, PropTypes.string])
        ),
    ]),

    /** The title. */
    title: PropTypes.string,

    /** The themes label. */
    labelThemes: PropTypes.string,

    /** The statements. */
    statements: PropTypes.array,

    /** onChange callback. */
    onChange: PropTypes.func,
};

StatementFilter.defaultProps = {
    title: "Filter op stelling",
    labelThemes: "Selecteer thema's",
    statements: [],
    onChange: () => {},
};
