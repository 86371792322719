import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./toolbar.scss";

/**
 * A generic wrapper that typically contains one or more Button components
 */
export const Toolbar = ({ align, stretch, ...props }) => {
    const className = classNames({
        Toolbar: true,
        "Toolbar--stretch": stretch,
        [`Toolbar--${align}`]: align,
    });

    return <div className={className}>{props.children}</div>;
};

Toolbar.propTypes = {
    /** Child components/content.  */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.element, PropTypes.string])
        ),
    ]),

    /** The alignment based on the text direction (LTR/RTL). */
    align: PropTypes.oneOf(["center", "end", "side"]),

    /** Whether to stretch horizontally. */
    stretch: PropTypes.bool,
};

Toolbar.defaultProps = {
    align: "center",
    stretch: false,
};
