import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { getId } from "../../lib/id";
import { useAnimation } from "../../lib/animation";
import "./compass-party.scss";

/**
 * CompassParty shows the party's position in the progressive/conservative left wing/right wing quadrant.
 * TODO: Rename to comparative entity.
 */
export const CompassParty = ({
    active,
    animate,
    colorize,
    name,
    showName,
    src,
    size,
    tag,
    title,
    x,
    y,
    onClick,
    ...props
}) => {
    const [showAnimation, setShowAnimation] = useState(animate);
    const [href, setHref] = useState(src);
    const node = useRef();
    const [id] = useState(getId());

    const renderedX = useAnimation(x);
    const renderedY = useAnimation(y);
    const renderedSize = useAnimation(size, 100, 0);
    // Inline image, allowing conversion to PNG on user's demand.
    useEffect(() => {
        let isUnounted = false;

        const image = new Image();
        image.crossOrigin = "Anonymous";
        image.onload = () => {
            if (isUnounted) {
                return;
            }

            // Create canvas
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            // Set width and height
            canvas.width = image.width;
            canvas.height = image.height;

            ctx.drawImage(image, 0, 0);
            const dataURL = canvas.toDataURL();
            setHref(dataURL);
        };

        image.src = src;

        return () => (isUnounted = true);
    }, [src]);

    const getX = () => {
        if (typeof size === "number") {
            return `${renderedX * 25 + 50 - size / 2}%`;
        }
        return null;
    };

    const getY = () => {
        if (typeof size === "number") {
            return `${-renderedY * 25 + 50 - size / 2}%`;
        } else {
            return null;
        }
    };

    const getSize = () => {
        if (typeof size === "number") {
            return `${renderedSize}%`;
        } else {
            return size;
        }
    };

    const className = classNames({
        CompassParty: true,
        "CompassParty--active": active,
        "CompassParty--animate": showAnimation,
    });

    const Wrapper = tag ? tag : onClick ? "button" : "g";

    return (
        <Wrapper
            className={className}
            tabIndex={onClick ? "0" : null}
            onClick={onClick}
            {...props}
        >
            {/* Icon */}
            <svg
                ref={node}
                className="CompassParty__icon CompassParty__icon--primary"
                aria-label={name}
                width={getSize()}
                height={getSize()}
                version="1.1"
                x={getX()}
                y={getY()}
                xmlns="http://www.w3.org/2000/svg"
                onAnimationEnd={() => setShowAnimation(false)}
            >
                <title>{title}</title>

                <defs>
                    <radialGradient id={`${id}-gradient`}>
                        <stop offset="0%" stopColor="#000" stopOpacity={1} />
                        <stop offset="99%" stopColor="#CCC" stopOpacity={0} />
                    </radialGradient>

                    <clipPath id={`${id}-mask`}>
                        <circle cx="50%" cy="50%" r="40%"></circle>
                    </clipPath>
                </defs>

                <circle
                    className="CompassParty__shadow"
                    cx="50%"
                    cy="50%"
                    r="50%"
                    fill={`url(#${id}-gradient)`}
                ></circle>
                <circle
                    className="CompassParty__background"
                    cx="50%"
                    cy="50%"
                    r="40%"
                    fill="#FFF"
                ></circle>

                {!colorize && (
                    <image
                        clipPath={`url(#${id}-mask)`}
                        x="10%"
                        y="10%"
                        width="80%"
                        height="80%"
                        href={href}
                    ></image>
                )}
                {colorize && (
                    <circle
                        className="CompassParty__fill"
                        cx="50%"
                        cy="50%"
                        r="40%"
                        href={href}
                    ></circle>
                )}

                <>
                    <circle
                        className="CompassParty__outline"
                        cx="50%"
                        cy="50%"
                        r="41%"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="transparent"
                        style={{ visibility: active ? "visible" : "hidden" }}
                    />
                    <svg
                        x="67%"
                        y="67%"
                        width="25%"
                        height="25%"
                        style={{ visibility: active ? "visible" : "hidden" }}
                    >
                        <circle cx="50%" cy="50%" r="50%" fill="currentColor" />
                        <line
                            x1="25%"
                            x2="40%"
                            y1="50%"
                            y2="75%"
                            stroke="#FFF"
                        ></line>
                        <line
                            x1="40%"
                            x2="75%"
                            y1="75%"
                            y2="25%"
                            stroke="#FFF"
                        ></line>
                    </svg>
                </>
            </svg>

            {/* Label */}
            {showName && (
                <svg
                    ref={node}
                    className="CompassParty__icon CompassParty__icon--primary"
                    aria-label={name}
                    height={getSize()}
                    version="1.1"
                    x={getX()}
                    y={getY()}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <text
                        fontFamily="Lora, Serif"
                        fontSize="14"
                        fontWeight="bold"
                        dominantBaseline="central"
                        textAnchor={x > 0 ? "end" : null}
                        transform={
                            x > 0 ? "translate(-36 0)" : "translate(4 0)"
                        }
                        x={getSize()}
                        y="50%"
                    >
                        {name}
                    </text>
                </svg>
            )}
        </Wrapper>
    );
};

CompassParty.propTypes = {
    /** Whether the compass party is active. */
    active: PropTypes.bool,

    /** Whether to animate the comparative entity. */
    animate: PropTypes.bool,

    /** Whether to replace image with solid color. */
    colorize: PropTypes.bool,

    /** The party name. */
    name: PropTypes.string,

    /** Whether to show the name next to the icon . */
    showName: PropTypes.bool,

    /** The logo src. */
    src: PropTypes.string,

    /** The size. */
    size: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.number,
        PropTypes.string,
    ]),

    /** The wrapper tag. */
    tag: PropTypes.string,

    /** The title. */
    title: PropTypes.string,

    /** The x position. */
    x: PropTypes.number,

    /** The y position. */
    y: PropTypes.number,

    /** onClick callback. */
    onClick: PropTypes.func,
};

CompassParty.defaultProps = {
    active: false,
    animate: true,
    colorize: false,
    name: "",
    showName: false,
    src: null,
    size: 10,
    tag: null,
    title: "",
    x: 20,
    y: 20,
    onClick: null,
};
