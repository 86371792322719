import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./image.scss";

/**
 * An image that optionally supports multiple sources for various device types.
 */
export const Image = ({
    align,
    alt,
    src,
    mobileSrc,
    tabletSrc,
    laptopSrc,
    tabletBreakpoint,
    laptopBreakpoint,
    desktopBreakpoint,
    width,
    height,
    objectFit,
    objectPosition,
    ...props
}) => {
    const className = classNames({
        Image: true,
        "Image--height-set": height,
        [`Image--${align}`]: align,
        [`Image--${objectFit}`]: objectFit,
        [`Image--object-position-${objectPosition}`]: objectPosition,
    });

    const mobileMQ = `(max-width: ${tabletBreakpoint - 1}px)`;
    const tabletMQ = `(max-width: ${laptopBreakpoint - 1}px)`;
    const desktopBQ = `(max-width: ${desktopBreakpoint - 1}px)`;

    return (
        <picture className={className} style={{ height: height }} {...props}>
            {mobileSrc && <source media={mobileMQ} srcSet={mobileSrc} />}
            {tabletSrc && <source media={tabletMQ} srcSet={mobileSrc} />}
            {laptopSrc && <source media={desktopBQ} srcSet={mobileSrc} />}
            <img
                className="Image__image"
                alt={alt}
                src={src}
                width={width}
                height={height}
            />
        </picture>
    );
};

Image.propTypes = {
    /** The alignment of the image. */
    align: PropTypes.oneOf(["", "left", "right"]),

    /** The alt attribute of the image. */
    alt: PropTypes.string.isRequired,

    /** The src attribute of the desktop and fallback image. */
    src: PropTypes.string.isRequired,

    /** The src attribute of the mobile image. */
    mobileSrc: PropTypes.string,

    /** The src attribute of the tablet image. */
    tabletSrc: PropTypes.string,

    /** The src attribute of the laptop image. */
    laptopSrc: PropTypes.string,

    /** The minimum screen width (in pixels) which is considered to be a tablet. */
    tabletBreakpoint: PropTypes.number,

    /** The minimum screen width (in pixels) which is considered to be a laptop. */
    laptopBreakpoint: PropTypes.number,

    /** The minimum screen width (in pixels) which is considered to be a desktop. */
    desktopBreakpoint: PropTypes.number,

    /** The image width (in pixels). */
    width: PropTypes.number,

    /** The image height (in pixels). */
    height: PropTypes.number,

    /** The value of the "object-fit" CSS property. */
    objectFit: PropTypes.oneOf(["fill", "cover", "contain"]),

    /** The value of the "object-fit" CSS property. */
    objectPosition: PropTypes.oneOf(["center", "left", "right"]),
};

Image.defaultProps = {
    align: "",
    alt: "",
    src: "",
    mobileSrc: null,
    tabletSrc: null,
    laptopSrc: null,
    tabletBreakpoint: 768,
    laptopBreakpoint: 992,
    desktopBreakpoint: 1200,
    width: null,
    height: null,
    objectFit: null,
    objectPosition: null,
};
