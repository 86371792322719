import { PREFERRED_CALCULATION } from "../../views/results/constants";
import { calculateAgreementPercentage } from "./agreement_score";
import {
    calculateManhattanDistance,
    getNormalizedManhattanDistance,
} from "./manhattan_distance";

/**
 * We write/cache the (normalized) score of a comparative entity to the comparative entity object:
 *
 * - For performance reasons.
 * - For consistency with preferred_calculation.
 *
 * We use a (guaranteed unique) Symbol to avoid any possibly clashed with existing keys on the object.
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Symbol Symbol on MDN}.
 * @const {Symbol}
 */
export const score = Symbol("score");

/**
 * Scores comparative entities based on "agreement" or "distance" calculation.
 * `score` Symbol (number (0-100)) is set on each comparative entity object allowing for future reference.
 * Comparative entities without a valid score are omited from the result.
 * @param {{axis: string, inverse: boolean, use_in_calculation: boolean, value: *}[]} statements
 * @param {{fk_answers: {answer: {value: *}, statement: {axis: string, inverse: boolean, use_in_calculation: boolean, value: *}}}[]} comparativeEntities
 * @param {"agreement"|"distance"} calculation The calculation method to used for scoring.
 *  - "agreement" calculates how much the user agrees with a comparative entity on individual statements.
 *  - "distance" calculates how close/distant a user is to a comparative entity on a political spectrum
 *     (left wing/right wing, progressive/conservative).
 * @return {{fk_answers: {answer: {value: *}, statement: {axis: string, inverse: boolean, use_in_calculation: boolean, value: *}}, [score]: number}[]}
 */
export const scoreComparativeEntities = (
    statements,
    comparativeEntities,
    calculation
) => {
    const _comparativeEntities = [...comparativeEntities];

    // Sort comparative entities based on agreement percentage.
    if (calculation === PREFERRED_CALCULATION.AGREEMENT) {
        return _comparativeEntities.map((c) => {
            c[score] = calculateAgreementPercentage(statements, c);
            return c;
        });
    }

    // Sort comparative entities based on distance/proximity percentage.
    return _comparativeEntities.map((c) => {
        const distance = calculateManhattanDistance(statements, c);

        const normalizedDistance =
            distance === Infinity
                ? 100
                : getNormalizedManhattanDistance(distance);

        c[score] = 100 - normalizedDistance; // Convert to proximity.
        return c;
    });
};

/**
 * Sorts comparative entities by score either based on "agreement" or "distance" calculation.
 * `score` Symbol (number (0-100)) is set on each comparative entity object allowing for future reference.
 * Comparative entities without a valid score are omitted from the result.
 * @param {{axis: string, inverse: boolean, use_in_calculation: boolean, value: *}[]} statements
 * @param {{fk_answers: {answer: {value: *}, statement: {axis: string, inverse: boolean, use_in_calculation: boolean, value: *}}}[]} comparativeEntities
 * @param {"agreement"|"distance"} calculation The calculation method to used for scoring.
 *  - "agreement" calculates how much the user agrees with a comparative entity on individual statements.
 *  - "distance" calculates how close/distant a user is to a comparative entity on a political spectrum
 *     (left wing/right wing, progressive/conservative).
 * @param {boolean} [unbias=false] Eliminates a bias by using random positions for equal comparative entities resulting in an unstable sort.
 * @return {{fk_answers: {answer: {value: *}, statement: {axis: string, inverse: boolean, use_in_calculation: boolean, value: *}}, [score]: number}[]}
 */
export const sortComparativeEntitiesByScore = (
    statements,
    comparativeEntities,
    calculation,
    unbias = false
) => {
    return scoreComparativeEntities(
        statements,
        comparativeEntities,
        calculation
    ).sort((a, b) => {
        // Eliminates a bias by using random positions for equal comparative entities resulting in an unstable sort.
        if (unbias && a[score] === b[score]) {
            return Math.random() - Math.random();
        }
        return b[score] - a[score];
    });
};
