import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { IconButton } from "../icon-button/IconButton";
import { Text } from "../typography/Text";
import { createYouTubeEmbedURL } from "../../lib/format";
import { Image } from "../image/Image";
import "./statement.scss";

/**
 * Statement is a title like component showing a certain (political) statement.
 */
export const Statement = ({
    active,
    align,
    explanationActive,
    size,
    statement,
    stretch,
    strong,
    toggle,
    transparent,
    onClick,
    ...props
}) => {
    const [activeState, setActiveState] = useState(active);
    const [explanationActiveState, setExplanationActiveState] =
        useState(explanationActive);
    useEffect(() => setActiveState(active), [active]);
    useEffect(
        () => setExplanationActiveState(explanationActive),
        [explanationActive]
    );

    const className = classNames({
        Statement: true,
        "Statement--active": activeState,
        "Statement--explanation-active": explanationActiveState,
        "Statement--stretch": stretch,
        "Statement--strong": strong,
        "Statement--transparent": transparent,
        "Statement--toggle": toggle,
        [`Statement--${align}`]: align,
        [`Statement--${size}`]: size,
    });

    /**
     * H1 click handler.
     * @param {SyntheticEvent} e
     */
    const onDivClick = (e) => {
        if (toggle) {
            setActiveState(!activeState);
        }
        if (onClick) {
            onClick(e);
        }
    };

    /**
     * IconButton click handler.
     * @param {SyntheticEvent} e
     */
    const onIconButtonClick = (e) => {
        if (onClick) {
            onClick(e);
        }
    };

    /**
     * Renders statement
     * @returns {(JSX.Element|null)}
     */
    const renderStatement = () => {
        if (!statement || props.children) {
            return null;
        }
        return (
            <h1 className="Statement__statement">
                {statement.explanation && (
                    <IconButton
                        icon={
                            explanationActiveState
                                ? "arrow-left-circle"
                                : "explanation-circle"
                        }
                        pad={false}
                        onClick={onIconButtonClick}
                    />
                )}
                {statement.statement}
            </h1>
        );
    };

    const renderMeta = () => {
        if (!explanationActiveState) {
            return null;
        }

        return (
            <aside className="Statement__explanation">
                <Text muted={true}>{statement.explanation}</Text>

                {statement.metadata_type === "video" && (
                    <div className="Statement__meta">
                        <iframe
                            className="Statement__iframe"
                            src={createYouTubeEmbedURL(statement.metadata_url)}
                            title="YouTube"
                            width="560"
                            height="315"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                    </div>
                )}

                {statement.metadata_type === "image" && (
                    <div className="Statement__meta">
                        <Image
                            src={statement.metadata_url}
                            alt={statement.explanation}
                            width={560}
                            height={315}
                            objectFit="contain"
                        />
                    </div>
                )}
            </aside>
        );
    };

    const Tag = onClick && size === "small" ? "button" : "div";
    return (
        <Tag className={className} onClick={onDivClick} {...props}>
            {renderStatement()}
            {renderMeta()}
            {props.children && (
                <h1 className="Statement__statement">{props.children}</h1>
            )}
        </Tag>
    );
};

Statement.propTypes = {
    /** Child components/content.  */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.element, PropTypes.string])
        ),
    ]),

    /** Active state. */
    active: PropTypes.bool,

    /** The alignment based on the text direction (LTR/RTL). */
    align: PropTypes.oneOf(["side", "center"]),

    /** Explanation Active state. */
    explanationActive: PropTypes.bool,

    /** The size. */
    size: PropTypes.oneOf(["big", "medium", "small"]),

    /** Statement object */
    statement: PropTypes.object,

    /** Whether to use strong text. */
    strong: PropTypes.bool,

    /** Whether to stretch vertically. */
    stretch: PropTypes.bool,

    /** Whether to allow a toggle. */
    toggle: PropTypes.bool,

    /** Whether the statement should be transparent. */
    transparent: PropTypes.bool,

    /** onClick callback. */
    onClick: PropTypes.func,
};

Statement.defaultProps = {
    active: false,
    align: "side",
    explanationActive: false,
    size: "big",
    statement: {},
    stretch: false,
    strong: false,
    transparent: false,
    toggle: false,
    onClick: null,
};
