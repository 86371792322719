import React from "react";
import { Col } from "../../components/layout/Col";
import { JustificationTable } from "../../components/justification-table/JustificationTable";
import { Body } from "../../components/typography/Body";
import { SubCaption } from "../../components/typography/SubCaption";
import "./render-justification-table.scss";

/**
 * @param {Object} match
 * @return {JSX.Element}
 */
export const renderJustificationTable = ({
    activeStatementState,
    activeComparativeEntitiesState,
    activeComparativeEntityState,
    history,
    match,
    toolState,
}) => (
    <Col>
        <Body align="center">
            <SubCaption color="black">
                {activeStatementState?.statement}
            </SubCaption>
        </Body>
        <JustificationTable
            tool={toolState}
            statement={activeStatementState}
            comparativeEntities={activeComparativeEntitiesState}
            activeComparativeEntity={activeComparativeEntityState}
            history={history}
            match={match}
        />
    </Col>
);
