import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./sub-caption.scss";

/**
 * SubCaption is a simple component that can be used to style smaller titles.
 */
export const SubCaption = ({ align, color, fontsize, ...props }) => {
    const className = classNames({
        SubCaption: true,
        [`SubCaption--${align}`]: align,
        [`SubCaption--${color}`]: color && color.charAt(0) !== "#",
    });

    const getStyle = () => {
        if (!color && !fontsize) {
            return null;
        }

        return {
            color: color && color.charAt(0) === "#" ? color : null,
            fontSize: fontsize ? `${fontsize / 16}rem` : null,
        };
    };

    return (
        <h5 className={className} style={getStyle()} {...props}>
            {props.children}
        </h5>
    );
};

SubCaption.propTypes = {
    /** Child components/content.  */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.element, PropTypes.string])
        ),
    ]),

    /** The alignment based on the text direction (LTR/RTL). */
    align: PropTypes.oneOf(["side", "center"]),

    /** Color. */
    color: PropTypes.string,

    /** Font size in pixels. */
    fontsize: PropTypes.number,
};

SubCaption.defaultProps = {
    align: "side",
    color: null,
    fontsize: null,
};
