/**
 * Sets social meta tags.
 * @param tool
 */
export const setSocial = (tool) => {
    if (!tool) {
        return;
    }

    const conf = tool.social_tool_setting;

    if (!conf) {
        return;
    }

    setMeta("fb:app_id", conf.facebook_app_id); // ?
    setMeta("og:type", `${conf.facebook_open_graph_app}:website`);
    setMeta("og:url", conf.forced_general_share_url);
    setMeta("og:title", conf.facebook_open_graph_share_description);
    setMeta("og:image", conf.facebook_open_graph_share_image);
    setMeta("og:description", conf.facebook_open_graph_share_description);
    setMeta(
        "og:locale",
        `${tool.primary_language.iso_code}_${tool.primary_language.country_code}`
    );

    // Update html lang.
    try {
        document.querySelector("html").lang = tool.primary_language.iso_code;
    } catch (e) {}
};

/**
 * Sets a meta tag.
 * @param {string} name When containing a colon (":"), "property" is used as name attribute instead of "name'.
 * @param {string} content
 */
const setMeta = (name, content) => {
    try {
        const head = document.head;
        const attribute = name.indexOf(":") > -1 ? "property" : "name";
        let meta = head.querySelector(`meta[${attribute}="${name}"]`);

        if (!meta) {
            meta = document.createElement("meta");
            meta.setAttribute(attribute, name);
            head.appendChild(meta);
        }

        if (content) {
            meta.content = content;
        } else {
            head.removeChild(meta);
        }
    } catch (e) {}
};
