import React from "react";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { isDevelopmentEnvironment } from "./data/api";

let dns = "https://76df67c5e2b2431c8c9935827c2f7943@sentry.maykinmedia.nl/93";
let sampleRate = 1;
if (process.env.REACT_APP_ENV === "production") {
    dns = "https://f313ee834fd54acbb0d1cedd4a1935ea@sentry.maykinmedia.nl/99";
    sampleRate = 0.1;
}

if (!isDevelopmentEnvironment()) {
    Sentry.init({
        dsn: dns,
        sampleRate: sampleRate,
    });
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
