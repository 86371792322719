import React, { useEffect, useState } from "react";
import { generatePath } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ThemeFilterConsumer } from "../../data/theme_filter";
import { slugify } from "../../lib/format";
import { isMobile } from "../../lib/responsive";
import {
    getThemeChoicesByStatements,
    getThemesByStatements,
} from "../../lib/statement";
import { ROUTES } from "../../routes";
import { Select } from "../form/Select";
import { Toggle } from "../form/Toggle";
import { Panel } from "../panel/Panel";
import { SubCaption } from "../typography/SubCaption";
import "./theme-filter.scss";

/**
 * Theme filter.
 */
export const ThemeFilter = ({
    statements,
    themes,
    title,
    match,
    onClick,
    sessionState,
    setErrorState,
    ...props
}) => {
    const [activeThemes, setActiveThemes] = useState(themes);
    const [scrolledStart, setScrolledStart] = useState(true);
    const [scrolledEnd, setScrolledEnd] = useState(false);
    const allThemes = getThemesByStatements(statements);

    useEffect(() => {
        setActiveThemes(themes);
    }, [themes]);

    const className = classNames({
        ThemeFilter: true,
        "ThemeFilter--scrolled-start": scrolledStart,
        "ThemeFilter--scrolled-end": scrolledEnd,
    });

    /**
     * Handles state updates during scroll.
     * @param {SyntheticEvent} e
     */
    const onScroll = (e) => {
        const scrollStart = Math.max(e.target.scrollTop, e.target.scrollLeft);
        const clientSize =
            e.target.scrollHeight > e.target.scrollWidth
                ? e.target.clientHeight
                : e.target.clientWidth;
        const scrollSize = Math.max(
            e.target.scrollHeight,
            e.target.scrollWidth
        );

        setScrolledStart(false);
        setScrolledEnd(false);

        if (scrollStart === 0) {
            setScrolledStart(true);
            setScrolledEnd(false);
        }

        if (scrollStart + clientSize === scrollSize) {
            setScrolledStart(false);
            setScrolledEnd(true);
        }
    };

    /**
     * Handles select change (on mobile).
     * @param {SyntheticEvent} e
     */
    const onSelectChange = (e) => {
        const selectedThemeIds = [...e.target.selectedOptions].map(
            (o) => o.value
        );
        const newActiveThemes = allThemes.filter(
            (theme) => selectedThemeIds.indexOf(String(theme.id)) > -1
        );
        setActiveThemes(newActiveThemes);
        onClick(e, newActiveThemes);
        sendChangesToApi(newActiveThemes);
    };

    /**
     * Toggle change handler.
     * @param {SyntheticEvent} e
     * @param {boolean} active
     */
    const onToggleChange = (e, active) => {
        const themeId = e.target.value;
        let newActiveThemes = [
            ...activeThemes.filter((theme) => {
                return String(theme.id) !== themeId;
            }),
        ];

        if (active) {
            let toAdd = allThemes.filter(
                (theme) => String(theme.id) === themeId
            );
            newActiveThemes = [...newActiveThemes, ...toAdd];
        }

        setActiveThemes(newActiveThemes);
        onClick(e, newActiveThemes);
        sendChangesToApi(newActiveThemes);
    };

    const sendChangesToApi = (newActiveThemes) => {
        // Send answers to API.
        const data = {
            themes: newActiveThemes.map((theme) => theme.id),
            source: "compass",
        };

        const themeFilterConsumer = new ThemeFilterConsumer();
        themeFilterConsumer.setSession(sessionState);
        themeFilterConsumer
            .create(data)
            .then(() => {
                // Do nothing
            })
            .catch((error) => {
                if (error.statusCode === 403) {
                    let parsedMessage = JSON.parse(
                        error.statusText.response.data
                    );
                    if (parsedMessage.code === "session_expired") {
                        window.history.push(
                            generatePath(ROUTES.RESTART.path, {
                                language: match.params.language,
                            })
                        );
                    } else {
                        setErrorState(error);
                    }
                } else {
                    setErrorState(error);
                }
            });
    };

    const isActive = (theme) => {
        const filteredThemes = activeThemes.filter(
            (activeTheme) => activeTheme.id === theme.id
        );
        return filteredThemes.length > 0;
    };

    /**
     * Renders the header.
     * @return {JSX.Element}
     */
    const renderHeader = () => {
        const themes = getThemesByStatements(statements);

        if (themes.length <= 1) {
            return null;
        }

        return (
            <header className="ThemeFilter__head">
                <Panel align="side" direction="vertical" grow={true}>
                    <SubCaption color="black">
                        <label
                            htmlFor={
                                isMobile() ? `select-${slugify(title)}` : null
                            }
                        >
                            {title}
                        </label>
                    </SubCaption>

                    <Select
                        id={`select-${slugify(title)}`}
                        multiple={true}
                        options={getThemeChoicesByStatements(statements, true)}
                        onChange={onSelectChange}
                        value={activeThemes.map((theme) => String(theme.id))}
                    />
                </Panel>
            </header>
        );
    };

    /**
     * Renders the body.
     * @return {JSX.Element}
     */
    const renderBody = () => {
        const themes = getThemesByStatements(statements);

        return (
            <div className="ThemeFilter__body">
                <Panel
                    align="side"
                    direction="vertical"
                    grow={true}
                    onScroll={onScroll}
                >
                    {themes.length > 1 && renderThemes(themes)}
                </Panel>
            </div>
        );
    };

    /**
     * Renders the themes.
     * @param {string[]} themes
     * @returns {JSX.Element[]}
     */
    const renderThemes = (themes) => {
        return themes.map((theme, index) => (
            <Toggle
                key={index}
                value={String(theme.id)}
                checked={isActive(theme)}
                onChange={onToggleChange}
                tabIndex={210 + index}
            >
                {theme.name}
            </Toggle>
        ));
    };

    return (
        <aside className={className} {...props}>
            {renderHeader()}
            {renderBody()}
        </aside>
    );
};

ThemeFilter.propTypes = {
    /** The themes. */
    themes: PropTypes.array,

    /** The statements. */
    statements: PropTypes.array,

    /** The title. */
    title: PropTypes.string,

    /** The "onClick" callback. */
    onClick: PropTypes.func,

    /** The "sessionState" callback. */
    sessionState: PropTypes.object,

    /** The "setErrorState" callback. */
    setErrorState: PropTypes.func,
};

ThemeFilter.defaultProps = {
    statements: [],
    themes: [],
    title: "Selecteer thema's",
    onClick: () => {},
    sessionState: {},
    setErrorState: () => {},
};
