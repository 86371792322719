import React, { useCallback, useEffect, useRef, useState } from "react";
import {
    BrowserRouter as Router,
    generatePath,
    Route,
    Switch,
} from "react-router-dom";
import { Body } from "./components/typography/Body";
import { Button } from "./components/button/Button";
import { Modal } from "./components/modal/Modal";
import { SubTitle } from "./components/typography/SubTitle";
import { Text } from "./components/typography/Text";
import { Toolbar } from "./components/toolbar/Toolbar";
import { isDevelopmentEnvironment } from "./data/api";
import { SessionConsumer } from "./data/session";
import { ToolConsumer } from "./data/tool";
import { getLanguageFromUrl, isRtlLanguage } from "./lib/i18n";
import { clone, deepGet } from "./lib/util";
import { setSocial } from "./meta";
import { ROUTES } from "./routes";
import { LOCALSTORAGE_COOKIE_CONSENT_KEY } from "./settings/config";
import "./app.scss";
import Color from "color";

/**
 * The frontend application.
 * @return {*}
 */
const App = (props) => {
    const [languageState, setLanguageState] = useState();
    const [toolState, setToolState] = useState();
    const [gaInitialized, setGaInitialized] = useState(false);

    const [sessionState, setSessionState] = useState();
    const [isSessionExpiring, setIsSessionExpiring] = useState(false);

    const [errorState, setErrorState] = useState(null);

    const [backgroundQuestionsState, _setBackgroundQuestionsState] = useState(
        []
    );
    const [popupQuestionsState, _setPopupQuestionsState] = useState([]);
    const [ptvState, _setPtvState] = useState([]);
    const [statementsState, _setStatementsState] = useState([]);

    const pollId = useRef();
    const sessionConsumer = useRef(new SessionConsumer());
    const toolConsumer = useRef(new ToolConsumer());

    /**
     * Returns the initial state of the cookie consent toast.
     * @returns {boolean}
     */
    const getInitialCookieConsentPromptState = () => {
        try {
            return !Boolean(
                localStorage.getItem(LOCALSTORAGE_COOKIE_CONSENT_KEY)
            );
        } catch (e) {
            return true;
        }
    };

    /**
     * Sets the initial state of the cookie consent toast.
     * @returns {boolean}
     */
    const setInitialCookieConsentPromptState = (value = true) => {
        try {
            return localStorage.setItem(LOCALSTORAGE_COOKIE_CONSENT_KEY, value);
        } catch (e) {}
    };

    /**
     * Fetches the session, either from cache or API.
     */
    const fetchSession = useCallback(() => {
        sessionConsumer.current
            .getOrCreate()
            .then((session) => {
                setSessionState(session);
            })
            .catch((error) => {
                if (
                    error &&
                    error.statusCode === 404 &&
                    !isDevelopmentEnvironment
                ) {
                    window.location = "/404.html";
                }
                setErrorState(error);
            });
    }, [sessionConsumer]);

    /**
     * Fetches the tool, either from cache or API.
     */
    const fetchTool = useCallback(() => {
        toolConsumer.current
            .getOrRead()
            .then((tool) => {
                setToolState(tool);
                setErrorState(null);
            })

            // Handle session expiry.
            // TODO: Move to consumer (data) layer.
            .catch((error) => {
                try {
                    if (error.statusCode === 403) {
                        let parsedMessage = JSON.parse(
                            error.statusText.response.data
                        );

                        if (parsedMessage.code === "session_expired") {
                            const language =
                                languageState || getLanguageFromUrl();
                            localStorage.clear();
                            window.location = "/" + language;
                            return;
                        }
                    }
                } catch (e) {}

                setErrorState(error);
            });
    }, [toolConsumer, languageState]);

    /**
     * Clear __most of__ the state, allowing the user to restart.
     */
    const clearState = useCallback(() => {
        setSessionState(null);
        _setBackgroundQuestionsState([]);
        _setStatementsState([]);
        _setPtvState([]);
        _setPopupQuestionsState([]);

        try {
            localStorage.clear();
        } catch (e) {
            console.warn(e);
        }

        fetchSession();
    }, [fetchSession]);

    /**
     * Restarts the tool.
     */
    const restart = useCallback(() => {
        clearTimeout(pollId.current);
        clearState();
        const path = generatePath(ROUTES.RESTART.path, {
            language: getLanguageFromUrl(),
        });
        window.location = path;
    }, [pollId, clearState]);

    /**
     * Abstract implementation of merging state update.
     * @param {Object[]} currentState
     * @param {Function} updateFunc
     * @param {Object[]} updatedState
     * @param {(boolean|string)} [debug=false]
     */
    const setGenericState = (
        currentState,
        updateFunc,
        updatedState,
        debug = false
    ) => {
        // Clone state.
        const newState = clone(currentState);

        // Update items in cloned (new) state.
        updatedState.forEach((updatedStateQuestion) => {
            // Find index.
            const index = newState.findIndex(
                (newStateQuestion) =>
                    parseInt(newStateQuestion.id) ===
                    parseInt(updatedStateQuestion.id)
            );
            const position = index > -1 ? index : newState.length;

            // Update item.
            newState[position] = updatedStateQuestion;
        });

        // Replace.
        updateFunc(newState);

        // Update cache.
        toolState?.setCache();

        // Debug (print state on update).
        if (debug) {
            const label = `${(typeof debug === "string"
                ? debug
                : "State"
            ).toUpperCase()}:`;
            console.debug(label, newState);

            [...newState].forEach((question) =>
                console.debug(
                    label,
                    question.id,
                    question.question,
                    question.value
                )
            );
        }
    };

    /**
     * Updates backgroundQuestions state, preserving old items.
     * @param {Object[]} updatedState
     */
    const setBackgroundQuestionsState = (updatedState) => {
        setGenericState(
            backgroundQuestionsState,
            _setBackgroundQuestionsState,
            updatedState
        );
    };

    /**
     * Updates statementsState state, preserving old items.
     * @param {Object[]} updatedState
     */
    const setStatementsState = (updatedState) => {
        setGenericState(statementsState, _setStatementsState, updatedState);
    };

    /**
     * Updates popupQuestions state, preserving old items.
     * @param {Object[]} updatedState
     */
    const setPopupQuestionsState = (updatedState) => {
        setGenericState(
            popupQuestionsState,
            _setPopupQuestionsState,
            updatedState
        );
    };

    /**
     * Updates ptvState state, preserving old items.
     * @param {Object[]} updatedState
     */
    const setPtvState = (updatedState) => {
        setGenericState(ptvState, _setPtvState, updatedState);
    };

    const [cookieConsentPromptState, _setCookieConsentPromptState] = useState(
        getInitialCookieConsentPromptState()
    );
    const setCookieConsentPromptState = (value) => {
        _setCookieConsentPromptState(value);
        setInitialCookieConsentPromptState();
    };

    // Start session.
    useEffect(() => {
        fetchSession();
    }, [props, fetchSession]);

    // Fetch tool, set error on failure.
    useEffect(() => {
        if (!sessionState) {
            return;
        }

        if (languageState) {
            toolConsumer.current.setLanguage(languageState);
        }
        toolConsumer.current.setSession(sessionState);

        // Make the request.
        fetchTool();
    }, [fetchTool, languageState, sessionState]);

    useEffect(
        () => {
            if (!toolState) {
                return;
            }

            setBackgroundQuestionsState(
                deepGet(
                    toolState,
                    "background_question_page.backgroundquestion_set",
                    []
                )
            );
            setStatementsState(
                deepGet(toolState, "statement_page.statements", [])
            );
            setPtvState(
                toolState.ptv_question_pages.reduce(
                    (acc, ptv_question_page, pageIndex) => [
                        ...acc,
                        ...ptv_question_page.questions.map((question) => {
                            question.pageIndex = pageIndex;
                            return question;
                        }),
                    ],
                    []
                )
            );
            setSocial(toolState);

            toolState.popup_question_page &&
                setPopupQuestionsState(
                    toolState.popup_question_page.popupquestion_set
                );
        },
        [toolState] // eslint-disable-line react-hooks/exhaustive-deps -- Prevent infinite loop
    );

    //
    // Misc.
    //

    /**
     * Polls for session duration.
     */
    useEffect(() => {
        const tick = () => {
            const expiresAtTimestamp = sessionState?.expires_at;
            const currentTimestamp = new Date().getTime() / 1000;
            const remainingSessionTimeInSeconds = Math.floor(
                expiresAtTimestamp - currentTimestamp
            );

            const exp =
                remainingSessionTimeInSeconds <= 60 &&
                remainingSessionTimeInSeconds > 0;
            setIsSessionExpiring(exp);

            if (remainingSessionTimeInSeconds <= 0) {
                restart();
            }

            pollId.current = setTimeout(tick, 1000);
        };

        pollId.current = setTimeout(tick, 1000);

        return () => clearTimeout(pollId.current);
    }, [restart, sessionState]);

    /**
     * Extends the session.
     * @returns {Promise}
     */
    const extendSession = async () => {
        const extendedSession = await sessionState.extend();
        setSessionState(extendedSession);
    };

    //
    // Route management.
    //

    /**
     * Renders the routes in order.
     * @return {*}
     */
    const renderRoutes = () => {
        // Tool not (yet) loaded.
        if (!toolState) {
            return null;
        }

        if (toolState.display_type === "singlepage") {
            return (
                <>
                    <Route
                        path="/:language"
                        exact={false}
                        render={(props) =>
                            renderView(ROUTES.SINGLE_PAGE.View, props)
                        }
                    />
                    <Route
                        {...ROUTES.RESTART}
                        render={(props) =>
                            renderView(ROUTES.RESTART.View, props)
                        }
                    />
                    <Route
                        {...ROUTES.ROOT}
                        render={(props) => renderView(ROUTES.ROOT.View, props)}
                    />
                </>
            );
        }

        return Object.values(ROUTES).map(({ View, ...props }, index) => (
            <Route
                key={index}
                {...props}
                render={(props) => renderView(View, props)}
            />
        ));
    };

    /**
     * Renders View.
     * @param View
     * @param props route props.
     * @return {JSX.Element}
     */
    const renderView = (View, props) => {
        return (
            <View
                cookieConsentPromptState={cookieConsentPromptState}
                setCookieConsentPromptState={setCookieConsentPromptState}
                languageState={languageState}
                setLanguageState={setLanguageState}
                sessionState={sessionState}
                toolState={toolState}
                setErrorState={setErrorState}
                gaInitialized={gaInitialized}
                setGaInitialized={setGaInitialized}
                clearState={clearState}
                backgroundQuestionsState={backgroundQuestionsState}
                setBackgroundQuestionsState={setBackgroundQuestionsState}
                statementsState={statementsState}
                setStatementsState={setStatementsState}
                ptvState={ptvState}
                setPtvState={setPtvState}
                popupQuestionsState={popupQuestionsState}
                setPopupQuestionsState={setPopupQuestionsState}
                {...props}
            />
        );
    };

    //
    // Render app.
    //

    return (
        <div
            className="App"
            dir={isRtlLanguage(toolState, languageState) ? "rtl" : "ltr"}
            style={{
                "--color-primary": toolState?.general_style?.primary_color,
                "--color-primary-contrast":
                    toolState?.general_style?.primary_contrast_color,
                "--color-secondary": toolState?.general_style?.secondary_color,
                "--color-secondary-contrast":
                    toolState?.general_style?.secondary_contrast_color,
                "--color-tertiary": toolState?.general_style?.tertiary_color,
                "--color-tertiary-contrast":
                    toolState?.general_style?.tertiary_contrast_color,
                "--color-border": toolState?.general_style?.primary_color
                    ? Color(toolState?.general_style?.primary_color).darken(0.2)
                    : null,
                "--color-border-secondary": toolState?.general_style
                    ?.secondary_color
                    ? Color(toolState?.general_style?.secondary_color).darken(
                          0.2
                      )
                    : null,
            }}
        >
            {errorState &&
                errorState.statusText &&
                String(errorState.statusText)}

            <Router>
                <Switch>{renderRoutes()}</Switch>
            </Router>

            <Modal active={isSessionExpiring} allowclose={false} size="normal">
                <Body align="center">
                    <SubTitle>
                        {deepGet(
                            toolState,
                            "general_texts.session_expiring_title",
                            "Uw sessie verloopt bijna."
                        )}
                    </SubTitle>

                    <Text>
                        {deepGet(
                            toolState,
                            "general_texts.session_expiring_description",
                            "Uw sessie is bijna verlopen, verleng uw sessie of start opnieuw."
                        )}
                    </Text>
                    <Toolbar>
                        <Button
                            minwidth={true}
                            style="primary"
                            onClick={extendSession}
                        >
                            {deepGet(
                                toolState,
                                "general_texts.session_expiring_renew",
                                "Verleng sessie"
                            )}
                        </Button>

                        <Button minwidth={true} style="open" onClick={restart}>
                            {deepGet(
                                toolState,
                                "general_texts.session_expiring_restart",
                                "Start opnieuw"
                            )}
                        </Button>
                    </Toolbar>
                </Body>
            </Modal>
        </div>
    );
};

export default App;
