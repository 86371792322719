import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useAnimation } from "../../lib/animation";
import { ucfirst } from "../../lib/format";
import "./referendum.scss";

/**
 * The progressive/conservative left wing/right wing quadrant.
 */
export const Referendum = ({
    value,
    labelNegative,
    labelPositive,
    labelResult,
    ...props
}) => {
    const renderedValue = useAnimation(value, 1, 0);

    const className = classNames({
        Referendum: true,
    });

    /**
     * Returns the result as string.
     * @return {string}
     */
    const getResultLabel = () => {
        const label = String(
            value >= 0 ? labelPositive : labelNegative
        ).toLowerCase();
        const percentage = parseFloat(Math.abs(value)).toFixed(2);
        const result = ucfirst(labelResult.toLowerCase());
        return `${result}: ${percentage}% ${label}.`;
    };

    return (
        <figure className={className} {...props}>
            <svg className="Referendum__svg">
                <title>{getResultLabel()}</title>

                <defs>
                    <linearGradient id="referendum-gradient">
                        <stop offset="0%" stopColor="#d35446" />
                        <stop offset="100%" stopColor="#5eb848" />
                    </linearGradient>
                </defs>

                <text className="Referendum__assistive-label">
                    {getResultLabel()}
                </text>

                {/* Background */}
                <rect
                    x="0%"
                    y="0.00%"
                    width="100%"
                    height="100%"
                    rx="10"
                    ry="10"
                    fill="antiquewhite"
                ></rect>

                <svg x="4%" y="0%" width="092%" height="100%">
                    {/* Gradient */}
                    <rect
                        x="0%"
                        y="16.0%"
                        width="100%"
                        height="68%"
                        rx="10"
                        ry="10"
                        fill="url(#referendum-gradient)"
                    ></rect>

                    {/* Horizontal divider /*/}
                    <rect
                        x="0%"
                        y="48.0%"
                        width="100%"
                        height="02%"
                        fill="antiquewhite"
                    ></rect>

                    {/* Scale */}
                    <text
                        aria-hidden={true}
                        x="12.5%"
                        y="11.0%"
                        textAnchor="middle"
                        fontFamily="Helvetica, Arial, sans-serif"
                    >
                        75%
                    </text>

                    <rect
                        x="12.5%"
                        y="33%"
                        width="1.0"
                        height="34%"
                        fill="antiquewhite"
                    ></rect>
                    <text
                        aria-hidden={true}
                        x="12.5%"
                        y="95.0%"
                        textAnchor="middle"
                        fontFamily="Helvetica, Arial, sans-serif"
                    >
                        75%
                    </text>

                    <text
                        aria-hidden={true}
                        x="25.0%"
                        y="11.0%"
                        textAnchor="middle"
                        fontFamily="Helvetica, Arial, sans-serif"
                    >
                        50%
                    </text>

                    <rect
                        x="25.0%"
                        y="33%"
                        width="1.0"
                        height="34%"
                        fill="antiquewhite"
                    ></rect>
                    <text
                        aria-hidden={true}
                        x="25.0%"
                        y="95.0%"
                        textAnchor="middle"
                        fontFamily="Helvetica, Arial, sans-serif"
                    >
                        50%
                    </text>

                    <text
                        aria-hidden={true}
                        x="37.5%"
                        y="11.0%"
                        textAnchor="middle"
                        fontFamily="Helvetica, Arial, sans-serif"
                    >
                        25%
                    </text>

                    <rect
                        x="37.5%"
                        y="33%"
                        width="1.0"
                        height="34%"
                        fill="antiquewhite"
                    ></rect>
                    <text
                        aria-hidden={true}
                        x="37.5%"
                        y="95.0%"
                        textAnchor="middle"
                        fontFamily="Helvetica, Arial, sans-serif"
                    >
                        25%
                    </text>

                    <text
                        aria-hidden={true}
                        x="50.0%"
                        y="11.0%"
                        textAnchor="middle"
                        fontFamily="Helvetica, Arial, sans-serif"
                    >
                        0%
                    </text>

                    <rect
                        x="50.0%"
                        y="33%"
                        width="1.0"
                        height="34%"
                        fill="antiquewhite"
                    ></rect>
                    <text
                        aria-hidden={true}
                        x="50.0%"
                        y="95.0%"
                        textAnchor="middle"
                        fontFamily="Helvetica, Arial, sans-serif"
                    >
                        0%
                    </text>

                    <text
                        aria-hidden={true}
                        x="62.5%"
                        y="11.0%"
                        textAnchor="middle"
                        fontFamily="Helvetica, Arial, sans-serif"
                    >
                        25%
                    </text>

                    <rect
                        x="62.5%"
                        y="33%"
                        width="1.0"
                        height="34%"
                        fill="antiquewhite"
                    ></rect>
                    <text
                        aria-hidden={true}
                        x="62.5%"
                        y="95.0%"
                        textAnchor="middle"
                        fontFamily="Helvetica, Arial, sans-serif"
                    >
                        25%
                    </text>

                    <text
                        aria-hidden={true}
                        x="75.0%"
                        y="11.0%"
                        textAnchor="middle"
                        fontFamily="Helvetica, Arial, sans-serif"
                    >
                        50%
                    </text>

                    <rect
                        x="75.0%"
                        y="32.5%"
                        width="1.0"
                        height="035%"
                        fill="antiquewhite"
                    ></rect>
                    <text
                        aria-hidden={true}
                        x="75.0%"
                        y="95.0%"
                        textAnchor="middle"
                        fontFamily="Helvetica, Arial, sans-serif"
                    >
                        50%
                    </text>

                    <text
                        aria-hidden={true}
                        x="87.5%"
                        y="11.0%"
                        textAnchor="middle"
                        fontFamily="Helvetica, Arial, sans-serif"
                    >
                        75%
                    </text>

                    <rect
                        x="87.5%"
                        y="32.5%"
                        width="1.0"
                        height="035%"
                        fill="antiquewhite"
                    ></rect>
                    <text
                        aria-hidden={true}
                        x="87.5%"
                        y="95.0%"
                        textAnchor="middle"
                        fontFamily="Helvetica, Arial, sans-serif"
                    >
                        75%
                    </text>

                    {/* Triangle */}
                    <svg
                        viewBox="0 0 60 160"
                        x={`${renderedValue / 2}%`}
                        y="25%"
                        height="50%"
                        aria-label={getResultLabel()}
                    >
                        <title>{getResultLabel()}</title>
                        <polygon
                            points="30,0 60,160 0,160"
                            fill="lightgray"
                            stroke="white"
                            strokeWidth="2"
                            opacity="0.9"
                        />
                    </svg>
                </svg>

                {/* Left/right labels */}
                <svg viewBox="0 0 160 160" x="-47%">
                    <text
                        x="80"
                        y="80"
                        transform="rotate(-90 80 80)"
                        textAnchor="middle"
                        fontFamily="Helvetica, Arial, sans-serif"
                    >
                        {labelNegative}
                    </text>
                </svg>

                <svg viewBox="0 0 160 160" x="47%">
                    <text
                        x="80"
                        y="80"
                        transform="rotate(90 80 80)"
                        textAnchor="middle"
                        fontFamily="Helvetica, Arial, sans-serif"
                    >
                        {labelPositive}
                    </text>
                </svg>
            </svg>
        </figure>
    );
};

Referendum.propTypes = {
    /** The value: min -100%, max 100%. */
    value: PropTypes.number,

    /** The left label. */
    labelNegative: PropTypes.string,

    /** The right label. */
    labelPositive: PropTypes.string,

    /** The result label. */
    labelResult: PropTypes.string,
};

Referendum.defaultProps = {
    value: 0,
    labelNegative: "",
    labelPositive: "",
    labelResult: "",
};
