import { CrudConsumer, CrudConsumerObject } from "consumerjs";
import { API_KEY, getApiBaseEndpoint } from "./api";
import {
    LOCALSTORAGE_SESSION_KEY,
    LOCALSTORAGE_TIMEOUT,
    LOCALSTORAGE_TIMEOUT_KEY,
} from "../settings/config";

/**
 * Session consumer object.
 */
class Session extends CrudConsumerObject {
    /**
     * Extends this session.
     * @returns {Promise}
     */
    extend() {
        return this.__consumer__.extendSession(this);
    }
}

/**
 * Session consumer.
 */
export class SessionConsumer extends CrudConsumer {
    constructor(
        endpoint = `${getApiBaseEndpoint()}/session/`,
        objectClass = Session,
        options = { csrfProtection: false }
    ) {
        super(endpoint, objectClass, options);

        /** @type {string} The localstorage key to use for cache. */
        this.localstorageKey = LOCALSTORAGE_SESSION_KEY;

        this.addHeader("AUTHORIZATION", API_KEY);
    }

    /**
     * Returns a promise for the current consumer object, retrieved from cache or created.
     * @returns {Promise}
     */
    getOrCreate() {
        return this.getFromCache()
            .catch(
                this.create.bind(this, {
                    referrer: document.referrer,
                    restart: true,
                })
            )
            .then(this.setCache.bind(this));
    }

    /**
     * Returns the a promise for the cached consumer object (if set).
     * Promise resolves with consumer object or rejects.
     * @returns {Promise}
     */
    getFromCache() {
        try {
            if (LOCALSTORAGE_TIMEOUT) {
                const timestamp = localStorage.getItem(
                    LOCALSTORAGE_TIMEOUT_KEY
                );

                if (
                    !timestamp ||
                    (new Date() - new Date(parseInt(timestamp))) / 1000 >
                        LOCALSTORAGE_TIMEOUT
                ) {
                    localStorage.clear();
                }
            }

            const value = localStorage.getItem(this.localstorageKey);
            const data = JSON.parse(value);
            const consumerObject = data
                ? new this.objectClass(data, this)
                : null;
            if (consumerObject) {
                return Promise.resolve(consumerObject);
            }
        } catch (e) {
            console.warn(e);
        }
        return Promise.reject();
    }

    /**
     * Caches the consumer object and returns a promise.
     * Promise resolves or rejects with consumer object based on cache success.
     * @param {CrudConsumerObject} consumerObject
     * @returns {Promise}
     */
    setCache(consumerObject) {
        try {
            const value = consumerObject.asJSON();
            localStorage.setItem(this.localstorageKey, value);
            localStorage.setItem(
                LOCALSTORAGE_TIMEOUT_KEY,
                new Date().getTime()
            );
        } catch (e) {
            console.warn(e);
        }
        return Promise.resolve(consumerObject);
    }

    /**
     * Extends requestSuccess with support for "lockdown".
     * @param response
     * @param method
     * @param path
     * @param data
     * @returns {Promise}
     */
    requestSuccess(response, method, path, data) {
        if (response.requestMessage.responseURL.endsWith("/lockdown/")) {
            window.location = response.requestMessage.responseURL;
        } else {
            let result = this.parse(response.response, method, path, data);
            return Promise.resolve(result);
        }
    }

    /**
     * Extends the given session.
     * @param {Session} session
     * @return {Promise}
     */
    extendSession(session) {
        this.addHeader("x-sessionid", session.session);
        return this.post("extend_session/");
    }
}
