import { useEffect, useRef, useState } from "react";

/**
 * Animation hook for React components.
 * "Tweens" `prop` to initial value/new value by increasing it's value step by step over time.
 *
 * const renderedValue = useAnimation(value);
 *
 * @param {number} prop Prop to animate, value must be a number.
 * @param {number} [speed=1] Animation speed.
 * @param {number} [initial=prop] Initial value.
 * @return {number}
 */
export const useAnimation = (prop, speed = 1, initial = prop) => {
    const [ready, setReady] = useState(false);
    const [newValue, setNewValue] = useState(prop);
    const [currentValue, setCurrentValue] = useState(initial);
    const [delta, setDelta] = useState(newValue);
    const animationFrameRequests = useRef([]);

    // Store the frame timestamp to compute frame difference while animating.
    const lastFrameTimestamp = useRef(Date.now());

    // Receive new `value`.
    useEffect(() => {
        if (ready) {
            setDelta(prop - newValue);

            if (prop - newValue === 0) {
                setCurrentValue(prop);
            }
        }
    }, [prop, newValue, ready]);

    useEffect(() => {
        lastFrameTimestamp.current = Date.now();
        setReady(false);
        setNewValue(prop);
    }, [prop]);

    // Receive new `currentValue` or `newValue`.
    useEffect(() => {
        const _animationFrameRequests = animationFrameRequests.current;

        // Values are equal, do nothing.
        if (currentValue === newValue) {
            lastFrameTimestamp.current = null;
            setReady(true);
            return;
        }

        // Update the values when the browsers is ready to render.
        const animationFrameRequest = requestAnimationFrame(() => {
            const timestampDelta = Date.now() - lastFrameTimestamp.current;
            const frameDuration = 1000 / 60;
            const frames = Math.max(
                Math.ceil(timestampDelta / frameDuration),
                1
            );
            const increaseBy = (delta / 60) * frames * speed;
            let updatedValue = currentValue + increaseBy;

            // New value would (due to floating point errors) result in a `updateValue` which is either too big or too
            // small. Adjust `updateValue` to the final `newValue`.
            if (
                (increaseBy > 0 && updatedValue >= newValue) ||
                (increaseBy < 0 && updatedValue <= newValue)
            ) {
                updatedValue = newValue;
            }

            // Update the value.
            setCurrentValue(updatedValue);

            // Update the frame timestamp.
            lastFrameTimestamp.current = Date.now();
        });

        // Allow cleanup.
        _animationFrameRequests.push(animationFrameRequest);
        return () =>
            _animationFrameRequests.forEach((animationFrameRequest) =>
                cancelAnimationFrame(animationFrameRequest)
            );
    }, [currentValue, newValue, delta, lastFrameTimestamp, speed]);

    return currentValue;
};
