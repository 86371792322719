import React from "react";
import { useHistory } from "react-router-dom";
import { Row } from "../../components/layout/Row";
import { Col } from "../../components/layout/Col";
import { Body } from "../../components/typography/Body";
import { bleach } from "../../lib/format";
import { Title } from "../../components/typography/Title";
import { Text } from "../../components/typography/Text";
import { IconButton } from "../../components/icon-button/IconButton";

/**
 * Content page.
 * @param {Object} props
 * @return {JSX.Element}
 */
export const ContentView = ({ ...props }) => {
    const history = useHistory();

    /**
     * Returns the correct title for the device.
     * @return {string}
     */
    const getTitle = () => {
        const page = getPage();

        if (!page) {
            return null;
        }

        return page.link_text;
    };

    /**
     * Returns the correct page content.
     * @return {string|null}
     */
    const getContent = () => {
        const page = getPage();

        if (!page) {
            return null;
        }

        return bleach(page.text, [
            "a",
            "br",
            "p",
            "strong",
            "em",
            "u",
            "h1",
            "h2",
            "h3",
        ]);
    };

    /**
     * Returns the current PTV page object.
     * @returns {Object|null}
     */
    const getPage = () => {
        const slug = getPageSlug();
        return props.toolState.more_info_items.find(
            (item) => item.slug === slug
        );
    };

    /**
     * Gets the page number.
     * @returns {string}
     */
    const getPageSlug = () => {
        return props.match.params.slug;
    };

    /**
     * Renders the main content.
     * @return {*}
     */
    const renderView = () => (
        <Row grow={true}>
            <Col>
                <Body>
                    <IconButton
                        icon="close"
                        pad={false}
                        onClick={() => history.goBack()}
                    ></IconButton>
                    <Title>{getTitle()}</Title>
                    <Text html={getContent()} />
                </Body>
            </Col>
        </Row>
    );

    return renderView();
};
