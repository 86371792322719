import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./body.scss";

/**
 * A generic wrapper that typically contains various typographic components.
 */
export const Body = ({ align, centered, margin, pad, size, ...props }) => {
    const className = classNames({
        Body: true,
        "Body--centered": centered,
        "Body--margin": margin,
        "Body--pad": pad === true,
        [`Body--pad-${pad}`]: typeof pad === "string",
        [`Body--${align}`]: align,
        [`Body--${size}`]: size,
    });

    return (
        <div className={className} {...props}>
            {props.children}
        </div>
    );
};

Body.propTypes = {
    /** Child components/content.  */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.element, PropTypes.string])
        ),
    ]),

    /** The alignment based on the text direction (LTR/RTL). */
    align: PropTypes.oneOf(["side", "center"]),

    /** Whether to center the body container. */
    centered: PropTypes.bool,

    /** Whether to apply margin to content elements. */
    margin: PropTypes.bool,

    /** Whether to apply horizontal padding. */
    pad: PropTypes.oneOf([true, false, "extra"]),

    /** Size preset. */
    size: PropTypes.oneOf(["normal", "big", "small"]),
};

Body.defaultProps = {
    align: "side",
    centered: true,
    margin: true,
    pad: true,
    size: "normal",
};
