import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Label } from "./Label";
import { getId } from "../../lib/id";
import "./radio.scss";

/**
 * Radio's allow the selection of a single options from a set of options. They appear as checked (ticked) when
 * activated. Clicking on a radio will toggle the checked property. They can also be checked programmatically by
 * setting the "checked" prop.
 */
export const Radio = ({
    checked,
    id,
    name,
    value,
    labelposition,
    onChange,
    tabIndex,
    ...props
}) => {
    const [stateId, setStateId] = useState();
    const [stateChecked, setStateChecked] = useState(checked);
    useEffect(() => setStateId(getId(id)), [id]);
    useEffect(() => setStateChecked(checked), [checked]);

    const className = classNames({
        Radio: true,
        [`Radio--${labelposition}`]: labelposition,
    });

    return (
        <div className={className} {...props}>
            <input
                className="Radio__input"
                id={stateId}
                checked={stateChecked}
                name={name ? name : null}
                defaultValue={value ? value : null}
                type="radio"
                onChange={onChange}
                tabIndex={tabIndex}
            />
            <div className="Radio__radio">
                <span className="Radio__checkmark"></span>
            </div>
            <Label htmlFor={stateId}>{props.children}</Label>
        </div>
    );
};

Radio.propTypes = {
    /** Child components/content.  */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.element, PropTypes.string])
        ),
    ]),

    /** The id attribute of the described element. */
    checked: PropTypes.bool,

    /** The id attribute of the radio. */
    id: PropTypes.string,

    /** The name attribute of the radio. */
    name: PropTypes.string,

    /** The value attribute of the radio. */
    value: PropTypes.string,

    /** The position of the label. */
    labelposition: PropTypes.oneOf(["outside", "inside"]),

    /** The "onChange" callback. */
    onChange: PropTypes.func,
};

Radio.defaultProps = {
    checked: false,
    id: "",
    name: "",
    value: "",
    labelposition: "outside",
    onChange: () => {},
};
