import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SubCaption } from "../typography/SubCaption";
import { IconButton } from "../icon-button/IconButton";
import "./popover.scss";

/**
 * A Popover is a dialog that appears on top of the current page.
 */
export const Popover = ({ title, active, ...props }) => {
    const [stateActive, setStateActive] = useState();
    const refHeader = useRef();

    useEffect(() => setStateActive(active), [active]);
    useEffect(() => {
        if (stateActive) {
            const button = refHeader.current?.querySelector("button");
            button?.focus();
        }
    }, [stateActive]);

    const className = classNames({
        Popover: true,
        "Popover--active": stateActive,
    });

    /**
     * Renders the header.
     * @return {JSX.Element}
     */
    const renderHeader = () => {
        return (
            <header ref={refHeader} className="Popover__header">
                <SubCaption>{title}</SubCaption>
                <IconButton
                    icon="close"
                    link={true}
                    pad={false}
                    tabIndex={stateActive ? 1 : -1}
                    onClick={() => setStateActive(false)}
                />
            </header>
        );
    };

    return (
        <aside className={className} {...props}>
            {renderHeader()}

            <div className="Popover__body">{props.children}</div>
        </aside>
    );
};

Popover.propTypes = {
    /** Child components/content.  */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.element, PropTypes.string])
        ),
    ]),

    /** The title. */
    title: PropTypes.string,

    /** The active state of the popover. */
    active: PropTypes.bool,
};

Popover.defaultProps = {
    active: false,
    title: "Help",
};
