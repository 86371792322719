import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { KEYCODES } from "../../lib/keycode";
import { Button } from "../button/Button";
import { Icon } from "../icon/Icon";
import "./toast.scss";

/**
 * A Toast is a subtle notification commonly used in modern applications. It can be used to provide feedback about an
 * operation or to display a system message. The toast appears on top of the app's content, and can be dismissed by the
 * app to resume user interaction with the app.
 */
export const Toast = ({
    active,
    activationDelay,
    timeout,
    labelClose,
    onClick,
    onClose,
    ...props
}) => {
    const [activeState, setActiveState] = useState(active);
    useEffect(() => setActiveState(active), [active]);

    const [renderedActiveState, setRenderedActiveState] = useState(false);

    const close = useCallback(
        (e) => {
            try {
                e.stopPropagation();
            } catch (e) {}

            setActiveState(false);

            if (onClose) {
                onClose(e, false);
            }
        },
        [onClose]
    );

    /**
     * onKeyUp callback.
     * @param {SyntheticEvent} e
     */
    const onKeyUp = useCallback(
        (e) => {
            if (e.keyCode === KEYCODES.ESCAPE) {
                close();
            }
        },
        [close]
    );

    useEffect(() => {
        if (activeState) {
            const _timeout = setTimeout(
                () => setRenderedActiveState(activeState),
                activationDelay
            );
            return () => clearTimeout(_timeout);
        }
        setRenderedActiveState(activeState);
    }, [activationDelay, activeState]);

    useEffect(() => {
        if (activeState && timeout !== null) {
            const _timeout = setTimeout(
                () => setRenderedActiveState(false),
                timeout
            );
            return () => clearTimeout(_timeout);
        }
    }, [activeState, timeout]);

    useEffect(() => {
        const eventListener = document.addEventListener("keyup", onKeyUp);
        return () => document.removeEventListener("keyup", eventListener);
    }, [activationDelay, onKeyUp, props, timeout]);

    const className = classNames({
        Toast: true,
        "Toast--active": renderedActiveState,
        "Toast--interactive": onClick,
    });

    const click = (e) => {
        setActiveState(false);

        if (onClick) {
            onClick(e, false);
            return;
        }

        if (onClose) {
            onClose(e, false);
        }
    };

    return (
        <aside className={className} onClick={click} {...props}>
            <div className="Toast__body">{activeState && props.children}</div>
            <Button pad={false} style="transparent" onClick={close}>
                <Icon icon="close" label={labelClose} />
            </Button>
        </aside>
    );
};

Toast.propTypes = {
    /** Child components/content.  */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.element, PropTypes.string])
        ),
    ]),

    /** The active state of the toast. */
    active: PropTypes.bool,

    /** The activation delay in microseconds. */
    activationDelay: PropTypes.number,

    /** The timeout (before hiding) in microseconds. */
    timeout: PropTypes.number,

    /** Label shown on close button (mobile only). */
    labelClose: PropTypes.string,

    /** onClose callback. */
    onClick: PropTypes.func,

    /** onClose callback. */
    onClose: PropTypes.func,
};

Toast.defaultProps = {
    active: true,
    activationDelay: 0,
    timeout: null,
    labelClose: "close",
    onClick: null,
    onClose: () => {},
};
