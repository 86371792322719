import React from "react";
import { Row } from "../../components/layout/Row";
import { Col } from "../../components/layout/Col";
import { Body } from "../../components/typography/Body";
import { Title } from "../../components/typography/Title";
import { Text } from "../../components/typography/Text";
import { SubTitle } from "../../components/typography/SubTitle";
import { IconButton } from "../../components/icon-button/IconButton";
import { useHistory } from "react-router-dom";

/**
 * Faq page.
 * @param {Object} props
 * @return {JSX.Element}
 */
export const FaqView = ({ ...props }) => {
    const history = useHistory();

    /**
     * Returns the correct title for the device.
     * @return {string}
     */
    const getTitle = () => {
        const page = getPage();

        if (!page) {
            return null;
        }

        return page.name;
    };

    /**
     * Returns the current PTV page object.
     * @returns {Object|null}
     */
    const getPage = () => {
        return props.toolState.faq_page;
    };

    /**
     * Renders the FAQ section.
     * @return {JSX.Element|null}
     */
    const renderFaq = () => {
        const page = getPage();

        if (!page) {
            return null;
        }

        const items = getPage().faq_items;

        return items.map((item, index) => (
            <React.Fragment key={index}>
                <SubTitle>{item.question}</SubTitle>
                <Text html={item.answer} />
            </React.Fragment>
        ));
    };

    /**
     * Renders the FAQ page.
     * @return {JSX.Element}
     */
    const renderView = () => (
        <Row grow={true}>
            <Col>
                <Body>
                    <IconButton
                        icon="close"
                        pad={false}
                        onClick={() => history.goBack()}
                    ></IconButton>
                    <Title>{getTitle()}</Title>
                    {renderFaq()}
                </Body>
            </Col>
        </Row>
    );

    return renderView();
};
