import React from "react";
import PropTypes from "prop-types";
import { useHistory, generatePath } from "react-router-dom";
import { Body } from "../../components/typography/Body";
import { SubTitle } from "../../components/typography/SubTitle";
import { Text } from "../../components/typography/Text";
import { Toolbar } from "../../components/toolbar/Toolbar";
import { Button } from "../../components/button/Button";
import { Modal } from "../../components/modal/Modal";
import { ROUTES } from "../../routes";
import { bleach } from "../../lib/format";
import { deepGet } from "../../lib/util";

/**
 * Email popup.
 * @return {JSX.Element}
 */
export const EmailPopup = ({
    active,
    align,
    match,
    modal,
    pad,
    size,
    strong,
    toolState,
    ...props
}) => {
    const history = useHistory();

    /**
     * Returns the background_question_page.email_popup.
     * @returns {Object|null}
     */
    const getPage = () => {
        try {
            return deepGet(
                toolState,
                "background_question_page.email_popup",
                {}
            );
        } catch (e) {
            return null;
        }
    };

    /**
     * Returns the correct title.
     * @return {string}
     */
    const getTitle = () => {
        const page = getPage();

        if (!page) {
            return "";
        }

        return page.title;
    };

    /**
     * Returns the correct subtitle.
     * @return {string}
     */
    const getSubtitle = () => {
        const page = getPage();

        if (!page) {
            return "";
        }

        return page.text;
    };

    /**
     * Continues to the next route.
     * @param {string} path
     */
    const nextRoute = (
        path = generatePath(ROUTES.STATEMENTS.path, {
            language: match.params.language,
        })
    ) => {
        // "nextRoute" callback passed as prop.
        if (props.nextRoute) {
            props.nextRoute(path);
            return;
        }

        history.push(path);
    };

    /**
     * Renders the body.
     * @return {JSX.Element}
     */
    const renderBody = () => (
        <Body align={align} pad={pad} size={size}>
            <SubTitle strong={strong}>{getTitle()}</SubTitle>
            <Text>{getSubtitle()}</Text>

            <Toolbar align={align}>
                <Button
                    minwidth={true}
                    style="primary"
                    onClick={() =>
                        nextRoute(
                            generatePath(
                                ROUTES.EXTRA_BACKGROUND_QUESTIONS.path,
                                { language: match.params.language }
                            )
                        )
                    }
                >
                    {getPage().continue_button}
                </Button>

                <Button
                    minwidth={true}
                    style="open"
                    onClick={() =>
                        nextRoute(
                            generatePath(ROUTES.STATEMENTS.path, {
                                language: match.params.language,
                            })
                        )
                    }
                >
                    {getPage().skip_button}
                </Button>
            </Toolbar>

            <Text
                size="small"
                html={bleach(getPage().small_text, [
                    "a",
                    "strong",
                    "em",
                    "u",
                    "h1",
                    "h2",
                    "h3",
                ])}
            />
        </Body>
    );

    if (modal) {
        return (
            <Modal
                active={active}
                size="big"
                dark={true}
                labelClose={deepGet(toolState, "general_texts.close")}
            >
                {renderBody()}
            </Modal>
        );
    }

    if (!getPage()) {
        nextRoute();
        return null;
    }
    return renderBody();
};

EmailPopup.propTypes = {
    /** Whether the modal is activated (if modal is used). */
    active: PropTypes.bool,

    /** The alignment based on the text direction (LTR/RTL). */
    align: PropTypes.oneOf(["center", "side"]),

    /** Whether to use a modal, if false, only the body is returned. */
    modal: PropTypes.bool,

    /** Whether to use a strong title. */
    strong: PropTypes.bool,
};

EmailPopup.defaultProps = {
    active: undefined,
    align: "center",
    modal: true,
    strong: false,
};
