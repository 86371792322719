import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { CardBody } from "../card/CardBody";
import { Statement } from "../statement/Statement";
import { Segment } from "../segment/Segment";
import { Button } from "../button/Button";
import { Modal } from "../modal/Modal";
import { Body } from "../typography/Body";
import { SubCaption } from "../typography/SubCaption";
import { Icon } from "../icon/Icon";
import { Text } from "../typography/Text";
import { Card } from "../card/Card";
import { CardHeader } from "../card/CardHeader";
import { ProgressIndicator } from "../progress-indicator/ProgressIndicator";
import { Ptv } from "../ptv/Ptv";
import { KEYCODES } from "../../lib/keycode";
import { isMobile } from "../../lib/responsive";
import "./statement-card.scss";

/**
 * StatementCard shows a card with various statements allowing the user to indicate an opinion.
 */
export const StatementCard = ({
    buttonStyle,
    labelStatement,
    labelAnswer,
    labelBack,
    labelOpen,
    labelClose,
    labelPrevious,
    labelNext,
    margin,
    noOpinionOnNewLine,
    pad,
    progressIndicatorPosition,
    segmentSize,
    size,
    showCounter,
    statementSize,
    statements,
    stretch,
    strong,
    transparent,
    wrap,
    onChange,
    ...props
}) => {
    const [statementsState, setStatementsState] = useState(statements);
    const [explanationActiveState, setExplanationActiveState] = useState(false);
    const [currentStep, _setCurrentStep] = useState(1);
    const [hoveredStep, setHoveredStep] = useState(null);

    useEffect(() => {
        setTimeout(() => {
            try {
                const selectors = `.StatementCard .Card__slide:nth-child(${
                    currentStep + 1
                }) .Segment button[value="99"]`;
                const button = document.querySelector(selectors);
                button?.focus();
            } catch (e) {}
        });
    }, [currentStep]);

    /**
     * Updates statements state when new staments are passed.
     */
    useEffect(() => setStatementsState(statements), [statements]);

    /**
     * Sets the current step.
     * @param step
     */
    const setCurrentStep = (step) => {
        setExplanationActiveState(false);
        _setCurrentStep(step);
    };

    const className = classNames({
        StatementCard: true,
        [`StatementCard--button-style-${buttonStyle}`]: buttonStyle,
        "StatementCard--explanation-active": explanationActiveState,
        "StatementCard--margin": margin,
        "StatementCard--no-margin": !margin,
        "StatementCard--pad": pad,
        Card: true,
        "Card--side": true,
        "Card--transparent": transparent,
        [`Card--card-header-${progressIndicatorPosition}`]:
            progressIndicatorPosition,
        [`Card--${size}`]: size,
    });

    /**
     * Returns the style string for an answer button.
     * @param {boolean} active
     * @return {string}
     */
    const getButtonStyle = (active, value) => {
        if (buttonStyle === "square") {
            return active ? "primary" : "open";
        }

        if (active) {
            return "secondary";
        }

        if (parseInt(value) === 99) {
            return "tertiary";
        }

        return "primary";
    };

    /**
     * Returns the counter style.
     */
    const getCounterStyle = () => {
        return {
            fontFamily: buttonStyle === "square" ? "lora" : null,
        };
    };

    /**
     * Returns whether a step is completed.
     * @param {number} step 1-indexed step number.
     * @returns {boolean}
     */
    const getCompleted = (step) => {
        try {
            return Boolean(statements[step - 1].value_human);
        } catch (e) {
            return false;
        }
    };

    /**
     * Card keyUp handler.
     * @param e
     */
    const onCardKeyUp = (e) => {
        if (e.keyCode === KEYCODES.ESCAPE) {
            setExplanationActiveState(false);
        }
    };

    /**
     * Statement change handler.
     * @param {SyntheticEvent} e
     */
    const onStatementClick = (e) => {
        if (e.target.tagName !== "BUTTON") {
            return;
        }
        setExplanationActiveState(!explanationActiveState);
    };

    /**
     * Button change handler.
     * @param {SyntheticEvent} e
     * @param {Object} statement
     * @param {number} step
     */
    const onButtonClick = (e, statement, step) => {
        const { value } = e.target;
        const id = statement.id;
        const index = statementsState.findIndex((item) => item.id === id);
        const position = index > -1 ? index : statementsState.length;
        const entry = statementsState[position] || { id };
        const newState = JSON.parse(JSON.stringify(statementsState));

        newState[position] = entry;
        newState[position].value = value;
        newState[position].value_human = e.target.textContent;
        setStatementsState(newState);

        setCurrentStep(Math.min(step + 1, statementsState.length));
        onChange(e, statementsState);
    };

    /**
     * Rendes the slides.
     * @return {JSX.Element[]}
     */
    const renderSlides = () => {
        return statementsState.map((statement, index) => {
            const step = index + 1;

            const noOpinion = Object.values(statement.answer_type)
                .filter((answer) => answer)
                .find((answer) => answer.value === 99);

            return (
                <CardBody
                    key={index}
                    aria-hidden={step !== currentStep}
                    id={`step-${currentStep}`}
                >
                    {renderCounter(step)}
                    <Statement
                        explanationActive={explanationActiveState}
                        statement={statement}
                        size={statementSize}
                        stretch={stretch}
                        strong={strong}
                        transparent={transparent}
                        onClick={onStatementClick}
                    />

                    <Segment size={segmentSize}>
                        {renderAnswers(statement, step)}
                    </Segment>

                    {(noOpinion && noOpinionOnNewLine && !isMobile() && (
                        <Segment justify={false} size="smaller">
                            <Button
                                style="open-secondary"
                                wrap={false}
                                onClick={() =>
                                    setCurrentStep(Math.max(step - 1, 1))
                                }
                                tabIndex={step !== currentStep ? -1 : null}
                            >
                                <Icon
                                    icon="arrow-left"
                                    label={labelBack}
                                ></Icon>
                                {labelBack}
                            </Button>
                            <Button
                                active={
                                    parseInt(statement.value) ===
                                    parseInt(noOpinion.value)
                                }
                                style="open-secondary"
                                wrap={false}
                                onClick={(e) =>
                                    onButtonClick(e, statement, step)
                                }
                                tabIndex={step !== currentStep ? -1 : null}
                                value={noOpinion.value}
                            >
                                {noOpinion.text_content}
                                <Icon
                                    icon="arrow-right"
                                    label={noOpinion.text_content}
                                ></Icon>
                            </Button>
                        </Segment>
                    )) ||
                        null}
                </CardBody>
            );
        });
    };

    /**
     * Renders the counter.
     * @return {JSX.Element|null}
     */
    const renderCounter = (step) => {
        if (!showCounter) {
            return null;
        }

        return (
            <Text
                className="StatementCard__counter"
                style={getCounterStyle()}
                muted={true}
                size="small"
            >
                <span className="StatementCard__counter-label">{step}</span>
                &nbsp;/&nbsp;{statementsState.length}
            </Text>
        );
    };

    /**
     * Renders the answers.
     * @param {Object} statement
     * @param {number} step
     * @return {JSX.Element[]}
     */
    const renderAnswers = (statement, step) => {
        return new Array(statement.answer_type.options_amount)
            .fill("")
            .map((answer, index) => {
                const _answer = statement.answer_type[`answer_${index + 1}`];
                const active =
                    parseInt(statement.value) === parseInt(_answer.value);

                if (
                    noOpinionOnNewLine &&
                    parseInt(_answer.value) === 99 &&
                    !isMobile()
                ) {
                    return null;
                }

                return (
                    <Button
                        key={_answer.id}
                        active={active}
                        value={String(_answer.value)}
                        style={getButtonStyle(active, _answer.value)}
                        onClick={(e) => onButtonClick(e, statement, step)}
                        pad={buttonStyle === "square" ? "horizontal" : true}
                        tabIndex={
                            step === currentStep
                                ? parseInt(_answer.value) === 99
                                    ? 100
                                    : 101 + index
                                : -1
                        }
                    >
                        {_answer.text_content}
                    </Button>
                );
            });
    };

    /**
     * Renders the modal, shown on hover.
     * @returns {JSX.Element}
     */
    const renderModal = () => {
        const statement = statementsState[hoveredStep - 1];

        return (
            <Modal
                active={Boolean(hoveredStep)}
                animationspeed="fast"
                dark={false}
                labelClose={labelClose}
            >
                <Body>
                    <SubCaption>
                        <Icon icon="statement" />
                        {labelStatement}
                    </SubCaption>
                    <Text size="big" strong={true}>
                        {statement &&
                            statementsState[hoveredStep - 1].statement}
                    </Text>
                    <SubCaption>
                        <Icon icon="answer" />
                        {labelAnswer}
                    </SubCaption>
                    <Text size="big" strong={true}>
                        {(statement &&
                            statementsState[hoveredStep - 1].value_human) ||
                            ""}
                    </Text>
                </Body>
            </Modal>
        );
    };

    return (
        <Card
            className={className}
            align="side"
            headerPosition={progressIndicatorPosition}
            maxwidth={1040}
            size="big"
            slide={currentStep}
            transparent={transparent}
            onKeyUp={onCardKeyUp}
            {...props}
            header={
                <CardHeader transparent={transparent} pad={pad}>
                    <ProgressIndicator
                        buttonStyle={buttonStyle}
                        current={currentStep}
                        max={statements.length}
                        labelOpen={labelOpen}
                        labelClose={labelClose}
                        labelPrevious={labelPrevious}
                        labelNext={labelNext}
                        getCompleted={getCompleted}
                        wrap={wrap}
                        onStepMouseEnter={(e, step) => setHoveredStep(step)}
                        onStepMouseLeave={() => setHoveredStep(null)}
                        onStepClick={(e, step) => setCurrentStep(step)}
                    />
                </CardHeader>
            }
            modal={renderModal()}
        >
            {renderSlides()}
        </Card>
    );
};

Ptv.propTypes = {
    /** The style of ProgressIndicator buttons, square is used for single page layout. */
    buttonStyle: PropTypes.oneOf(["circle", "square"]),

    /** Whether to use the "big" statement configuration. */
    big: PropTypes.bool,

    /** The "Statement:" label. */
    labelStatement: PropTypes.string,

    /** The "Your answer:" label. */
    labelAnswer: PropTypes.string,

    /** The "Close" label. */
    labelClose: PropTypes.string,

    /** The "Back" label. */
    labelBack: PropTypes.string,

    /** Alt label next button. */
    labelNext: PropTypes.string,

    /** The "Bekijk uw antwoorden" label. */
    labelOpen: PropTypes.string,

    /** Alt label previous button. */
    labelPrevious: PropTypes.string,

    /** Whether to render "no opinion" button new line. */
    noOpinionOnNewLine: PropTypes.bool,

    /** Whether to apply margin. */
    margin: PropTypes.bool,

    /** Whether to apply horizontal padding. */
    pad: PropTypes.bool,

    /** The position of the progress indicator. */
    progressIndicatorPosition: PropTypes.oneOf(["top", "bottom"]),

    /** Segment size preset. */
    segmentSize: PropTypes.oneOf(["normal", "small", "smaller"]),

    /** Size preset. */
    size: PropTypes.oneOf(["big", "normal"]),

    /** Whether to show the counter. */
    showCounter: PropTypes.bool,

    /** Statement size preset. */
    statementSize: PropTypes.oneOf(["big", "medium", "small"]),

    /** Statements to render. */
    statements: PropTypes.array,

    /** Whether to stretch vertically. */
    stretch: PropTypes.bool,

    /** Whether to use strong text. */
    strong: PropTypes.bool,

    /** Whether the card should be transparent. */
    transparent: PropTypes.bool,

    /** onChange callback (receives inputs with values as second argument). */
    onChange: PropTypes.func,
};

StatementCard.defaultProps = {
    buttonStyle: undefined,
    labelStatement: "",
    labelAnswer: "",
    labelClose: "",
    labelBack: "",
    labelNext: "",
    labelOpen: "",
    labelPrevious: "",
    margin: true,
    noOpinionOnNewLine: false,
    pad: true,
    progressIndicatorPosition: undefined,
    segmentSize: undefined,
    size: "big",
    showCounter: false,
    statementSize: undefined,
    statements: [],
    stretch: true,
    strong: false,
    transparent: false,
    wrap: undefined,
    onChange: () => {},
};
