import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Icon } from "../icon/Icon";
import { Popover } from "../popover/Popover";
import "./info-button.scss";

/**
 * Info button is a button which triggers a popover on click.
 */
export const InfoButton = ({ active, title, ...props }) => {
    const [stateActive, setStateActive] = useState(active);
    useEffect(() => setStateActive(active), [active]);

    const className = classNames({
        InfoButton: true,
        "InfoButton--active": stateActive,
    });

    const getPopover = () => (
        <Popover active={stateActive} title={title}>
            {props.children}
        </Popover>
    );

    return (
        <button
            className={className}
            type="button"
            onClick={() => setStateActive(!stateActive)}
            {...props}
        >
            <Icon icon="explanation-circle" label={title} />
            {getPopover()}
        </button>
    );
};

InfoButton.propTypes = {
    /** Child components/content.  */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.element, PropTypes.string])
        ),
    ]),

    /** The active state of the infoButton. */
    active: PropTypes.bool,

    /** The title. */
    title: PropTypes.string,
};

InfoButton.defaultProps = {
    active: false,
    title: "",
};
