import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./toast-stack.scss";

/**
 * Wraps one or more toasts.
 */
export const ToastStack = ({ ...props }) => {
    const className = classNames({
        ToastStack: true,
    });

    return (
        <div className={className} {...props}>
            {props.children}
        </div>
    );
};

ToastStack.propTypes = {
    /** Child components/content.  */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.element, PropTypes.string])
        ),
    ]),
};

ToastStack.defaultProps = {};
