import React, { useState } from "react";
import { generatePath, useHistory } from "react-router-dom";
import { ucfirst } from "../../lib/format";
import { calculateCompassPosition } from "../../lib/score/position_score";
import { Compass } from "../../components/compass/Compass";
import { CompassUser } from "../../components/compass/CompassUser";
import { CompassUserCircles } from "../../components/compass/CompassUserCircles";
import { CompassParty } from "../../components/compass/CompassParty";
import { TOOLTYPES } from "./constants";
import { ROUTES } from "../../routes";
import { filterStatementsByThemes } from "../../lib/statement";
import { isRtlLanguage } from "../../lib/i18n";
import { deepGet } from "../../lib/util";
import { sortComparativeEntitiesByScore } from "../../lib/score/comparative_entity_score";

/**
 * Returns the href to the justification page for use in the compass.
 * @return {string|null}
 */
const getCompassHref = (toolState, match, comparativeEntity) => {
    const tabs = [
        toolState.first_tab,
        toolState.second_tab,
        toolState.third_tab,
        toolState.fourth_tab,
        toolState.fifth_tab,
    ];

    if (!tabs.find((tab) => tab === TOOLTYPES.JUSTIFICATION.slug)) {
        return null;
    }

    const comparativeEntitySlug = comparativeEntity
        ? comparativeEntity.name
        : undefined;

    return generatePath(ROUTES.RESULTS.path, {
        language: match.params.language,
        tab: TOOLTYPES.JUSTIFICATION.slug,
        comparativeEntity: encodeURIComponent(comparativeEntitySlug),
    });
};

/**
 * Returns the title for an object on the compass.
 * @param {Object} toolState
 * @param {string} name
 * @param {{x: number|null, y:number|null}} entityPosition
 * @return {string}
 */
const getTitle = (toolState, name, entityPosition) => {
    const summaryX = getAxisSummary(toolState, "x", entityPosition);
    const summaryY = getAxisSummary(toolState, "y", entityPosition);
    const summaryString = [summaryX, summaryY].filter((v) => v).join(", ");

    if (summaryString) {
        return ucfirst(`${name}: ${summaryString}.`);
    }
    return ucfirst(name);
};

/**
 * Returns the summary for an axis.
 * @param {Object} toolState
 * @param {('x'|'y')}axis
 * @param {{x: number|null, y:number|null}} entityPosition
 * @return {string}
 */
const getAxisSummary = (toolState, axis, entityPosition) => {
    // No summary when no valid position is available.
    if (!entityPosition || entityPosition[axis] === null) {
        return "";
    }

    const label =
        entityPosition[axis] >= 0
            ? toolState[`axis_${axis}`].positive_identity
            : toolState[`axis_${axis}`].negative_identity;
    const percentage = Math.round(Math.abs(entityPosition[axis]) * 50);
    return `${percentage}% ${label}`.toLowerCase();
};

/**
 * Renders the compass.
 * @return {JSX.Element}
 */
export const renderCompass = (
    {
        aspect = false,
        activeThemesState,
        activeComparativeEntitiesState,
        activeComparativeEntityState,
        languageState,
        match,
        statementsState,
        toolState,
        callback,
    },
    options = {}
) => {
    const _statements = filterStatementsByThemes(
        statementsState,
        activeThemesState
    );
    const userPosition = calculateCompassPosition(_statements);
    const userLabel = deepGet(
        toolState,
        "result_configuration.compass_configuration.marker_explanation",
        "Dit bent u."
    );

    /**
     * Merges default config with options.
     * @type {{combineLabels: boolean, showUser: boolean}}
     */
    const config = Object.assign(
        {
            axesColor: undefined,
            circleCount: undefined,
            circlePostitionFixed: false,
            circleSize: undefined,
            comparativeEntityClick: true,
            comparativeEntityColorize: undefined,
            comparativeEntityNameField: "name",
            comparativeEntitySize: undefined,
            comparativeEntityShowName: undefined,
            combineLabels: undefined,
            labelColor: undefined,
            userColorize: undefined,
            showUser: true,
            userShowName: undefined,
        },
        options
    );

    return (
        <Compass
            aspect={aspect}
            axesColor={config.axesColor}
            axisX={toolState.axis_x}
            axisY={toolState.axis_y}
            href={getCompassHref(toolState, match)}
            labelColor={config.labelColor}
            rtl={isRtlLanguage(toolState, languageState)}
            combineLabels={config.combineLabels}
            svgReady={callback}
        >
            {/* Circles. */}
            <CompassUserCircles
                color={config.axesColor}
                count={config.circleCount}
                size={config.circleSize}
                x={
                    config.showUser && !config.circlePostitionFixed
                        ? userPosition.x
                        : 0
                }
                y={
                    config.showUser && !config.circlePostitionFixed
                        ? userPosition.y
                        : 0
                }
            />

            {/* Comparative entities. */}
            <ComparativeEntityAggregator
                activeComparativeEntityState={activeComparativeEntityState}
                activeComparativeEntitiesState={activeComparativeEntitiesState}
                config={config}
                match={match}
                statements={_statements}
                toolState={toolState}
            />

            {/* User. */}
            {config.showUser !== false && (
                <CompassUser
                    colorize={config.userColorize}
                    label={config.userShowName ? userLabel : ""}
                    src={toolState.result_configuration.user_location_icon}
                    title={getTitle(toolState, userLabel, userPosition)}
                    x={userPosition.x}
                    y={userPosition.y}
                />
            )}
        </Compass>
    );
};

/**
 * Aggregates comparative entities.
 * @return {JSX.Element}
 */
const ComparativeEntityAggregator = ({
    activeComparativeEntityState,
    activeComparativeEntitiesState,
    config,
    match,
    statements,
    toolState,
}) => {
    const history = useHistory();
    const [activeComparativeEntity, setActiveComparativeEntity] = useState();

    const statementIds = statements.map((statement) => statement.id);
    const orderedActiveComparativeEntitiesState =
        sortComparativeEntitiesByScore(
            statements,
            activeComparativeEntitiesState,
            "distance",
            true
        ).reverse();

    /**
     * Gets called when a comparative entity is clicked.
     * @param {SyntheticEvent} e
     * @param {Object} comparativeEntity
     */
    const onCompassPartyClick = (e, comparativeEntity) => {
        const href = getCompassHref(toolState, match, comparativeEntity);
        history.push(href);
    };

    /**
     * Gets called when a key is released on a comparative entity.
     * @param {SyntheticEvent} e
     * @param {Object} comparativeEntity
     */
    const onCompassPartyKeyUp = (e, comparativeEntity) => {
        const href = getCompassHref(
            toolState,
            match,
            comparativeEntity,
            match,
            comparativeEntity
        );

        if (e.keyCode === 13 || e.keyCode === 32) {
            // Enter or space.
            history.push(href);
        }
    };

    return (
        <>
            {orderedActiveComparativeEntitiesState.map((comparativeEntity) => {
                const entity_statements = comparativeEntity.fk_answers
                    .filter(
                        (fk_answer) =>
                            statementIds.indexOf(fk_answer.statement.id) > -1
                    )
                    .map((fk_answer) => {
                        if (fk_answer.answer) {
                            return {
                                value: fk_answer.answer.value,
                                ...fk_answer.statement,
                            };
                        }
                        return {
                            value: null,
                        };
                    });

                const entityPosition =
                    calculateCompassPosition(entity_statements);

                // Not a valid result.
                if (
                    comparativeEntity.x === null &&
                    comparativeEntity.y === null
                ) {
                    return null;
                }

                return (
                    <CompassParty
                        key={comparativeEntity.pk}
                        active={
                            comparativeEntity === activeComparativeEntityState
                        }
                        colorize={config.comparativeEntityColorize}
                        showName={config.comparativeEntityShowName}
                        size={config.comparativeEntitySize}
                        src={comparativeEntity.logo}
                        name={
                            deepGet(
                                comparativeEntity,
                                config.comparativeEntityNameField,
                                ""
                            ) || comparativeEntity.name
                        }
                        tag="g"
                        title={getTitle(
                            toolState,
                            comparativeEntity.name,
                            entityPosition
                        )}
                        x={entityPosition.x}
                        y={entityPosition.y}
                        onMouseOver={() =>
                            setActiveComparativeEntity(comparativeEntity)
                        }
                        onClick={
                            config.comparativeEntityClick
                                ? (e) =>
                                      onCompassPartyClick(e, comparativeEntity)
                                : null
                        }
                        onKeyUp={(e) =>
                            onCompassPartyKeyUp(e, comparativeEntity)
                        }
                    />
                );
            })}

            {/* TODO: REFACTOR/COMBINE*/}
            {activeComparativeEntity && (
                <CompassParty
                    key={`${activeComparativeEntity.name}_${activeComparativeEntity.regional_or_national}`}
                    animate={false}
                    colorize={config.comparativeEntityColorize}
                    showName={config.comparativeEntityShowName}
                    size={config.comparativeEntitySize}
                    src={activeComparativeEntity.logo}
                    tag="g"
                    title={getTitle(toolState, activeComparativeEntity.name, {
                        x: activeComparativeEntity.x,
                        y: activeComparativeEntity.y,
                    })}
                    name={
                        deepGet(
                            activeComparativeEntity,
                            config.comparativeEntityNameField,
                            ""
                        ) || activeComparativeEntity.name
                    }
                    x={activeComparativeEntity.x}
                    y={activeComparativeEntity.y}
                    onClick={
                        config.comparativeEntityClick
                            ? (e) =>
                                  onCompassPartyClick(
                                      e,
                                      activeComparativeEntity
                                  )
                            : null
                    }
                    onKeyUp={(e) =>
                        onCompassPartyKeyUp(e, activeComparativeEntity)
                    }
                />
            )}
        </>
    );
};
