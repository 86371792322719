import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
    getComparativeEntityAnswer,
    getComparativeEntityJustifications,
} from "../../lib/score/agreement_score";
import { Button } from "../button/Button";
import { Chip } from "../chip/Chip";
import { CompassParty } from "../compass/CompassParty";
import { Icon } from "../icon/Icon";
import { SubCaption } from "../typography/SubCaption";
import { Text } from "../typography/Text";
import "./justification-table.scss";
import { TOOLTYPES } from "../../views/results/constants";
import { generatePath } from "react-router-dom";
import { ROUTES } from "../../routes";

/**
 * The justfication table views various comparative entity's opinion about a statement.
 */
export const JustificationTable = ({
    activeComparativeEntity,
    comparativeEntities,
    history,
    match,
    statement,
    tool,
    ...props
}) => {
    const [activeComparativeEntityState, setActiveComparativeEntityState] =
        useState(activeComparativeEntity);

    /**
     * Clear active comparative entity when statement changes.
     */
    useEffect(
        () => setActiveComparativeEntityState(activeComparativeEntity),
        [activeComparativeEntity, statement]
    );

    // This might happen in weired cases.
    if (!statement) {
        return null;
    }

    const className = classNames({
        JustificationTable: true,
    });

    const getPageLink = (comparativeEntity) => {
        const comparativeEntitySlug = comparativeEntity
            ? comparativeEntity.name
            : undefined;

        return generatePath(ROUTES.RESULTS.path, {
            language: match.params.language,
            tab: TOOLTYPES.JUSTIFICATION.slug,
            comparativeEntity: encodeURIComponent(comparativeEntitySlug),
        });
    };

    /**
     * Returns the amount of table columns.
     * @returns {number|null}
     */
    const getColSpan = () => {
        try {
            const answerType = statement.answer_type;
            return answerType.options_amount;
        } catch (e) {
            return null;
        }
    };

    /**
     * Renders the headers.
     * @return {JSX.Element|null}
     */
    const renderHeaders = () => {
        if (!statement) {
            return null;
        }

        const answerType = statement.answer_type;
        const optionAmount = answerType.options_amount;
        const headers = new Array(optionAmount).fill(null).map((v, index) => {
            const answer = answerType[`answer_${index + 1}`];
            return answer.text_content;
        });

        return (
            <tr className="JustificationTable__row">
                {headers.map((header, index) => (
                    <th key={index} className="JustificationTable__cell">
                        <Text align="center" size="big" color="black">
                            {header}
                        </Text>
                    </th>
                ))}
            </tr>
        );
    };

    /**
     * Renders the statement.
     * @return {JSX.Element|null}
     */
    const renderStatement = () => {
        if (!statement) {
            return null;
        }

        return (
            <tr key={statement.id} className="JustificationTable__row">
                {renderAnswers()}
            </tr>
        );
    };

    /**
     * Renders the answers.
     * @return {JSX.Element[]|null}
     */
    const renderAnswers = () => {
        if (!statement) {
            return null;
        }

        const answerType = statement.answer_type;

        return new Array(getColSpan()).fill(null).map((v, index) => {
            const answer = answerType[`answer_${index + 1}`];
            return (
                <td key={index} className="JustificationTable__cell">
                    {renderComparativeEntities(answer)}
                </td>
            );
        });
    };

    /**
     * Renders the comparative entities.
     * @param {Object} answer
     * @return {JSX.Element}
     */
    const renderComparativeEntities = (answer) => {
        return (
            <div className="JustificationTable__comparative-entities">
                {/* Comparative entities. */}
                {comparativeEntities
                    .filter((comparativeEntity) => {
                        const comparativeEntityAnswer =
                            getComparativeEntityAnswer(
                                comparativeEntity,
                                statement.id
                            );
                        return (
                            comparativeEntityAnswer &&
                            comparativeEntityAnswer.value === answer.value
                        );
                    })
                    .map((comparativeEntity) => (
                        <CompassParty
                            key={`${comparativeEntity.name}_${comparativeEntity.regional_or_national}`}
                            active={
                                comparativeEntity ===
                                activeComparativeEntityState
                            }
                            onClick={() => {
                                setActiveComparativeEntityState(
                                    comparativeEntity
                                );
                                history.push(getPageLink(comparativeEntity));
                            }}
                            name={comparativeEntity.name}
                            size="44px"
                            src={comparativeEntity.logo}
                        />
                    ))}

                {/* User position. */}
                {parseInt(answer.value) === parseInt(statement.value) && (
                    <CompassParty
                        size="44px"
                        src={tool.result_configuration.your_answer_icon}
                    />
                )}
            </div>
        );
    };

    /**
     * Renders the justifications.
     * @param {Object} statement
     * @return {JSX.Element[]|null}
     */
    const renderJustifications = () => {
        return (
            <tr className="JustificationTable__row">
                <td className="JustificationTable__cell" colSpan={getColSpan()}>
                    <div className="JustificationTable__justification">
                        {/* Default state (no comparative entity selected. */}
                        {!activeComparativeEntityState && (
                            <SubCaption align="center">
                                {
                                    tool.result_configuration
                                        .compare_justification_page_configuration
                                        .compare_comparative_entity_explanation
                                }
                            </SubCaption>
                        )}

                        {/* Active comparative entity selected, render close button. */}
                        {activeComparativeEntityState && (
                            <Button
                                style="transparent"
                                onClick={() =>
                                    setActiveComparativeEntityState(null)
                                }
                            >
                                <Icon icon="close" />
                                {tool.general_texts.close}
                            </Button>
                        )}

                        {/* Active comparative entity selected but no justification(s) given.. */}
                        {activeComparativeEntityState &&
                            !getComparativeEntityJustifications(
                                activeComparativeEntityState,
                                statement.id
                            ).length && (
                                <SubCaption align="center">
                                    {
                                        tool.result_configuration
                                            .compare_justification_page_configuration
                                            .no_party_justification
                                    }
                                </SubCaption>
                            )}

                        {/* Active comparative entity selected and justification(s) given.. */}
                        {activeComparativeEntityState &&
                            getComparativeEntityJustifications(
                                activeComparativeEntityState,
                                statement.id
                            ).map((justification, index) => (
                                <React.Fragment key={index}>
                                    {renderJustificationTitle(justification)}
                                    {renderJustificationText(justification)}
                                    {renderJustificationOrigin(justification)}
                                </React.Fragment>
                            ))}
                    </div>
                </td>
            </tr>
        );
    };

    /**
     * Renders the justification title
     * @returns {JSX.Element|null}
     */
    const renderJustificationTitle = (justification) => {
        if (!justification || !justification.origin) {
            return null;
        }

        return <SubCaption align="side">{justification.origin}</SubCaption>;
    };

    /**
     * Renders the justification text.
     * @param justification
     * @returns {JSX.Element|null}
     */
    const renderJustificationText = (justification) => {
        if (!justification || !justification.justification) {
            return null;
        }
        return <Text>{justification.justification}</Text>;
    };

    /**
     * Renders the justification's origin.
     * @param {Object} justification
     * @returns {JSX.Element|null}
     */
    const renderJustificationOrigin = (justification) => {
        if (!justification || !justification.link) {
            return null;
        }

        return (
            <Text>
                <Chip href={justification.link}>
                    {
                        tool.result_configuration
                            .compare_justification_page_configuration
                            .source_button
                    }
                </Chip>
            </Text>
        );
    };

    return (
        <div className={className} {...props}>
            <table className="JustificationTable__table">
                <thead className="JustificationTable__head">
                    {renderHeaders()}
                </thead>

                <tbody className="JustificationTable__body">
                    {renderStatement()}
                    {renderJustifications()}
                </tbody>
            </table>
        </div>
    );
};

JustificationTable.propTypes = {
    /** The active comparative entity. */
    activeComparativeEntity: PropTypes.object,

    /** The comparative entities. */
    comparativeEntities: PropTypes.array,

    /** Result of the useHistory() hook. */
    history: PropTypes.object,

    /** The route match. */
    match: PropTypes.object,

    /** The statement. */
    statement: PropTypes.object,
};

JustificationTable.defaultProps = {
    activeComparativeEntity: null,
    comparativeEntities: [],
    history: null,
    match: null,
    statement: null,
    tool: {},
};
