/**
 * Deep clones value using JSON.parse/JSON.stringify.
 * @param {*} value
 * @return {*}
 */
import { isDevelopmentEnvironment } from "../data/api";

export const clone = (value) => JSON.parse(JSON.stringify(value));

/**
 * Gets path from object return null if not found.
 * @param {Object} object
 * @param {string} path dot (.) separated string representing the path to a key in object.
 * @param {*} fallback The fallback value.
 * @param {boolean} showPath Whether to use path as fallback value (in development only).
 * @return {*|null}
 */
export const deepGet = (object, path, fallback = null, showPath = false) => {
    const _showPath = showPath && isDevelopmentEnvironment();
    const _fallback = _showPath ? path : fallback;
    const keys = path.split(".");

    try {
        const value = keys.reduce((object, key) => object[key], object);

        if (typeof value !== "undefined") {
            return value;
        }
    } catch (e) {}

    return _fallback;
};
