import React, { useState } from "react";
import { useHistory, generatePath } from "react-router-dom";
import { Row } from "../../components/layout/Row";
import { Col } from "../../components/layout/Col";
import { Card } from "../../components/card/Card";
import { CardBody } from "../../components/card/CardBody";
import { Body } from "../../components/typography/Body";
import { Button } from "../../components/button/Button";
import { ButtonLink } from "../../components/button/ButtonLink";
import { bleach } from "../../lib/format";
import { isMobile } from "../../lib/responsive";
import { Logo } from "../../components/logo/Logo";
import { Sponsors } from "../../components/sponsors/Sponsors";
import { Title } from "../../components/typography/Title";
import { SubTitle } from "../../components/typography/SubTitle";
import { Text } from "../../components/typography/Text";
import { ROUTES } from "../../routes";
import { PrivacyPopup } from "./PrivacyPopup";
import { deepGet } from "../../lib/util";
import { Image } from "../../components/image/Image";
import { Divider } from "../../components/divider/Divider";

/**
 * Landing page.
 * @return {JSX.Element}
 */
export const LandingView = ({ ...props }) => {
    const history = useHistory();
    const [privacyPopupState, setPrivacyPopupState] = useState(false);

    /**
     * Returns the correct title for the device.
     * @return {string}
     */
    const getTitle = () => {
        if (isMobile()) {
            return props.toolState.start_page.mobile_intro;
        }
        return props.toolState.start_page.title;
    };

    /**
     * Returns the correct subtitle for the device.
     * @return {string}
     */
    const getSubtitle = () => {
        if (isMobile()) {
            return "";
        }
        return props.toolState.start_page.subtitle;
    };

    /**
     * Continues to the next route.
     * @param {string} [path]
     */
    const nextRoute = (
        path = generatePath(ROUTES.BACKGROUND_QUESTIONS.path, {
            language: props.match.params.language,
        })
    ) => {
        history.push(path);
    };

    /**
     * Button onClick handler.
     */
    const onButtonClick = () => {
        if (
            deepGet(props, "toolState.background_question_page.privacy_popup")
        ) {
            setPrivacyPopupState(true);
            return;
        }

        nextRoute();
    };

    /**
     * Renders the sponsor images.
     * @return {*}
     */
    const renderSponsors = () => {
        return props.toolState.general_style.sponsor_images.map(
            (sponsorImage, index) => {
                const href = sponsorImage.link;
                const alt = `${sponsorImage.name} - ${sponsorImage.link}`;
                return (
                    <Logo
                        key={index}
                        alt={alt}
                        href={href}
                        target="_blank"
                        src={sponsorImage.image}
                    />
                );
            }
        );
    };

    const height = 60;

    /**
     * Renders the main content.
     * @return {*}
     */
    const renderView = () => (
        <>
            <Row grow={true}>
                <Col
                    backgroundImage={
                        props.toolState.start_page.background_image
                    }
                    blur={props.toolState.start_page.background_image_blur}
                    compensatetop={true}
                    pad={false}
                    valign="center"
                >
                    <PrivacyPopup
                        active={privacyPopupState}
                        labelClose={deepGet(
                            props,
                            "toolState.general_texts.close"
                        )}
                        onClose={() => setPrivacyPopupState(false)}
                        onContinue={() => nextRoute()}
                        onSkip={() =>
                            nextRoute(
                                generatePath(ROUTES.STATEMENTS.path, {
                                    language: props.match.params.language,
                                })
                            )
                        }
                        page={deepGet(
                            props,
                            "toolState.background_question_page"
                        )}
                    />

                    <Card>
                        <CardBody>
                            <Body align="center" margin={false}>
                                <Title>{getTitle()}</Title>
                                <SubTitle>{getSubtitle()}</SubTitle>
                                <Button minwidth={true} onClick={onButtonClick}>
                                    {deepGet(
                                        props,
                                        "toolState.start_page.button_text"
                                    )}
                                </Button>
                                <Text
                                    dangerouslySetInnerHTML={{
                                        __html: bleach(
                                            props.toolState.general_texts
                                                .we_use_cookies_message,
                                            ["a"]
                                        ),
                                    }}
                                ></Text>
                                {props.toolState.extra_tool_url && (
                                    <>
                                        <Divider></Divider>
                                        <SubTitle
                                            fontsize={24}
                                            marginBottom={14}
                                            strong={true}
                                        >
                                            {
                                                props.toolState.start_page
                                                    .extra_tool_title
                                            }
                                        </SubTitle>
                                        <Text size="big" color="secondary">
                                            {
                                                props.toolState.start_page
                                                    .extra_tool_text
                                            }
                                        </Text>
                                        <ButtonLink
                                            href={
                                                props.toolState.extra_tool_url
                                            }
                                            size="normal"
                                            style="open"
                                        >
                                            <Image
                                                height={height}
                                                src={
                                                    props.toolState.start_page
                                                        .extra_tool_image
                                                }
                                                alt="Kieskompas met Steffie"
                                            />
                                            {
                                                props.toolState.start_page
                                                    .extra_tool_button_text
                                            }
                                        </ButtonLink>
                                    </>
                                )}
                            </Body>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Sponsors>{renderSponsors()}</Sponsors>
                </Col>
            </Row>
        </>
    );

    return renderView();
};
