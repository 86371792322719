import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Avatar } from "../avatar/Avatar";
import { ProgressBar } from "../progress-bar/ProgressBar";
import "./ranking.scss";

/**
 * Ranking is a title like component showing a certain (political) ranking.
 */
export const Ranking = ({ value, src, title, color, ...props }) => {
    const className = classNames({
        Ranking: true,
    });

    return (
        <div className={className} {...props}>
            <Avatar src={src} alt={title} size="big">
                {title}
            </Avatar>
            <ProgressBar
                value={value}
                max={100}
                color={color}
                background={true}
                showpercent={true}
            />
        </div>
    );
};

Ranking.propTypes = {
    /** Child components/content.  */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.element, PropTypes.string])
        ),
    ]),
    /** Value. */
    value: PropTypes.number,

    /** The src attribute of the desktop and fallback image. */
    src: PropTypes.string,

    /** The title. */
    title: PropTypes.string,

    /** Color. */
    color: PropTypes.string,
};

Ranking.defaultProps = {
    value: 1,
    color: "#1388c7",
};
