import React from "react";
import { Layout } from "../../components/layout/Layout";
import { Row } from "../../components/layout/Row";
import { Col } from "../../components/layout/Col";
import { Title } from "../../components/typography/Title";
import { Image } from "../../components/image/Image";
import { Body } from "../../components/typography/Body";
import { Link } from "../../components/typography/Link";
import { Text } from "../../components/typography/Text";

export const NotFoundView = ({ ...props }) => {
    const getTitle = () => {
        const fallback = "Pagina niet gevonden.";

        try {
            return props.toolState.general_texts.not_found_text || fallback;
        } catch (e) {
            return fallback;
        }
    };
    const getSubTitle = () => {
        const fallback = "Terug naar startpagina.";

        try {
            return props.toolState.general_texts.back_to_begin_text || fallback;
        } catch (e) {
            return fallback;
        }
    };

    return (
        <Layout>
            <Row grow={true}>
                <Col valign="center">
                    <Body align="center">
                        <Title>{getTitle()}</Title>
                        <Image
                            alt={getTitle()}
                            src="/static/sad_smiley.png"
                            width={183}
                            height={100}
                        />
                        <Text>
                            <Link href="/">{getSubTitle()}</Link>
                        </Text>
                    </Body>
                </Col>
            </Row>
        </Layout>
    );
};
