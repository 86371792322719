import { CrudConsumer, CrudConsumerObject } from "consumerjs";
import { API_KEY, getApiBaseEndpoint } from "./api";

/**
 * StatementAnswer.
 */
class StatementAnswer extends CrudConsumerObject {}

/**
 * StatementAnswer consumer.
 */
export class StatementAnswerConsumer extends CrudConsumer {
    constructor(
        endpoint = `${getApiBaseEndpoint()}/statement-answers/`,
        objectClass = StatementAnswer,
        options = { csrfProtection: false }
    ) {
        super(endpoint, objectClass, options);

        this.addHeader("AUTHORIZATION", API_KEY);
    }

    /**
     * Sets the language.
     * @param {Object} language
     */
    setLanguage(language) {
        this.endpoint = `${getApiBaseEndpoint(language)}/statement-answers/`;
    }

    /**
     * Sets the session header.
     * @param {Session} session
     */
    setSession(session) {
        this.addHeader("x-sessionid", session.session);
    }
}
