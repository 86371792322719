import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./Skiplinks.scss";

/**
 * Renders skiplinks.
 */
export const Skiplinks = ({ skiplinks, ...props }) => {
    const className = classNames({
        Skiplinks: true,
    });

    /**
     * Renders the list items.
     */
    const renderListItems = () =>
        skiplinks.map(([targetId, label], index) => {
            return (
                <li key={targetId} className="Skiplinks__list-item">
                    <a
                        className="Skiplinks__link"
                        href={`#${targetId}`}
                        tabIndex={index + 1}
                    >
                        {label}
                    </a>
                </li>
            );
        });

    return (
        <nav className={className} {...props}>
            <ul className="Skiplinks__list">{renderListItems()}</ul>
        </nav>
    );
};

Skiplinks.propTypes = {
    /** skiplinks.  */
    skiplinks: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};

Skiplinks.defaultProps = {
    skiplinks: [],
};
