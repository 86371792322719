import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { isRtl } from "../../lib/responsive";
import "./slider.scss";

/**
 * Slider is a header component for Card.
 */
export const Slider = ({
    ariaLabel,
    id,
    name,
    value,
    min,
    max,
    disabled,
    ...props
}) => {
    const [stateValue, setStateValue] = useState(value);
    const elementRef = useRef();

    const className = classNames({
        Slider: true,
    });

    /**
     * Allow numeric input using keyboard.
     */
    useEffect(() => {
        const setValue = (e) => {
            const isSliderFocussed =
                elementRef.current === document.activeElement;
            const isChildFocussed = elementRef.current?.contains(
                document.activeElement
            );

            if (isChildFocussed) {
                elementRef.current?.focus();
            }

            const key = e.key;
            const keyInt = parseInt(key);

            const KEY_ARROW_LEFT = "ArrowLeft";
            const KEY_ARROW_RIGHT = "ArrowRight";

            if (!(isSliderFocussed || isChildFocussed)) {
                return;
            }

            // Numeric key, set as value.
            if (!isNaN(keyInt)) {
                updateValue(keyInt, e);
            }

            // Arrow left.
            if (key === KEY_ARROW_LEFT) {
                if (isRtl()) {
                    increaseValue(e);
                } else {
                    decreaseValue(e);
                }
            }

            // Arrow right.
            if (key === KEY_ARROW_RIGHT) {
                if (isRtl()) {
                    decreaseValue(e);
                } else {
                    increaseValue(e);
                }
            }
        };

        document.addEventListener("keyup", setValue);
        return () => document.removeEventListener("keyup", setValue);
    });

    /**
     * Returns a value for `id`.
     * @return {string|null}
     */
    const getId = () => {
        if (id) {
            return id;
        }

        if (name) {
            return `slider-${name}`;
        }

        return null;
    };

    const increaseValue = (e) => {
        const value = Math.min(stateValue + 1, max);
        updateValue(value, e);
    };

    const decreaseValue = (e) => {
        const value = Math.max(stateValue - 1, min);
        updateValue(value, e);
    };

    /**
     * Updates the value, calls onChange (if set)
     * @param {number} val
     * @param {Event} [e] If given, call props.onChange (if set) with Event e.
     */
    const updateValue = (val = value, e = null) => {
        const newValue = val === "" ? "" : parseInt(val);
        setStateValue(newValue);

        if (props.onChange && e) {
            props.onChange(e, newValue);
        }
    };
    useEffect(updateValue, [value, props]);

    /**
     * Sets the slider value based on click location.
     * @param {SyntheticEvent} e
     */
    const setSliderValueByMouseEvent = (e) => {
        let clientX = e.clientX;

        try {
            clientX = e.touches[0].clientX;
        } catch (error) {}

        const rect = e.target.getBoundingClientRect();
        const x = isRtl()
            ? Math.abs(clientX - rect.right)
            : clientX - rect.left;
        const width = e.target.clientWidth;
        const valueWidth = width / (max - min + 1);
        const sliderValue = Math.max(
            min,
            Math.min(max, Math.floor(x / valueWidth))
        );

        updateValue(sliderValue, e);
    };

    /**
     * Returns the labels of the slider.
     * @return {JSX.Element[]}
     */
    const getLabels = () => {
        return new Array(max - min + 1).fill().map((v, index) => {
            const labelClassName = classNames({
                "Slider__list-item": true,
                "Slider__list-item--active":
                    stateValue !== null && index + min === parseInt(stateValue),
            });
            return (
                <li key={index + min} className={labelClassName}>
                    <div aria-hidden={true}>{index + min}</div>
                </li>
            );
        });
    };

    return (
        <div
            ref={elementRef}
            className={className}
            onClick={setSliderValueByMouseEvent}
            onTouchMove={setSliderValueByMouseEvent}
            {...props}
        >
            <input
                id={getId()}
                className="Slider__input"
                type="range"
                disabled={disabled || stateValue === ""}
                name={name ? name : null}
                value={stateValue >= min ? stateValue : 0}
                min={min}
                max={max}
                onChange={(e) => {
                    e.stopPropagation();
                    updateValue(e.target.value);
                }}
                aria-label={ariaLabel}
            />

            <ol className="Slider__list">{getLabels()}</ol>
        </div>
    );
};

Slider.propTypes = {
    /** aria-label attribute. */
    ariaLabel: PropTypes.string,

    /** The id attribute of the input. */
    id: PropTypes.string,

    /** Whether to slider is disabled. */
    disabled: PropTypes.bool,

    /** The name attribute of the slider. */
    name: PropTypes.string,

    /** Current step number. */
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

    /** Minimum step number. */
    min: PropTypes.number,

    /** Maximum step number. */
    max: PropTypes.number,

    /** onChange callback. */
    onChange: PropTypes.func,
};

Slider.defaultProps = {
    id: null,
    disabled: false,
    name: "",
    value: 1,
    min: 1,
    max: 10,
    onChange: null,
};
