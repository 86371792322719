import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./link.scss";

/**
 * A link is used to navigate to a specified page.
 */
export const Link = ({ href, target, ...props }) => {
    const className = classNames({
        Link: true,
    });

    return (
        <a className={className} href={href || "#"} target={target} {...props}>
            {props.children}
        </a>
    );
};

Link.propTypes = {
    /** Child components/content.  */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.element, PropTypes.string])
        ),
    ]),

    /** The href attribute of the link. */
    href: PropTypes.string,

    /** The target attribute of the link. */
    target: PropTypes.string,
};

Link.defaultProps = {
    href: "",
    target: "_self",
};
