import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./wysiwyg.scss";

/**
 * Renders arbitrary HTML using dangerouslySetInnerHTML, applying basic styling.
 */
export const Wysiwyg = ({
    align,
    centered,
    margin,
    pad,
    size,
    children,
    ...props
}) => {
    const className = classNames({
        Wysiwyg: true,
        "Wysiwyg--centered": centered,
        "Wysiwyg--margin": margin,
        "Wysiwyg--pad": pad === true,
        [`Wysiwyg--pad-${pad}`]: typeof pad === "string",
        [`Wysiwyg--${align}`]: align,
        [`Wysiwyg--${size}`]: size,
    });

    /**
     * Returns the correct structure to use with dangerouslySetInnerHTML.
     * @return {{__html: string}}
     */
    const getHTML = () => ({
        __html: children,
    });

    return (
        <div
            className={className}
            {...props}
            dangerouslySetInnerHTML={getHTML()}
        ></div>
    );
};

Wysiwyg.propTypes = {
    /** Child components/content.  */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.element, PropTypes.string])
        ),
    ]),

    /** The alignment based on the text direction (LTR/RTL). */
    align: PropTypes.oneOf(["side", "center"]),

    /** Whether to center the wysiwyg container. */
    centered: PropTypes.bool,

    /** Whether to apply margin to content elements. */
    margin: PropTypes.bool,

    /** Whether to apply horizontal padding. */
    pad: PropTypes.oneOf([true, false, "extra"]),

    /** Size preset. */
    size: PropTypes.oneOf(["normal", "big", "small"]),
};

Wysiwyg.defaultProps = {
    align: "side",
    centered: true,
    margin: true,
    pad: true,
    size: "normal",
};
