/**
 * Returns all unique theme names in statements.
 * @param {Object[]} statements
 * @return {string[]}
 */
export const getThemesByStatements = (statements) => {
    let mainArray = statements.map((statement) => {
        return {
            id: statement.theme.id,
            name: statement.theme.display_name || statement.theme.description,
        };
    });

    const result = [];
    const map = new Map();
    for (const item of mainArray) {
        if (!map.has(item.id)) {
            map.set(item.id, true); // set any value to Map
            result.push({
                id: item.id,
                name: item.name,
            });
        }
    }
    return result;
};

/**
 * Returns choices which can be used as "options" prop for Select.
 * @param {Object[]} statements
 * @param {boolean} useThemeIdAsValue Whether to use the theme id as choice value, false is considered legacy.
 * @return {Array[]}
 */
export const getThemeChoicesByStatements = (
    statements,
    useThemeIdAsValue = false
) =>
    getThemesByStatements(statements).map((theme) => [
        useThemeIdAsValue ? String(theme.id) : theme.name,
        theme.name,
    ]);

/**
 * Returns statements filtered by theme name.
 * @param {Object[]} statements
 * @param {string} theme
 * @return {Object[]}
 */
export const filterStatementsByTheme = (statements, theme) =>
    statements.filter(
        (statement) =>
            statement.theme.display_name === theme ||
            statement.theme.description === theme
    );

/**
 * Returns statements filtered by theme name.
 * @param {Object[]} statements
 * @param {string[]} themes
 * @return {Object[]}
 */
export const filterStatementsByThemes = (statements, themes) => {
    if (themes) {
        return statements.filter((statement) => {
            let toMatch =
                statement.theme.display_name || statement.theme.description;
            let found = themes.find((theme) => theme.name === toMatch);
            return !!found;
        });
    }
    return statements;
};
