import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./layout.scss";

/**
 * Layout should be the top level visible component that can contain one or more Row components.
 */
export const Layout = ({ backgroundImage, debug, fill, scroll, ...props }) => {
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    window.addEventListener("resize", () =>
        setWindowHeight(window.innerHeight)
    );

    const className = classNames({
        Layout: true,
        "Layout--debug": debug,
        "Layout--fill": fill,
        "Layout--scroll": scroll,
    });

    const getStyle = () => {
        return {
            backgroundImage: backgroundImage ? `url(${backgroundImage})` : null,
            height: `${windowHeight}px`,
        };
    };

    return (
        <main className={className} {...props} style={getStyle()}>
            {props.children}
        </main>
    );
};

Layout.propTypes = {
    /** Child components/content.  */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.element, PropTypes.string])
        ),
    ]),

    /** URL of a background image to show. */
    backgroundImage: PropTypes.string,

    /** Whether to enable debug styling. */
    debug: PropTypes.bool,

    /** Whether to fill the background with a color. */
    fill: PropTypes.bool,

    /** Whether to allow scrolling. */
    scroll: PropTypes.bool,
};

Layout.defaultProps = {
    backgroundImage: "",
    debug: false,
    fill: false,
    scroll: false,
};
