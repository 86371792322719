import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./button.scss";

/**
 * Buttons provide a clickable element, which can be used in forms, or anywhere that needs simple, standard button
 * functionality. They may display text, icons, or both. Buttons can be styled with several attributes to look a
 * specific way.
 */
export const Button = ({
    active,
    disabled,
    type,
    style,
    styleOnHover,
    name,
    value,
    direction,
    minwidth,
    pad,
    size,
    wrap,
    ...props
}) => {
    const [hoverState, setHoverState] = useState(false);

    const className = classNames({
        Button: true,
        "Button--active": active,
        "Button--min-width": minwidth,
        "Button--pad": pad,
        "Button--wrap": wrap,
        "Button--nowrap": !wrap,
        [`Button--${size}`]: size,
        [`Button--${direction}`]: direction,
        [`Button--${typeof style === "object" ? "styled" : style}`]: style,
    });

    const getStyle = () => {
        if (typeof style !== "object") {
            return null;
        }

        if (styleOnHover) {
            if (hoverState) {
                return style;
            }
            return null;
        }

        return style;
    };

    return (
        <button
            className={className}
            disabled={disabled}
            type={type}
            name={name}
            value={value}
            style={getStyle()}
            onMouseEnter={() => setHoverState(true)}
            onMouseLeave={() => setHoverState(false)}
            {...props}
        >
            {props.children}
        </button>
    );
};

Button.propTypes = {
    /** Child components/content.  */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.element, PropTypes.string])
        ),
    ]),

    /** The active state of the button. */
    active: PropTypes.bool,

    /** The disabled state of the button. */
    disabled: PropTypes.bool,

    /** The name attribute of the button. */
    name: PropTypes.string,

    /** The type attribute of the button. */
    type: PropTypes.oneOf(["button", "submit", "reset"]),

    /** The value attribute of the button. */
    value: PropTypes.string,

    /** The style of the button, can be "primary", "secondary", "open" or a React style object. */
    style: PropTypes.oneOfType([
        PropTypes.oneOf([
            "primary",
            "primary-contrast",
            "secondary",
            "tertiary",
            "open",
            "open-secondary",
            "open-tertiary",
            "transparent",
        ]),
        PropTypes.object,
    ]),

    /** If a style objects is assigned to style, only apply it on hover. */
    styleOnHover: PropTypes.bool,

    /** The content direction. */
    direction: PropTypes.oneOf(["horizontal", "vertical"]),

    /** Whether the button should have a minimum width. */
    minwidth: PropTypes.bool,

    /** Whether the button should have padding. */
    pad: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),

    /** Size preset. */
    size: PropTypes.oneOf(["big", "normal"]),

    /** Whether to wrap text. */
    wrap: PropTypes.bool,
};

Button.defaultProps = {
    active: false,
    disabled: false,
    type: "button",
    style: "primary",
    styleOnHover: false,
    name: null,
    value: null,
    direction: "horizontal",
    minwidth: false,
    pad: true,
    size: "big",
    wrap: true,
};
