import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Label } from "./Label";
import { getId } from "../../lib/id";
import "./toggle.scss";

/**
 * Toggles change the state of a single option. Toggles can be switched on or off by pressing or them. They can also be
 * checked programmatically by setting the checked property.
 */
export const Toggle = ({
    checked,
    id,
    name,
    value,
    stretch,
    tabIndex,
    onChange,
    ...props
}) => {
    const [idState, setIdState] = useState();
    const [checkedState, setCheckedState] = useState(checked);
    useEffect(() => setIdState(getId(id)), [id]);
    useEffect(() => setCheckedState(checked), [checked]);

    const className = classNames({
        Toggle: true,
        "Toggle--stretch": stretch,
    });

    const onInputChange = (e) => {
        setCheckedState(!checkedState);
        onChange(e, !checkedState);
    };

    return (
        <div className={className} {...props}>
            <Label htmlFor={idState}>{props.children}</Label>
            <input
                className="Toggle__input"
                id={idState}
                checked={checkedState}
                name={name ? name : null}
                defaultValue={value ? value : null}
                type="checkbox"
                tabIndex={tabIndex}
                onChange={onInputChange}
            />
            <label className="Toggle__toggle" htmlFor={idState}>
                <span className="Toggle__checkmark"></span>
            </label>
        </div>
    );
};

Toggle.propTypes = {
    /** Child components/content.  */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.element, PropTypes.string])
        ),
    ]),

    /** The id attribute of the described element. */
    checked: PropTypes.bool,

    /** The id attribute of the toggle. */
    id: PropTypes.string,

    /** The name attribute of the toggle. */
    name: PropTypes.string,

    /** The value attribute of the toggle. */
    value: PropTypes.string,

    /** Whether to stretch horizontally. */
    stretch: PropTypes.bool,

    /** Tabindex value. */
    tabIndex: PropTypes.number,

    /** The "onChange" callback. */
    onChange: PropTypes.func,
};

Toggle.defaultProps = {
    checked: false,
    id: "",
    name: "",
    value: "",
    stretch: true,
    tabIndex: null,
    onChange: () => {},
};
