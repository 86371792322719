import React, { useCallback, useEffect, useState } from "react";
import { generatePath, useHistory } from "react-router-dom";
import { Avatar } from "../../components/avatar/Avatar";
import { Button } from "../../components/button/Button";
import { ButtonLink } from "../../components/button/ButtonLink";
import { renderDownloadCompass } from "../../components/compass/Compass";
import { Form } from "../../components/form/Form";
import { Icon } from "../../components/icon/Icon";
import { IconButton } from "../../components/icon-button/IconButton";
import { InfoButton } from "../../components/info-button/InfoButton";
import { Col } from "../../components/layout/Col";
import { Row } from "../../components/layout/Row";
import { Modal } from "../../components/modal/Modal";
import { Panel } from "../../components/panel/Panel";
import { Toast } from "../../components/toast/Toast";
import { Toolbar } from "../../components/toolbar/Toolbar";
import { Body } from "../../components/typography/Body";
import { SubTitle } from "../../components/typography/SubTitle";
import { Text } from "../../components/typography/Text";
import { ComparativeEntityConsumer } from "../../data/comparitive_entity";
import { PopupQuestionAnswerConsumer } from "../../data/popup_question_answer";
import { bleach, camelCaseToSnakeCase } from "../../lib/format";
import { isMobile } from "../../lib/responsive";
import { getThemesByStatements } from "../../lib/statement";
import { ROUTES } from "../../routes";
import { AbstractQuestionsView } from "../abstract/AbstractQuestionsView";
import { renderFacebook } from "./renderFacebook";
import { renderHeaderButtons, renderToolIcon } from "./renderHeaderButtons";
import { renderTwitter } from "./renderTwitter";
import { TOOLTYPES } from "./constants";
import { deepGet } from "../../lib/util";
import { renderMoreTools } from "./renderMoreTools";
import { renderMoreToolsModal } from "./renderMoreToolsModal";
import "./results-view.scss";
import { getNearestComparativeEntity } from "../../lib/score/euclidean_distance";

/**
 * Combines popup questions/privacy popup state machine.
 * @param toolState
 * @return {*[]} popupQuestionsActiveState, privacyPopupActiveState, setPopupQuestionsActiveState, setPrivacyPopupState
 */
const usePopupQuestionsState = (toolState, initial = false) => {
    const [popupQuestionsActiveState, _setPopupQuestionsActiveState] =
        useState(initial);
    const [popupQuestionsCompletedState, setPopupQuestionsCompletedState] =
        useState(false);
    const [popupQuestionsMandatoryState, setPopupQuestionsMandatoryState] =
        useState(
            deepGet(toolState, "result_configuration.popup_question_mandatory")
        );
    const [privacyPopupActiveState, setPrivacyPopupState] = useState(initial);
    const [timeoutSet, setTimeoutSet] = useState(initial);

    const setPopupQuestionsActiveState = (value) => {
        setPrivacyPopupState(false);
        _setPopupQuestionsActiveState(value);
    };

    /**
     * Show privacy popup after `result_configuration.popup_question_timeout` seconds.
     */
    useEffect(() => {
        // Popup already shown.
        if (timeoutSet) {
            return;
        }

        // Set timeout.
        const timeout = setTimeout(() => {
            if (popupQuestionsCompletedState) {
                return;
            }

            deepGet(toolState, "popup_question_page.privacy_popup")
                ? setPrivacyPopupState(true)
                : setPopupQuestionsActiveState(true);

            setTimeoutSet(true); // Prevent multiple privacy popups.
        }, toolState.result_configuration.popup_question_timeout * 1000);

        // Clean up function.
        return () => clearTimeout(timeout);
    }, [toolState, popupQuestionsCompletedState, timeoutSet]);

    return [
        popupQuestionsActiveState,
        popupQuestionsCompletedState,
        privacyPopupActiveState,
        setPopupQuestionsActiveState,
        setPopupQuestionsCompletedState,
        setPrivacyPopupState,
        popupQuestionsMandatoryState,
        setPopupQuestionsMandatoryState,
    ];
};

/**
 * The privacy popup asking the user to fill in popup questions.
 */
const PrivacyPopup = ({ active, onClose, onContinue, onSkip, toolState }) => {
    if (
        !deepGet(toolState, "popup_question_page.popupquestion_set.length") ||
        !deepGet(toolState, "popup_question_page.privacy_popup")
    ) {
        return null;
    }

    /**
     * Returns the title.
     * @return {string}
     */
    const getTitle = () => {
        return deepGet(
            toolState,
            "popup_question_page.privacy_popup.title",
            ""
        );
    };

    /**
     * Returns the body.
     * @return {string}
     */
    const getBody = () => {
        return deepGet(
            toolState,
            "popup_question_page.privacy_popup.text",
            ""
        );
    };

    /**
     * Returns the footnotes.
     * @return {string}
     */
    const getFootnotes = () => {
        return bleach(
            deepGet(toolState, "popup_question_page.privacy_popup.small_text"),
            ["a"]
        );
    };

    return (
        <Modal
            active={active}
            size="big"
            dark={true}
            allowclose={true}
            labelClose={deepGet(toolState, "general_texts.close")}
            onClose={onClose}
        >
            <Body align="center">
                <SubTitle>{getTitle()}</SubTitle>
                <Text>{getBody()}</Text>

                <Toolbar>
                    <Button
                        minwidth={true}
                        style="primary"
                        onClick={onContinue}
                    >
                        {
                            toolState.popup_question_page.privacy_popup
                                .continue_button
                        }
                    </Button>

                    <Button minwidth={true} style="open" onClick={onSkip}>
                        {
                            toolState.popup_question_page.privacy_popup
                                .skip_button
                        }
                    </Button>
                </Toolbar>

                <Text size="small" html={getFootnotes()} />
            </Body>
        </Modal>
    );
};

/**
 * Popup questions allowing the user to provide more information.
 */
const PopupQuestions = ({
    active,
    allowClose = false,
    match,
    popupQuestionsState,
    sessionState,
    toolState,
    onChange,
    onClose,
    onSubmit,
    ...props
}) => {
    if (!deepGet(toolState, "popup_question_page.popupquestion_set.length")) {
        return null;
    }

    /**
     * Returns the title.
     * @return {string}
     */
    const getTitle = () => {
        return deepGet(toolState, "popup_question_page.title", "");
    };

    /**
     * Returns form inputs.
     * @param {Object[]} source
     * @return {Object[]}
     */
    const getFormInputs = (source = popupQuestionsState) => source;

    /**
     * Returns form actions.
     * @return {Object[]}
     */
    const getFormActions = () => [
        {
            label: deepGet(
                toolState,
                "popup_question_page.submit_button_text",
                ""
            ),
            onClick: onFormSubmit,
        },
    ];

    /**
     * Form submit hook.
     */
    const onFormSubmit = () => {
        // Get answers.
        const answeredPopupQuestions = popupQuestionsState
            .filter((question) => question.value)
            .map((question) => ({
                question: question.id,
                value: question.value,
            }));

        // Send answers to API.
        const popupAnswerConsumer = new PopupQuestionAnswerConsumer();
        popupAnswerConsumer.setSession(sessionState);
        popupAnswerConsumer
            .create(answeredPopupQuestions)
            .then((...args) => {
                onSubmit(...args);
                onClose(...args);
            })

            // TODO: clean up.
            .catch((error) => {
                if (error.statusCode === 403) {
                    let parsedMessage = JSON.parse(
                        error.statusText.response.data
                    );
                    if (parsedMessage.code === "session_expired") {
                        window.history.push(
                            generatePath(ROUTES.RESTART.path, {
                                language: match.params.language,
                            })
                        );
                    } else {
                        props.setErrorState(error);
                        onClose();
                    }
                } else {
                    props.setErrorState(error);
                    onClose();
                }
            });
    };

    return (
        <Modal
            active={active}
            allowclose={allowClose}
            dark={allowClose}
            filled={!allowClose}
            labelClose={deepGet(toolState, "general_texts.close")}
            position="float"
            size={allowClose ? "big" : "fullscreen"}
            iconProps={
                allowClose
                    ? {
                          icon: "baret",
                          background: "#47D09B",
                          color: "#FFF",
                          label: deepGet(
                              toolState,
                              "social_tool_setting.more_questions",
                              ""
                          ),
                      }
                    : null
            }
            title={getTitle()}
            onClose={onClose}
        >
            <Form
                intro={deepGet(toolState, "popup_question_page.small_text")}
                inputs={getFormInputs()}
                actions={getFormActions()}
                emptyLabel={deepGet(
                    toolState,
                    "general_texts.pick_your_answer"
                )}
                pad={true}
                style={allowClose ? "vertical" : "horizontal"}
                size={allowClose ? "normal" : "big"}
                onChange={onChange}
            />
        </Modal>
    );
};

/**
 * Results page.
 * @return {JSX.Element}
 */
export const ResultsView = ({
    match,
    statementsState,
    backgroundQuestionsState,
    sessionState,
    languageState,
    popupQuestionsState,
    setPopupQuestionsState,
    toolState,
    setErrorState,
    ...props
}) => {
    const history = useHistory();
    /* eslint-disable no-unused-vars */
    const [
        popupQuestionsActiveState,
        popupQuestionsCompletedState,
        privacyPopupActiveState,
        setPopupQuestionsActiveState,
        setPopupQuestionsCompletedState,
        setPrivacyPopupState,
        popupQuestionsMandatoryState,
        setPopupQuestionsMandatoryState,
    ] = usePopupQuestionsState(toolState, false);
    /* eslint-enable no-unused-vars */

    /**
     * Renders the privacy popup.
     */
    const renderPrivacyPopup = () => {
        return (
            <PrivacyPopup
                active={privacyPopupActiveState}
                toolState={toolState}
                onClose={() => setPrivacyPopupState(false)}
                onContinue={() => setPopupQuestionsActiveState(true)}
                onSkip={() => setPrivacyPopupState(false)}
            />
        );
    };

    /**
     * Renders the popup questions.
     */
    const renderPopupQuestions = () => {
        return (
            <PopupQuestions
                active={popupQuestionsActiveState}
                allowClose={!popupQuestionsMandatoryState}
                match={match}
                popupQuestionsState={popupQuestionsState}
                sessionState={sessionState}
                toolState={toolState}
                onChange={(e, formState) => setPopupQuestionsState(formState)}
                onClose={() => {
                    setPopupQuestionsMandatoryState(false);
                    setPopupQuestionsActiveState(false);
                }}
                onSubmit={() => setPopupQuestionsCompletedState(true)}
            />
        );
    };

    //
    // State machines.
    //

    const [activeStatementState, setActiveStatementState] = useState(
        statementsState.length ? statementsState[0] : null
    );
    const [activeThemesState, setActiveThemesState] = useState(
        getThemesByStatements(statementsState)
    );
    const [collapsedSetState, setCollapsedSetState] = useState(false);
    const [collapsedState, setCollapsedState] = useState(false);
    const [compassSvg, setCompassSvg] = useState();
    const [comparativeEntityConsumer] = useState(
        new ComparativeEntityConsumer()
    );
    const [comparativeEntitiesState, setComparativeEntitiesState] = useState(
        []
    );
    const [activeComparativeEntitiesState, setActiveComparativeEntitiesState] =
        useState([]);
    const [activeComparativeEntityState, setActiveComparativeEntityState] =
        useState(null);
    const [moreToolsActiveState, setMoreToolsActiveState] = useState(false);
    const [nearestComparativeEntityState, setNearestComparativeEntityState] =
        useState();

    /**
     * Returns the current tool type.
     * @returns {Object|null}
     */
    const getToolType = useCallback(() => {
        const tabs = [
            toolState.first_tab,
            toolState.second_tab,
            toolState.third_tab,
            toolState.fourth_tab,
            toolState.fifth_tab,
        ];

        const toolType = Object.values(TOOLTYPES).find(
            (toolType) => toolType.slug === match.params.tab
        );

        if (!toolType || tabs.indexOf(toolType.slug) === -1) {
            return null;
        }

        return toolType;
    }, [match, toolState]);

    /**
     * Returns the initial collapsed state for the secondary sidebar.
     * @return {boolean}
     */
    const getInitialCollapsed = useCallback(() => {
        const toolType = getToolType();

        if (!toolType) {
            return false;
        }

        return getToolType().initialCollapsed || isMobile();
    }, [getToolType]);

    //
    // Effects.
    //

    /**
     * Set initial collapsed value.
     */
    useEffect(() => {
        setCollapsedState(getInitialCollapsed());
    }, [toolState, getInitialCollapsed]);

    /**
     * Redirect to first tab if not set.
     */
    useEffect(() => {
        if (!match.params.tab) {
            const firstTab = deepGet(toolState, "first_tab", "");

            if (!firstTab) {
                return;
            }

            history.push(
                generatePath(ROUTES.RESULTS.path, {
                    language: match.params.language,
                    tab: toolState.first_tab,
                })
            );
        }
    }, [toolState, history, match.params.language, match.params.tab]);

    /**
     * Fetch comparative entities, set error on failure.
     * To prevent possible infinite loops, `setErrorState` is omitted from dependency array.
     */
    useEffect(() => {
        comparativeEntityConsumer.setSession(sessionState);
        comparativeEntityConsumer
            .read()
            .then((comparativeEntities) => {
                if (
                    toolState.result_configuration
                        .apply_background_question_filter
                ) {
                    let oldComparativeEntities = comparativeEntities;
                    comparativeEntities = comparativeEntities.filter(
                        (comparativeEntity) => {
                            let passesFilter = true;
                            comparativeEntity.background_question_answer_options.forEach(
                                (option) => {
                                    if (backgroundQuestionsState.length < 1) {
                                        return true;
                                    }
                                    backgroundQuestionsState.forEach(
                                        (backgroundQuestion) => {
                                            if (
                                                backgroundQuestion.id ===
                                                option.question
                                            ) {
                                                passesFilter =
                                                    backgroundQuestion.value ===
                                                    option.option;
                                            }
                                        }
                                    );
                                }
                            );
                            return passesFilter;
                        }
                    );
                    if (comparativeEntities.length === 0) {
                        comparativeEntities = oldComparativeEntities;
                    }
                }
                return setComparativeEntitiesState(comparativeEntities);
            })

            // TODO: clean up.
            .catch((error) => {
                if (error.statusCode === 403) {
                    let parsedMessage = JSON.parse(
                        error.statusText.response.data
                    );
                    if (parsedMessage.code === "session_expired") {
                        history.push(
                            generatePath(ROUTES.RESTART.path, {
                                language: match.params.language,
                            })
                        );
                    } else {
                        setErrorState(error);
                    }
                } else {
                    setErrorState(error);
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        backgroundQuestionsState,
        comparativeEntityConsumer,
        history,
        match.params.language,
        sessionState,
        toolState,
    ]);

    /**
     * Set active comparative entities when comparative entities are updated.
     */
    useEffect(() => {
        setActiveComparativeEntitiesState(comparativeEntitiesState);
    }, [comparativeEntitiesState]);

    /**
     * Sets the (initial) active comparative entity.
     */
    useEffect(() => {
        const activeComparativeEntityName = decodeURIComponent(
            match.params.comparativeEntity
        );
        const activeComparativeEntity = comparativeEntitiesState.find(
            (c) => c.name === activeComparativeEntityName
        );
        setActiveComparativeEntityState(activeComparativeEntity);
    }, [match, comparativeEntitiesState]);

    /**
     * Calculate/show nearest comparative entity (based on compass)
     * TODO: clean up.
     */
    useEffect(() => {
        if (!comparativeEntitiesState.length) {
            return;
        }

        const nearestComparativeEntity = getNearestComparativeEntity(
            statementsState,
            comparativeEntitiesState
        );
        setNearestComparativeEntityState(nearestComparativeEntity);
    }, [comparativeEntitiesState, statementsState]);

    /**
     * Update themes when statements change.
     */
    useEffect(() => {
        setActiveThemesState(getThemesByStatements(statementsState));
    }, [statementsState]);

    //
    // Page functions.
    //

    /**
     * Returns the current results configuration object.
     * @returns {Object|null}
     */
    const getPage = () => {
        const toolType = getToolType();

        if (!toolType) {
            return null;
        }

        const key = `${camelCaseToSnakeCase(toolType.slug)}_configuration`;
        return toolState.result_configuration[key];
    };

    /**
     * Theme filter click hander.
     * @param {SyntheticEvent} e
     * @param {string[]} activeThemes
     */
    const onThemeFilterClick = (e, activeThemes) => {
        setActiveThemesState(activeThemes);
    };

    /**
     * Renders the sidebar.
     * @returns {JSX.Element|null}
     */
    const renderPrimarySidebar = () => {
        const toolType = getToolType();

        if (!toolType) {
            return null;
        }

        return (
            <Col span={2} overflow={true} width="sidebar-left">
                {renderInfoButton()}

                {toolType.renderSidebar({
                    activeStatementState,
                    activeThemesState,
                    activeComparativeEntitiesState,
                    comparativeEntitiesState,
                    statementsState,
                    sessionState,
                    setErrorState,
                    toolState,
                    match,
                    onComparativeEntityFilterClick: (e, comparativeEntity) => {
                        setActiveComparativeEntityState(comparativeEntity);
                        history.push(
                            generatePath(match.path, {
                                language: match.params.language,
                                tab: match.params.tab,
                                comparativeEntity: encodeURIComponent(
                                    comparativeEntity.name
                                ),
                            })
                        );
                    },
                    onStatementFilterChange: (e, statement) =>
                        setActiveStatementState(statement),
                    onThemeFilterClick: onThemeFilterClick,
                    onRangeFilterChange: (e, activeComparativeEntities) =>
                        setActiveComparativeEntitiesState(
                            activeComparativeEntities
                        ),
                })}
            </Col>
        );
    };

    /**
     * Renders the info button.
     * @return {JSX.Element|null}
     */
    const renderInfoButton = () => {
        if (!toolState.result_configuration.help_button) {
            return null;
        }

        return (
            <InfoButton
                active={false}
                title={toolState.result_configuration.help_button}
                tabIndex={200}
            >
                {getPage() && getPage().marker_explanation && (
                    <React.Fragment>
                        <Icon icon="pin" color="#FC4423" background="#FFF" />
                        <Text
                            align="side"
                            html={bleach(getPage().marker_explanation, ["a"])}
                        />
                    </React.Fragment>
                )}

                {getPage() && getPage().themes_explanation && (
                    <React.Fragment>
                        <Icon icon="themes" color="#0E70C2" background="#FFF" />
                        <Text
                            align="side"
                            html={bleach(getPage().themes_explanation, ["a"])}
                        />
                    </React.Fragment>
                )}

                {getPage() && getPage().icon_explanation && (
                    <React.Fragment>
                        {renderToolIcon(
                            getToolType().slug,
                            getPage().marker_explanation,
                            "#0E70C2",
                            "#FFF"
                        )}
                        <Text
                            align="side"
                            html={bleach(getPage().icon_explanation, ["a"])}
                        />
                    </React.Fragment>
                )}

                <Text>
                    <ButtonLink size={"normal"} style="primary-contrast">
                        {toolState.general_texts.back_button_text}
                    </ButtonLink>
                </Text>
            </InfoButton>
        );
    };

    /**
     * Renders the correct toolState.
     * @returns {JSX.Element|JSX.Element[]|null}
     */
    const renderTool = () => {
        const toolType = getToolType();

        if (!toolType) {
            history.push(
                `/${
                    languageState || toolState.primary_language.iso_code
                }/not_found`
            );
            return null;
        }

        const callback = (svg) => setCompassSvg(svg);

        const result = toolType.renderTool({
            activeStatementState,
            activeThemesState,
            activeComparativeEntitiesState,
            activeComparativeEntityState,
            setActiveComparativeEntityState,
            comparativeEntitiesState,
            languageState: languageState,
            statementsState: statementsState,
            toolState: toolState,
            callback, // FIXME: refactor to onReady???
            history: history,
            match: match,
        });
        return result;
    };

    /**
     * Renders the secondary sidebar.
     * @return {JSX.Element}
     */
    const renderSecondarySidebar = () => {
        return (
            <Col
                span={2}
                width="sidebar-right"
                collapsible={true}
                collapsed={collapsedSetState ? null : getInitialCollapsed()}
                onClick={() => {
                    setCollapsedSetState(true);
                    setCollapsedState(!collapsedState);
                }}
            >
                <Panel
                    align="space-around"
                    direction="vertical"
                    grow={true}
                    pad={false}
                >
                    {Boolean(
                        deepGet(
                            toolState,
                            "popup_question_page.popupquestion_set.length"
                        )
                    ) && (
                        <IconButton
                            icon="baret"
                            background="#47D09B"
                            color="#FFF"
                            label={toolState.social_tool_setting.more_questions}
                            pad={false}
                            onClick={() =>
                                toolState.popup_question_page.privacy_popup
                                    ? setPrivacyPopupState(
                                          !privacyPopupActiveState
                                      )
                                    : setPopupQuestionsActiveState(
                                          !popupQuestionsActiveState
                                      )
                            }
                            tabIndex={collapsedState ? -1 : 300}
                        />
                    )}

                    {renderTwitter(
                        toolState,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        collapsedState ? -1 : 301
                    )}
                    {renderFacebook(
                        toolState,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        collapsedState ? -1 : 302
                    )}

                    {compassSvg &&
                        renderDownloadCompass(
                            toolState,
                            compassSvg,
                            "#3A3F43",
                            "#FFF",
                            "normal",
                            false,
                            true,
                            collapsedState ? -1 : 303
                        )}

                    {renderMoreTools(
                        toolState,
                        () => setMoreToolsActiveState(!moreToolsActiveState),
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        collapsedState ? -1 : 304
                    )}
                </Panel>
            </Col>
        );
    };

    /**
     * Renders the modals.
     * @return {JSX.Element|null}
     */
    const renderModals = () => {
        return (
            <React.Fragment>
                {renderMoreToolsModal(
                    toolState,
                    () => setMoreToolsActiveState(false),
                    moreToolsActiveState
                )}
                {renderPrivacyPopup()}
                {renderPopupQuestions()}
            </React.Fragment>
        );
    };

    /**
     * Renders the required toasts.
     * @return {JSX.Element}
     */
    const renderToasts = () => {
        return (
            <>
                {renderComparativeEntityToast()}
                {renderMoreToolsToast()}
            </>
        );
    };

    /**
     * Renders "nearest comparative entity" toast.
     * @return {JSX.Element|null}
     */
    const renderComparativeEntityToast = () => {
        // Only show the toast when the compass is shown, as it uses the compass calculation to determine the "closest"
        // comparative entity.
        if (getToolType() !== TOOLTYPES.COMPASS) {
            return;
        }

        if (!nearestComparativeEntityState) {
            return null;
        }

        if (
            !toolState.result_configuration.show_closest_to ||
            !toolState.result_configuration.closest_comparative_entity_text
        ) {
            return null;
        }

        return (
            <Toast
                activationDelay={
                    toolState.result_configuration.show_closest_to_after * 1000
                }
                timeout={
                    toolState.result_configuration.hide_closest_to_after * 1000
                }
                labelClose={deepGet(toolState, "general_texts.close")}
            >
                <Avatar
                    color="primary-contrast"
                    src={nearestComparativeEntityState.logo}
                >
                    {
                        toolState.result_configuration
                            .closest_comparative_entity_text
                    }
                    <br />
                    {nearestComparativeEntityState.name}
                </Avatar>
            </Toast>
        );
    };

    /**
     * Renders the "more tools" toast.
     * @return {JSX.Element|null}
     */
    const renderMoreToolsToast = () => {
        const social_tool_setting = toolState.social_tool_setting;
        const tools = toolState.tools_to_link_to;
        if (!social_tool_setting || !tools.length) {
            return null;
        }

        const label =
            social_tool_setting.more_tools_description ||
            social_tool_setting.more_tools;

        if (!label) {
            return null;
        }

        const timeout =
            typeof toolState.social_tool_setting.hide_more_tools_after ===
            "number"
                ? toolState.social_tool_setting.hide_more_tools_after * 1000
                : null;

        return (
            <Toast
                activationDelay={
                    toolState.social_tool_setting.show_more_tools_after * 1000
                }
                timeout={timeout}
                onClick={() => setMoreToolsActiveState(true)}
                labelClose={deepGet(toolState, "general_texts.close")}
            >
                <Text>{label}</Text>
            </Toast>
        );
    };

    /**
     * Renders the main content.
     * @return {JSX.Element}
     */
    const renderView = () => (
        <Row
            grow={true}
            data-tool-type={getToolType()?.slug}
            data-has-range-filter={Boolean(
                deepGet(
                    toolState,
                    "result_configuration.regional_national_filter"
                ),
                false
            )}
        >
            {renderPrimarySidebar()}
            {renderTool()}
            {renderSecondarySidebar()}
        </Row>
    );

    return (
        <AbstractQuestionsView
            headerProps={{ style: "border" }}
            headerButtons={renderHeaderButtons(
                match,
                toolState,
                history,
                getToolType(),
                (e, buttonLabel) => {
                    if (buttonLabel.tool === "info") {
                        setTimeout(() => {
                            setActiveComparativeEntityState(null);
                        });
                    }
                }
            )}
            match={match}
            modals={renderModals()}
            statementsState={statementsState}
            toasts={renderToasts()}
            toolState={toolState}
            {...props}
        >
            {renderView()}
        </AbstractQuestionsView>
    );
};

/**
 * Results component for single page view.
 */

export const ResultsComponent = ({
    match,
    sessionState,
    toolState,
    popupQuestionsState,
    ...props
}) => {
    /* eslint-disable no-unused-vars */
    const [
        popupQuestionsActiveState,
        popupQuestionsCompletedState,
        privacyPopupActiveState,
        setPopupQuestionsActiveState,
        setPopupQuestionsCompletedState,
        setPrivacyPopupState,
    ] = usePopupQuestionsState(toolState, false);
    /* eslint-enable no-unused-vars */
    /**
     * Renders the privacy popup.
     */
    const renderPrivacyPopup = () => {
        return (
            <PrivacyPopup
                active={privacyPopupActiveState}
                toolState={toolState}
                onClose={() => setPrivacyPopupState(false)}
                onContinue={() => setPopupQuestionsActiveState(true)}
                onSkip={() => setPrivacyPopupState(false)}
            />
        );
    };

    /**
     * Renders the popup questions.
     */
    const renderPopupQuestions = () => {
        return (
            <PopupQuestions
                active={popupQuestionsActiveState}
                match={match}
                popupQuestionsState={popupQuestionsState}
                sessionState={sessionState}
                toolState={toolState}
                onChange={(e, formState) =>
                    props.setPopupQuestionsState(formState)
                }
                onClose={() => setPopupQuestionsActiveState(false)}
                onSubmit={() => setPopupQuestionsCompletedState(true)}
            />
        );
    };

    /**
     * Returns the title.
     * @return {string}
     */
    const getTitle = () => {
        return deepGet(
            toolState,
            "result_configuration.main_result_title",
            "",
            true
        );
    };

    /**
     * Returns the body text.
     * @return {string}
     */
    const getBody = () => {
        return deepGet(
            toolState,
            "result_configuration.main_result_button_text",
            "",
            true
        );
    };

    /**
     * Renders the modals.
     * @return {JSX.Element|null}
     */
    const renderModals = () => {
        return (
            <React.Fragment>
                {renderPrivacyPopup()}
                {renderPopupQuestions()}
            </React.Fragment>
        );
    };

    return (
        <>
            <Body centered={false} pad={false}>
                <SubTitle strong={true} maxWidth={`${252 / 15}rem`}>
                    {getTitle()}
                </SubTitle>
                <Text>{getBody()}</Text>

                {Boolean(
                    deepGet(
                        toolState,
                        "popup_question_page.popupquestion_set.length"
                    )
                ) && (
                    <Button
                        minwidth={true}
                        size="big"
                        onClick={() => setPopupQuestionsActiveState(true)}
                    >
                        {deepGet(
                            toolState,
                            "social_tool_setting.more_questions"
                        )}
                    </Button>
                )}
            </Body>
            {renderModals()}
        </>
    );
};
