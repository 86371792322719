import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Panel } from "../panel/Panel";
import { CompassParty } from "../compass/CompassParty";
import "./comparative-entity-filter.scss";

/**
 * ComparativeEntity filter.
 */
export const ComparativeEntityFilter = ({
    comparativeEntities,
    onClick,
    ...props
}) => {
    const [activeComparativeEntityState, setActiveComparativeEntityState] =
        useState(null);
    const [scrolledStart, setScrolledStart] = useState(true);
    const [scrolledEnd, setScrolledEnd] = useState(false);

    const className = classNames({
        ComparativeEntityFilter: true,
        "ComparativeEntityFilter--scrolled-start": scrolledStart,
        "ComparativeEntityFilter--scrolled-end": scrolledEnd,
    });

    /**
     * Handles state updates during scroll.
     * @param {SyntheticEvent} e
     */
    const onScroll = (e) => {
        const scrollStart = Math.max(e.target.scrollTop, e.target.scrollLeft);
        const clientSize =
            e.target.scrollHeight > e.target.scrollWidth
                ? e.target.clientHeight
                : e.target.clientWidth;
        const scrollSize = Math.max(
            e.target.scrollHeight,
            e.target.scrollWidth
        );

        setScrolledStart(false);
        setScrolledEnd(false);

        if (scrollStart === 0) {
            setScrolledStart(true);
            setScrolledEnd(false);
        }

        if (scrollStart + clientSize === scrollSize) {
            setScrolledStart(false);
            setScrolledEnd(true);
        }
    };

    /**
     * Handles compass party click.
     * @param {SyntheticEvent} e
     * @param {ComparativeEntity} comparativeEntity
     */
    const onCompassPartyClick = (e, comparativeEntity) => {
        setActiveComparativeEntityState(comparativeEntity);
        onClick(e, comparativeEntity);
    };

    /**
     * Renders the comparative entities.
     * @return {JSX.Element[]}
     */
    const renderComparativeEntities = () => {
        return comparativeEntities.map((comparativeEntity, index) => {
            return (
                <CompassParty
                    key={`${comparativeEntity.name}_${comparativeEntity.regional_or_national}`}
                    active={comparativeEntity === activeComparativeEntityState}
                    onClick={(e) => onCompassPartyClick(e, comparativeEntity)}
                    name={comparativeEntity.name}
                    size="44px"
                    src={comparativeEntity.logo}
                    tabIndex={210 + index}
                />
            );
        });
    };

    return (
        <aside className={className} {...props}>
            <header className="ComparativeEntityFilter__head" />

            <div className="ComparativeEntityFilter__body">
                <Panel
                    align="side"
                    direction="vertical"
                    grow={true}
                    onScroll={onScroll}
                >
                    {renderComparativeEntities()}
                </Panel>
            </div>
        </aside>
    );
};

ComparativeEntityFilter.propTypes = {
    /** The comparativeEntities. */
    comparativeEntities: PropTypes.array,

    /** The "onClick" callback. */
    onClick: PropTypes.func,
};

ComparativeEntityFilter.defaultProps = {
    comparativeEntities: [],
    onClick: () => {},
};
