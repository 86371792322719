import { generatePath, useHistory } from "react-router-dom";
import classNames from "classnames";
import { ROUTES } from "../../routes";
import "./language-switcher.scss";
import PropTypes from "prop-types";
import { getLanguageFromUrl } from "../../lib/i18n";

/**
 * Language switcher switches between possible languages.
 */
export const LanguageSwitcher = ({
    languageState,
    setLanguageState,
    languages,
    callback,
    ...props
}) => {
    const history = useHistory();

    const className = classNames({
        LanguageSwitcher: true,
    });

    /**
     * Returns whether language is active.
     * @param {Object} language
     * @return {boolean}
     */
    const getActive = (language) => {
        const _languageState = languageState || getLanguageFromUrl();
        return _languageState && _languageState === language.iso_code;
    };

    /**
     * onClick handler for language.
     * @param {SyntheticEvent} e
     * @param {Object} language
     */
    const onLanguageClick = (e, language) => {
        e.preventDefault();
        setLanguageState(language.iso_code);
        callback();
        const path = generatePath(ROUTES.LANDING.path, {
            language: language.iso_code,
        });
        history.push(path);
    };

    /**
     * Renders the list items.
     */
    const renderListItems = () =>
        languages.map((language, index) => {
            const classNameListItem = classNames({
                LanguageSwitcher__list_item: true,
                "LanguageSwitcher__list_item--active": getActive(language),
            });

            return (
                <li key={index} className={classNameListItem}>
                    <a
                        className="LanguageSwitcher__language"
                        href={generatePath(ROUTES.LANDING.path, {
                            language: language.iso_code,
                        })}
                        onClick={(e) => onLanguageClick(e, language)}
                    >
                        {language.iso_code}
                    </a>
                </li>
            );
        });

    if (!languages || languages.length < 2) {
        return null;
    }

    return (
        <ul className={className} {...props}>
            {renderListItems()}
        </ul>
    );
};

LanguageSwitcher.propTypes = {
    /** Current language iso code  */
    languageState: PropTypes.string,

    /** Setter for language state  */
    setLanguageState: PropTypes.func,

    /** Available languages  */
    languages: PropTypes.arrayOf(PropTypes.object),

    /** Callback function, called when switching language. */
    callback: PropTypes.func,
};

LanguageSwitcher.defaultProps = {
    languageState: "",
    setLanguageState: () => {},
    languages: [],
    callback: () => {},
};
