import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Logo } from "../logo/Logo";
import { MenuButton } from "../menu/MenuButton";
import { Modal } from "../modal/Modal";
import { Button } from "../button/Button";
import { Icon } from "../icon/Icon";
import { isMobile } from "../../lib/responsive";
import "./header.scss";

/**
 * Header is a parent component that holds a Logo, Menu and HeaderButton components.
 */
export const Header = ({
    labelClose,
    languageSwitcher,
    logo,
    menu,
    modalprops,
    style,
    ...props
}) => {
    const [menuActiveState, setMenuActiveState] = useState(false);
    const [toolSelectionActiveState, setToolSelectionActiveState] =
        useState(false);
    const className = classNames({
        Header: true,
        "Header--menu": props.children,
        [`Header--${style}`]: style,
    });

    /**
     * Handles tool selection click only on mobile.
     */
    const onToolSelectionClick = () => {
        if (!props.children || !isMobile()) {
            return;
        }

        setToolSelectionActiveState(!toolSelectionActiveState);
    };

    /**
     * Returns the amount of buttons.
     * @return {string|*}
     */
    const getAmountOfButtons = () => {
        return props?.children?.length || "0";
    };

    return (
        <header className={className} {...props}>
            {logo && (
                <div className="Header__section Header__section--branding">
                    {typeof logo == "object" ? logo : <Logo />}
                </div>
            )}

            <div
                className="Header__section Header__section--grow"
                data-button-count={getAmountOfButtons()}
                onClick={onToolSelectionClick}
            >
                {props.children && props.children.length > 3 && (
                    <>
                        <Button style="primary-contrast">
                            <Icon icon="chevron-down" strong={true}></Icon>
                        </Button>

                        <Modal
                            active={toolSelectionActiveState}
                            allowclose={true}
                            labelClose={labelClose}
                            onClose={onToolSelectionClick}
                        >
                            {props.children}
                        </Modal>
                    </>
                )}

                {props.children}
                {!props.children && languageSwitcher}
            </div>

            {menu && (
                <div className="Header__section Header__section--actions">
                    <MenuButton
                        active={menuActiveState}
                        id="menu"
                        onClick={() => setMenuActiveState(!menuActiveState)}
                        tabIndex={getAmountOfButtons() + 13}
                    />
                    <Modal
                        active={menuActiveState}
                        dark={false}
                        labelClose={labelClose}
                        onClose={() => setMenuActiveState(false)}
                        allowclose={true}
                        {...modalprops}
                    >
                        {menu}
                    </Modal>
                </div>
            )}
        </header>
    );
};

Header.propTypes = {
    /** Child components/content.  */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.element, PropTypes.string])
        ),
    ]),

    /** Label shown on close button (mobile only). */
    labelClose: PropTypes.string,

    /** Language switcher component. */
    languageSwitcher: PropTypes.element,

    /** Whether to show the logo/the contents of the logo section. */
    logo: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.element,
        PropTypes.string,
    ]),

    /** The contents of the menu section, if falsy, menu structure is hidden. */
    menu: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.element,
        PropTypes.string,
    ]),

    // /** Props to pass to the menu. */
    // modalprops: PropTypes.shape(Toast.propTypes),

    /** The style of the header. */
    style: PropTypes.oneOf(["shadow", "border", "flat"]),
};

Header.defaultProps = {
    labelClose: null,
    languageSwitcher: null,
    logo: <Logo />,
    menu: false,
    modalprops: {
        position: "side",
    },
    style: "shadow",
};
