import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Caption } from "../typography/Caption";
import { useAnimation } from "../../lib/animation";
import "./progress-bar.scss";

/**
 * ProgressBar is a header component for Card.
 */
export const ProgressBar = ({
    animationSpeed,
    value,
    max,
    background,
    color,
    showpercent,
    ...props
}) => {
    const renderedValue = useAnimation(value, animationSpeed, 0);

    const className = classNames({
        ProgressBar: true,
        "ProgressBar--background": background,
    });

    const getStyle = () => {
        return {
            color: typeof color === "string" ? color : null,
            backgroundColor: typeof background === "string" ? background : null,
        };
    };

    return (
        <div className={className}>
            <progress
                className="ProgressBar__progressbar"
                value={renderedValue}
                max={max}
                style={getStyle()}
                {...props}
            />
            {showpercent && (
                <Caption>{`${parseInt((max / 100) * renderedValue)}%`}</Caption>
            )}
        </div>
    );
};

ProgressBar.propTypes = {
    /** Animation speed. */
    animationSpeed: PropTypes.number,

    /** Value. */
    value: PropTypes.number,

    /** Maximum step number. */
    max: PropTypes.number,

    /** Whether to show (and optionally the color of) a background. */
    background: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),

    /** Color. */
    color: PropTypes.string,

    /** Whether to show the percentage. */
    showpercent: PropTypes.bool,
};

ProgressBar.defaultProps = {
    animationSpeed: 1,
    value: 1,
    max: 26,
    background: null,
    color: "var(--color-primary)",
    showpercent: false,
};
